import React from 'react';
import { Box, Image, Text } from '@chakra-ui/react';

const SelectCertificate = ({
  title,
  data = [],
  onSelect = () => {},
  selected,
}) => {
  return (
    <Box>
      <Text my="1" fontSize="xl" fontWeight={'bold'}>
        {title}
      </Text>

      <Box
        display="grid"
        gridGap={'4'}
        gridTemplateColumns={{
          sm: '1fr',
          md: '1fr 1fr',
          lg: '1fr 1fr 1fr',
        }}
        backgroundColor={'light'}
        px="4"
        pt="1"
        pb="3"
      >
        {data?.map?.((item, index) => {
          const isSelected = item?.key === selected?.key;
          return (
            <Box
              backgroundColor={'light'}
              borderWidth={2}
              borderColor={isSelected ? 'primary' : 'light'}
              borderRadius="md"
              cursor="pointer"
              onClick={() => onSelect(item)}
              key={index.toString()}
              display="flex"
              flex="1"
              p="3"
            >
              <Image objectFit={'contain'} src={item.imageUrl} />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default SelectCertificate;
