import React from 'react';
import { Box } from '@chakra-ui/react';
import { UserPostCard } from '.';

const UserPostBoard = ({ userPosts = [], ...props }) => {
  return (
    <Box
      //flex={1}
      display="grid"
      gridGap={'4'}
      gridTemplateColumns={{
        base: '1fr 1fr',
        sm: '1fr 1fr',
        md: '1fr 1fr 1fr',
        lg: '1fr 1fr 1fr 1fr',
      }}
      pt={'5'}
      px="3"
      {...props}
    >
      {userPosts.map?.(item => {
        return (
          <UserPostCard
            key={item?.id}
            avatar={item?.user}
            image={item?.image}
            title={props.title}
            userId={item?.user?.id || item?.userId}
            postId={item?.postId}
            id={item?.id}
          />
        );
      })}
    </Box>
  );
};

export default UserPostBoard;
