import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  fonts: {
    heading: 'Poppins',
    body: 'Poppins',
  },
  colors: {
    backgroundColor: '#fff',
    primary: '#297AF3',
    default: '#515151',
    light: '#f9f9f9',
    gray: '#E5E5E5',
    lightGray: '#818283',
    dark: '#292D32',
    main: {
      50: '#e0f1ff',
      100: '#b3d2ff',
      200: '#83b4fb',
      300: '#5496f7',
      400: '#2678f3',
      500: '#0c5ed9',
      600: '#0349aa',
      700: '#00347b',
      800: '#001f4c',
      900: '#000a1f',
    },
  },
});

export default theme;
