/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Box,
  Avatar,
  Text,
} from '@chakra-ui/react';
import { AuthLinkButon, InfiniteScroll } from '..';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../constants/routes';
import useMainStore from '../../hooks/useMainStore';
import useFetch from '../../hooks/useFetch';
import { UserService } from '../../services';
import { useTranslation } from 'react-i18next';

const FollowListModal = ({
  onClose = () => {},
  isOpen = false,
  title = '',
  userId,
  type,
  users,
}) => {
  const { t } = useTranslation();
  const { isLoggedIn } = useMainStore();
  const navigate = useNavigate();

  const list = useFetch({
    queryKey: userId + type,
    fetchFn: params =>
      type === 'followers'
        ? UserService.queryFollowers(userId, params)
        : UserService.queryFollowees(userId, params),
  });

  const onUser = username => {
    navigate(routes.user.getPath(username));
    onClose();
  };

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {!isLoggedIn ? (
            <AuthLinkButon text={t('login_for_more_desc')} />
          ) : (
            <Box overflow="auto" id="followList">
              <InfiniteScroll {...list} scrollableTarget="followList">
                {users?.map(item => {
                  const user = item?.user || item?.users;
                  return (
                    <Box
                      key={user.username}
                      display={'flex'}
                      alignItems={'center'}
                      my={'1'}
                      cursor={'pointer'}
                      onClick={() => onUser(user?.username)}
                    >
                      <Box pr={'2'}>
                        <Avatar
                          size={'sm'}
                          src={user.avatar}
                          name={user.username}
                        />
                      </Box>
                      <Box>
                        <Text fontWeight={'600'} color="dark">
                          {user.username}
                        </Text>
                      </Box>
                    </Box>
                  );
                })}
              </InfiniteScroll>
            </Box>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default FollowListModal;
