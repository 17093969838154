import * as React from 'react';

const SvgPlus = props => (
  <svg
    viewBox="0 0 232 231"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11 117h210M117 220V11"
      stroke="currentColor"
      strokeWidth={22}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgPlus;
