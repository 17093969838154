/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Avatar, Box, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../constants/routes';
import useMainStore from '../../hooks/useMainStore';
import { useTranslation } from 'react-i18next';

const PostCommentItem = ({ avatar, userId, username, qrCode = false }) => {
  const { t } = useTranslation();
  const { isLoggedIn } = useMainStore();
  const navigate = useNavigate();

  const onUser = () => {
    isLoggedIn && navigate(routes.user.getPath(username));
  };

  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      my={'1'}
      cursor={'pointer'}
      onClick={onUser}
    >
      <Box pr={'2'}>
        <Avatar size={'sm'} src={avatar} name={username} />
      </Box>
      <Box>
        <Text fontWeight={'600'} color="dark">
          {username}
        </Text>
        {qrCode && <Text color="dark">{t('qr_code_scanned')}</Text>}
      </Box>
    </Box>
  );
};

export default PostCommentItem;
