import * as React from 'react';

const SvgUser = props => (
  <svg
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2 27c0-4.688 6.25-4.688 9.375-7.813 1.563-1.562-3.125-1.562-3.125-9.375C8.25 4.606 10.333 2 14.5 2s6.25 2.605 6.25 7.813c0 7.812-4.688 7.812-3.125 9.374C20.75 22.313 27 22.313 27 27"
      stroke="currentColor"
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgUser;
