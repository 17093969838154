import { Loader } from '@googlemaps/js-api-loader';
import { useEffect, useState } from 'react';

const useGoogleMaps = options => {
  const loader = new Loader({
    apiKey: 'AIzaSyDB9c1nPWNT-yU3Yk82Ru0gxDXB75BIgog',
    libraries: ['places'],
    ...options,
  });
  const [google, setGoogle] = useState(null);

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const load = async () => {
    try {
      const google = await loader.load();
      setGoogle(google);
    } catch (error) {
      console.error(error);
    }
  };

  return google;
};

export default useGoogleMaps;
