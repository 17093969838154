/* eslint-disable react-hooks/exhaustive-deps */
import FirebaseChat from '@metehankurucu/firebase-chat';
import { useEffect, useRef, useState } from 'react';
import { UserService } from '../services';

const useRooms = currentUserId => {
  const [_rooms, _setRooms] = useState([]);
  const rooms = useRef(FirebaseChat.rooms());
  const [usersCache, setUsersCache] = useState({});

  useEffect(() => {
    rooms.current = FirebaseChat.rooms();
    _setRooms([]);
    const unsubscribe = rooms?.current?.listenRooms(async items => {
      if (items.length === 0) {
        if (_rooms.length === 0) return;
        else return _setRooms([]);
      }

      const promises = items.map(async item => {
        const otherUserId =
          String(item.user1Id) === String(currentUserId)
            ? item.user2Id
            : item.user1Id;

        const userData = await getUserData(otherUserId);

        return {
          ...item,
          otherUserId,
          userData,
        };
      });

      const data = await Promise.all(promises);
      _setRooms(data);
    });

    return () => unsubscribe();
  }, [currentUserId]);

  const getUserData = async userId => {
    if (usersCache[userId]) {
      return usersCache[userId];
    }
    try {
      const [data] = await UserService.getUser(userId);
      if (data) setUsersCache(oldData => ({ ...oldData, [userId]: data }));
      return data;
    } catch (error) {
      return null;
    }
  };

  const getRoomId = async otherUserId => {
    const room = await rooms?.current?.getRoom(otherUserId, {
      createIfNotExists: true,
    });
    return room.id;
  };

  const deleteRoom = async roomId => {
    await rooms?.current?.deleteRoom(roomId);
    const messages = FirebaseChat.messages(roomId);
    messages.deleteAllMessages();
  };

  return {
    rooms: _rooms,
    getRoomId,
    getUserData,
    deleteRoom,
  };
};

export default useRooms;
