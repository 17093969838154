import React, { useState } from 'react';
import { Avatar, Box, Button, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import {
  MainLayout,
  MainSection,
  SecondarySection,
  SectionsWrapper,
  Spinner,
} from '../../components';
import { routes } from '../../constants/routes';
import toast from '../../helpers/toast';
import { UserService } from '../../services';
import SearchBox from '../../components/SearchBox';
import useNavigateUserProfile from '../../hooks/useNavigateUserProfile';
import { useTranslation } from 'react-i18next';

const ClubNewMember = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const navigateProfile = useNavigateUserProfile();
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const search = async text => {
    if (!text || !text.trim() || text.trim()?.length < 3)
      return toast({ title: t('min_x_characters', { x: 3 }) });
    setRefresh(true);
    const [data] = await UserService.searchUser(
      text.trim().toLocaleLowerCase('tr')
    );

    if (data?.result) {
      setData(data.users);
      if (data?.users?.length === 0) {
        toast({ title: t('no_result_with_your_search') });
      }
    }
    setRefresh(false);
  };

  const addUserToClub = async userId => {
    const [data] = await UserService.addUserToClub(userId);
    if (data?.result) {
      toast({ title: t('user_added'), status: 'success' });
      navigate(-1);
      return;
    }
    toast({ title: t('user_already_member') });
    return;
  };

  return (
    <MainLayout>
      <SectionsWrapper>
        <MainSection>
          <Text mt="3" color={'primary'} fontSize="xl" fontWeight={'bold'}>
            {t('add_new_member')}
          </Text>
          <Box mt="3">
            <SearchBox
              maxWidth={'100%'}
              borderWidth="1"
              borderColor="primary"
              placeholder={t('search_member')}
              onSearch={search}
            />
            <Spinner isVisible={refresh} />
            <Box display={'flex'} flexDirection="column" flex="1">
              {data?.map?.((item, index) => {
                return (
                  <Box
                    key={index.toString()}
                    display={'flex'}
                    flexDirection="row"
                    justifyContent={'space-between'}
                    m="2"
                    p={'5'}
                    bgColor="light"
                    borderRadius={'md'}
                    alignItems="center"
                  >
                    <Box
                      cursor="pointer"
                      onClick={() => navigateProfile(item?.id)}
                      display={'flex'}
                      alignItems="center"
                    >
                      <Avatar size={'md'} src={item?.avatar} />
                      <Text mx="3">
                        {item?.username} - {item?.name}
                      </Text>
                    </Box>
                    <Button
                      backgroundColor={'green'}
                      color="white"
                      onClick={() => addUserToClub(item.id)}
                      mr="2"
                    >
                      {t('add')}
                    </Button>
                  </Box>
                );
              })}
            </Box>
          </Box>
        </MainSection>
        <SecondarySection>
          <Button
            onClick={() => navigate(routes.clubDashboard.path)}
            m="3"
            backgroundColor="primary"
            color="white"
          >
            {t('back_to_club_dashboard')}
          </Button>
        </SecondarySection>
      </SectionsWrapper>
    </MainLayout>
  );
};

export default ClubNewMember;
