import React, { useEffect } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { Footer, MainLayout } from '../components';
import { UserService, PostService } from '../services';
import toast from '../helpers/toast';
import { useNavigate } from 'react-router-dom';
import { routes } from '../constants/routes';
import { useTranslation } from 'react-i18next';

const API = () => {
  const { t } = useTranslation();
  const [apiKey, setApiKey] = React.useState('');
  const [events, setEvents] = React.useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    UserService.getApiKey().then(([data]) => {
      if (data) setApiKey(data.apiKey);
    });
    PostService.getExternalEvents().then(([data]) => {
      if (data) setEvents(data.results);
    });
  }, []);

  const onCopy = () => {
    navigator.clipboard.writeText(apiKey);
    toast({
      title: t('copied'),
      status: 'success',
    });
  };

  return (
    <MainLayout>
      <Box my="10" minH="70vh">
        <Text fontWeight={'bold'} fontSize="3xl" my="6">
          {t('ticketing_system')}
        </Text>
        <Text
          onClick={onCopy}
          fontWeight={'bold'}
          fontSize="lg"
          cursor={'pointer'}
        >
          {t('api_key')}:{' '}
          <Text as="span" fontWeight={'bold'} color="primary">
            {apiKey}
          </Text>
        </Text>
        <Box>
          <Text fontWeight={'semibold'} fontSize="xl" my="6">
            {t('outsource_events')}
          </Text>
          {events?.map?.(event => (
            <Box
              key={event.id}
              my="3"
              p="3"
              borderWidth="0.5px"
              borderColor="gray.100"
              borderRadius="md"
              onClick={() => navigate(routes.detail.getPath(event.slug))}
              cursor={'pointer'}
            >
              <Text fontWeight={'bold'} fontSize="lg">
                {event.title}
              </Text>
            </Box>
          ))}
        </Box>
      </Box>

      <Footer />
    </MainLayout>
  );
};

export default API;
