import { Box, Spinner } from '@chakra-ui/react';
import React from 'react';

const Fallback = () => {
  return (
    <Box
      display={'flex'}
      height={'100vh'}
      width={'100vw'}
      justifyContent={'center'}
      alignItems={'center'}
      backgroundColor={'#BCD6FF'}
    >
      <Spinner color="primary" size={'lg'} />
    </Box>
  );
};

export default Fallback;
