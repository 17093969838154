/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Box, Button, Icon, Center } from '@chakra-ui/react';
import { Back } from '../icons';
import Footer from '../../components/Footer';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../constants/routes';
import { useTranslation } from 'react-i18next';
// import ClupUserInfo from '../../pages/information/ClupUserInfo';
// import WhatIs from '../../pages/information/WhatIs';
// import AppInfo from '../../pages/information/AppInfo';

const Carousel = React.lazy(() => import('../Carousel'));
const Logo = React.lazy(() => import('../Logo'));
const AuthHeader = React.lazy(() => import('../headers/AuthHeader'));

const AuthLayout = ({
  children,
  title = 'Panoyabak',
  withBackButton,
  ...props
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box>
      <Box as="main" minH={'100vh'} marginX="auto" {...props}>
        <AuthHeader />
        <Box
          flex={1}
          minH={'80vh'}
          display="flex"
          flexDirection={{ base: 'column-reverse', lg: 'row' }}
        >
          <Box
            flex={1}
            display="flex"
            backgroundColor={'white'}
            flexDirection={'column'}
          >
            <Box
              flex={1}
              display={'flex'}
              alignItems="center"
              justifyContent={'center'}
              mx={'10'}
            >
              <iframe
                width="806"
                height="453"
                src="https://www.youtube.com/embed/4UocbPTvkvU"
                title={t('app_video_desc')}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen={true}
              ></iframe>
            </Box>
          </Box>
          <Box
            flex={1}
            display="flex"
            backgroundColor={'backgroundColor'}
            flexDirection={'column'}
            py={'5'}
            px={{ base: '6', lg: '0' }}
            mx={{ base: 'unset', lg: 'auto' }}
            justifyContent={'space-evenly'}
            //justifyContent={'space-between'}
            position={'relative'}
          >
            {withBackButton && (
              <Button
                onClick={() => navigate(-1)}
                backgroundColor={'light'}
                variant={'ghost'}
                borderRadius={'full'}
                position={{ base: 'fixed', lg: 'absolute' }}
                top="3"
                left="5"
              >
                <Icon width={'unset'} as={Back} color="primary" />
              </Button>
            )}
            <Box
              cursor={'pointer'}
              onClick={() => navigate(routes.login.path)}
              mx={'auto'}
            >
              <Logo width={200} />
            </Box>
            {children}

            <Box>
              <Center>
                <a href="https://play.google.com/store/apps/details?id=com.panoyabak&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                  <img
                    alt={t('download_google_text')}
                    src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                    width="150px"
                  />
                </a>

                <a href="https://apps.apple.com/us/app/panoyabak/id1591753530?itsct=apps_box_badge&amp;itscg=30200">
                  <img
                    src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1635465600&h=8c67bcbdb68aadb98d67939c201e4566"
                    alt={t('download_apple_text')}
                  />
                </a>
              </Center>
            </Box>
          </Box>
        </Box>

        {/* <Box>
          <WhatIs />
          <AppInfo />
        </Box> */}
        <Footer />
      </Box>
    </Box>
  );
};

export default AuthLayout;
