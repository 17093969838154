import * as React from 'react';

const SvgHomeActive = props => (
  <svg
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 30V14.61a2 2 0 0 1 .709-1.527l13-10.991a2 2 0 0 1 2.582 0l13 10.99A2 2 0 0 1 31 14.61V30a2 2 0 0 1-2 2h-6.5a2 2 0 0 1-2-2v-5.595a2 2 0 0 0-2-2h-5a2 2 0 0 0-2 2V30a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="square"
    />
  </svg>
);

export default SvgHomeActive;
