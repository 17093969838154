/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { MainLayout, PostDetails } from '../components';
import { PostService } from '../services';

const PostDetail = () => {
  const { slug } = useParams();
  const [id, setId] = useState(null);

  useEffect(() => {
    PostService.getPostDetailsBySlug(slug).then(([result]) => {
      if (result) setId(result.id);
    });
  }, []);

  return <MainLayout> <PostDetails id={id} /></MainLayout>;
};

export default PostDetail;
