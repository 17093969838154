/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import useMainStore from '../../hooks/useMainStore';
import useRooms from '../../hooks/useRooms';
import { Text } from '@chakra-ui/react';
import {
  MainLayout,
  MainSection,
  RecommendedUsers,
  SecondarySection,
  Footer,
  SectionsWrapper,
  Spinner,
} from '../../components';
import MessageCard from '../../components/cards/MessageCard';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../constants/routes';
import { useTranslation } from 'react-i18next';

const Messages = () => {
  const { t } = useTranslation();
  const [allRooms, _setAllRooms] = useState([]);
  const [_items, _setItems] = useState([]);
  const {
    data: { user },
  } = useMainStore();
  const { rooms: items, deleteRoom } = useRooms(user.id);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const timeout = useRef();

  useEffect(() => {
    setItems(items);
  }, [items]);

  const setItems = async rooms => {
    setLoading(true);
    timeout.current && clearTimeout(timeout.current);
    const items = rooms.filter(room => room.lastMessage);
    _setItems(items);
    _setAllRooms(items);
    timeout.current = setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const onRoom = item => {
    navigate(
      routes.chat.getPath({ userId: item.otherUserId, roomId: item.id })
    );
  };

  const onUser = username => {
    username && navigate(routes.user.getPath(username));
  };

  return (
    <MainLayout>
      <SectionsWrapper>
        <MainSection>
          {loading ? (
            <Spinner />
          ) : (
            _items.map?.((item, index) => {
              if (!item.userData || !item.lastMessage) return null;
              return (
                <MessageCard
                  key={index}
                  onClick={() => onRoom(item)}
                  onUser={() => onUser(item?.userData?.username)}
                  lastMessage={item.lastMessage}
                  user={item.userData}
                  deleteText={t('delete_conversation')}
                  onDelete={() => {
                    deleteRoom(item.id);
                  }}
                />
              );
            })
          )}
          {!_items.length && !loading && (
            <Text fontSize="lg" fontWeight="400" textAlign="center" my="4">
              {t('empty_yet')}..
            </Text>
          )}
        </MainSection>
        <SecondarySection>
          <RecommendedUsers />
          <Footer />
        </SecondarySection>
      </SectionsWrapper>
    </MainLayout>
  );
};

export default Messages;
