import { Box, Text } from '@chakra-ui/react';
import React from 'react';
import { Footer, MainLayout } from '../../components';
import { useTranslation } from 'react-i18next';
import { selectByLanguage } from '../../language';

const data = {
  tr: (
    <>
      <Text fontWeight={'bold'} fontSize="3xl" textAlign={'center'}>
        Kullanım Şartları (KVKK)
      </Text>
      <br></br>
      <div>
        <div>
          <p>
            <strong>
              ÜYELİK FORMUNDA KİŞİSEL VERİLERİN İŞLENMESİNE İLİŞKİNAYDINLATMA
              METNİ
            </strong>
            <br></br>
          </p>
          <br></br>
          <p>
            Panoyabak olarak; 6698 sayılı Kişisel Verilerin KorunmasıKanunu
            (“KVKK”) ve ilgili mevzuat ve yasal düzenlemelerden kaynaklanan
            faaliyetleriçerçevesinde kişisel verilerinizin işlenmesi, saklanması
            ve aktarılmasına ilişkin sizleribilgilendirmek amacıyla işbu
            aydınlatma metnini hazırladık.
          </p>
          <br></br>
        </div>

        <div>
          <p>
            <strong>
              (I) İşlediğimiz Kişisel Veri Kategorileriniz Nelerdir ve Bunları
              Hangi Amaçlarlaİşliyoruz?
            </strong>
            <br></br>
          </p>
          <br></br>
          <p>
            Ziyaretçilerimizin kişisel bilgileri Panoyabak tarafından KVKK’nın
            42. maddesinde öngörülen;
          </p>
          <br></br>

          <div>
            <span>
              <strong>(i)</strong>
              <br></br> Hukuka ve dürüstlük kurallarına uygun olma,{' '}
            </span>
          </div>
          <div>
            <span>
              <strong>(ii)</strong>
              <br></br> Doğru ve gerektiğinde güncel olma,
            </span>
          </div>
          <div>
            <span>
              <strong>(iii)</strong>
              <br></br> Belirli, açık ve meşru amaçlar için işlenme,
            </span>
          </div>
          <div>
            <span>
              <strong>(iv)</strong>
              <br></br> İşlendikleri amaçla bağlantılı, sınırlı ve ölçülü olma,
            </span>
          </div>
          <div>
            <span>
              <strong>(v)</strong>
              <br></br> İlgili mevzuatta öngörülen veya işlendikleri amaç için
              gerekli olan süre kadar muhafazaedilme,
            </span>
          </div>

          <div>
            <p>
              ilkelerine uygun olarak, aşağıda belirtilen amaçlar doğrultusunda
              işlenebilecektir.
            </p>
            <br></br>

            <p>
              Kimlik bilgileri (ör: ad, soyadı, cinsiyet, doğum tarihi),
              iletişim bilgileri (ör: adres, telefonnumarası, e-posta adresi),
            </p>
            <br></br>
          </div>

          <div>
            <span>
              <strong>(i)</strong>
              <br></br> faaliyetlerin mevzuata uygun yürütülmesi
            </span>
          </div>
          <div>
            <span>
              <strong>(ii)</strong>
              <br></br> bilgi güvenliği süreçlerinin yürütülmesi
            </span>
          </div>
          <div>
            <span>
              <strong>(iii)</strong>
              <br></br> finans ve muhasebe işlerinin yürütülmesi
            </span>
          </div>
          <div>
            <span>
              <strong>(iv)</strong>
              <br></br> firma / ürün / hizmetlere bağlılık süreçlerinin
              yürütülmesi
            </span>
          </div>
          <div>
            <span>
              <strong>(v)</strong>
              <br></br> iletişim faaliyetlerinin yürütülmesi
            </span>
          </div>
          <div>
            <span>
              <strong>(vi)</strong>
              <br></br> iş faaliyetlerinin yürütülmesi / denetimi
            </span>
          </div>
          <div>
            <span>
              <strong>(vii)</strong>
              <br></br> iş süreçlerinin iyileştirilmesine yönelik önerilerin
              alınması ve değerlendirilmesi
            </span>
          </div>
          <div>
            <span>
              <strong>(viii)</strong>
              <br></br> iş sürekliliğinin sağlanması faaliyetlerinin yürütülmesi
            </span>
          </div>
          <div>
            <span>
              <strong>(ix)</strong>
              <br></br> lojistik faaliyetlerinin yürütülmesi
            </span>
          </div>
          <div>
            <span>
              <strong>(x)</strong>
              <br></br> mal / hizmet satış süreçlerinin yürütülmesi
            </span>
          </div>
          <div>
            <span>
              <strong>(xi)</strong>
              <br></br> mal / hizmet satış sonrası destek hizmetlerinin
              yürütülmesi
            </span>
          </div>
          <div>
            <span>
              <strong>(xii)</strong>
              <br></br> pazarlama analiz çalışmalarının yürütülmesi
            </span>
          </div>
          <div>
            <span>
              <strong>(xiii)</strong>
              <br></br> reklam / kampanya / promosyon süreçlerinin yürütülmesi
            </span>
          </div>
          <div>
            <span>
              <strong>(xiv)</strong>
              <br></br> müşteri ilişkileri yönetimi süreçlerinin yürütülmesi
            </span>
          </div>
          <div>
            <span>
              <strong>(xv)</strong>
              <br></br> müşteri memnuniyetine yönelik aktivitelerin yürütülmesi
            </span>
          </div>
          <div>
            <span>
              <strong>(xvi)</strong>
              <br></br> talep / şikayetlerin takibi
            </span>
          </div>
          <div>
            <span>
              <strong>(xvii)</strong>
              <br></br> ürün / hizmetlerin pazarlama süreçlerinin yürütülmesi
            </span>
          </div>
          <div>
            <span>
              <strong>(xviii)</strong>
              <br></br> saklama ve arşiv faaliyetlerinin yürütülmesiamaçlarıyla
              işlenecektir.
            </span>
          </div>

          <p>
            Konum verileriniz <strong>açık rızanıza dayanarak</strong>
            <br></br> sizlere daha iyi hizmet verebilmek
            yakınınızdakietkinlikleri gösterebilmek amacıyla işlenecektir.
          </p>
          <br></br>

          <p>
            Fotoğraf verileriniz <strong>açık rızanıza dayanarak</strong>
            <br></br> sizlere daha iyi hizmet verebilmek kendiprofilinizi
            oluşturabilmeniz amacıyla işlenecektir.
          </p>
          <br></br>

          <p>
            <strong>Kişisel Verilerinizin Toplanma Yöntemleri Nelerdir?</strong>
            <br></br>
          </p>
          <br></br>

          <p>
            Yukarıda kategoriler halinde belirtilen kişisel verileriniz; üyelik
            formu, üyelik bilgilerisayfasını doldurmanız, destek talepleriniz
            gibi internet sitemizi kullanımınız vasıtalarıylakısmen veya tamamen
            otomatik olan yollarla toplanmaktadır.
          </p>
          <br></br>
        </div>

        <div>
          <p>
            <strong>
              (II) Kişisel Verilerinizin Toplanmasının Hukuki Sebebi Nedir?
            </strong>
            <br></br>
          </p>
          <br></br>
          <p>
            Kişisel verileriniz, Panoyabak tarafından; yukarıda açıklanan
            amaçların gerçekleştirilmesidoğrultusunda KVKK’nın 5. maddesinde
            belirtilmiş olan;
          </p>
          <br></br>

          <div>
            <span>
              <strong>(i)</strong>
              <br></br> Veri sorumlusunun hukuki yükümlülüğünü yerine
              getirebilmesi için zorunlu olması,
            </span>
          </div>
          <div>
            <span>
              <strong>(ii)</strong>
              <br></br> Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya
              ilgili olması kaydıyla,sözleşmenin taraflarına ait kişisel
              verilerin işlenmesinin gerekli olması,
            </span>
          </div>
          <div>
            <span>
              <strong>(iii)</strong>
              <br></br> ilgili kişinin temel hak ve özgürlüklerine zarar
              vermemek kaydıyla, veri sorumlusununmeşru menfaatleri için veri
              işlenmesinin zorunlu olması
            </span>
          </div>
          <div>
            <span>hukuki sebeplerine dayanılarak işlenmektedir.</span>
          </div>
        </div>

        <div>
          <p>
            <strong>
              (III) Kişisel Verilerinizi Üçüncü Bir Kişiye Aktarıyor Muyuz?
            </strong>
            <br></br>
          </p>
          <br></br>
          <p>
            Yukarıda bahsedilen kişisel veriler, yasal düzenlemelerin öngördüğü
            kapsamda, faaliyetlerinmevzuata uygun yürütülmesi, hukuk işlerinin
            takibi ve yürütülmesi, yetkili kişi, kurum vekuruluşlara bilgi
            verilmesi amacıyla yetkili kamu kurum ve kuruluşlarına
            aktarılmaktadır.Ayrıca söz konusu kişisel veriler, ileride
            çıkabilecek hukuki uyuşmazlıklarda delil olarakkullanılabilmesi
            amacıyla hizmet aldığımız hukuk bürosuna ve adli
            makamlaraaktarılabilecektir.
          </p>
          <br></br>
        </div>

        <div>
          <p>
            <strong>
              (IV) Kişisel Verileriniz ile ilgili Haklarınızı Nasıl
              Kullanabilirsiniz?
            </strong>
            <br></br>
          </p>
          <br></br>
          <p>
            KVKK’nın ilgili kişilerin haklarını düzenleyen11. maddesi
            kapsamındaki taleplerinizi Panoyabak’a ait
            <a href="https://panoyabak.com">www.panoyabak.com</a> adresinde yer
            alan “Veri Sorumlusuna Başvuru Usulve Esasları Hakkında Tebliğ”
            uyarınca sizlere kolaylık sağlaması adına hazırlanan
            “VeriSorumlusuna Başvuru Formu” aracılığıyla bizlere
            iletebilirsiniz.
          </p>
          <br></br>
        </div>

        <div>
          <div>
            <span>
              <strong>Veri Sorumlusu:</strong>
              <br></br> Panoyabak
            </span>
          </div>
          <div>
            <span>
              <strong>Adres:</strong>
              <br></br> Veliefendi mahallesi 75 2 sok no 84 d4 Zeytinburnu
              İstanbul
            </span>
          </div>
          <div>
            <span>
              <strong>Mail:</strong>
              <br></br> iletisim@panoyabak.com
            </span>
          </div>
        </div>
      </div>
    </>
  ),
  en: (
    <>
      <Text fontWeight={'bold'} fontSize="3xl" textAlign={'center'}>
        Terms of Use (PDPL)
      </Text>
      <br></br>
      <div>
        <div>
          <p>
            <strong>
              CLARIFICATION TEXT ON THE PROCESSING OF PERSONAL DATA IN THE
              MEMBERSHIP FORM
            </strong>
            <br></br>
          </p>
          <br></br>
          <p>
            As Panoyabak; we have prepared this clarification text to inform you
            about the processing, storage, and transfer of your personal data
            within the scope of activities arising from the Personal Data
            Protection Law No. 6698 (“PDPL”) and related legislation and legal
            regulations.
          </p>
          <br></br>
        </div>

        <div>
          <p>
            <strong>
              (I) What Are the Categories of Personal Data We Process and For
              What Purposes Do We Process Them?
            </strong>
            <br></br>
          </p>
          <br></br>
          <p>
            The personal information of our visitors can be processed by
            Panoyabak in accordance with the principles stipulated in Article 42
            of PDPL;
          </p>
          <br></br>

          <div>
            <span>
              <strong>(i)</strong>
              <br></br> Being in compliance with the law and the rules of
              honesty,
            </span>
          </div>
          <div>
            <span>
              <strong>(ii)</strong>
              <br></br> Being accurate and, when necessary, up to date,
            </span>
          </div>
          <div>
            <span>
              <strong>(iii)</strong>
              <br></br> Being processed for specified, explicit, and legitimate
              purposes,
            </span>
          </div>
          <div>
            <span>
              <strong>(iv)</strong>
              <br></br> Being relevant, limited, and proportionate to the
              purposes for which they are processed,
            </span>
          </div>
          <div>
            <span>
              <strong>(v)</strong>
              <br></br> Being retained for the period stipulated by relevant
              legislation or necessary for the purpose they are processed for,
            </span>
          </div>

          <div>
            <p>in accordance with the purposes specified below.</p>
            <br></br>

            <p>
              Identity information (e.g., name, surname, gender, date of birth),
              communication information (e.g., address, phone number, email
              address),
            </p>
            <br></br>
          </div>

          <div>
            <span>
              <strong>(i)</strong>
              <br></br> conducting activities in compliance with legislation
            </span>
          </div>
          <div>
            <span>
              <strong>(ii)</strong>
              <br></br> execution of information security processes
            </span>
          </div>
          <div>
            <span>
              <strong>(iii)</strong>
              <br></br> conducting financial and accounting affairs
            </span>
          </div>
          <div>
            <span>
              <strong>(iv)</strong>
              <br></br> conducting processes related to loyalty to the
              company/product/services
            </span>
          </div>
          <div>
            <span>
              <strong>(v)</strong>
              <br></br> conducting communication activities
            </span>
          </div>
          <div>
            <span>
              <strong>(vi)</strong>
              <br></br> conducting / auditing business activities
            </span>
          </div>
          <div>
            <span>
              <strong>(vii)</strong>
              <br></br> receiving and evaluating suggestions for the improvement
              of business processes
            </span>
          </div>
          <div>
            <span>
              <strong>(viii)</strong>
              <br></br> conducting activities to ensure business continuity
            </span>
          </div>
          <div>
            <span>
              <strong>(ix)</strong>
              <br></br> conducting logistic activities
            </span>
          </div>
          <div>
            <span>
              <strong>(x)</strong>
              <br></br> conducting processes of sales of goods / services
            </span>
          </div>
          <div>
            <span>
              <strong>(xi)</strong>
              <br></br> conducting after-sales support services for goods /
              services
            </span>
          </div>
          <div>
            <span>
              <strong>(xii)</strong>
              <br></br> conducting marketing analysis studies
            </span>
          </div>
          <div>
            <span>
              <strong>(xiii)</strong>
              <br></br> conducting advertising / campaign / promotion processes
            </span>
          </div>
          <div>
            <span>
              <strong>(xiv)</strong>
              <br></br> conducting customer relationship management processes
            </span>
          </div>
          <div>
            <span>
              <strong>(xv)</strong>
              <br></br> conducting activities aimed at customer satisfaction
            </span>
          </div>
          <div>
            <span>
              <strong>(xvi)</strong>
              <br></br> tracking of demands / complaints
            </span>
          </div>
          <div>
            <span>
              <strong>(xvii)</strong>
              <br></br> conducting marketing processes of products / services
            </span>
          </div>
          <div>
            <span>
              <strong>(xviii)</strong>
              <br></br> will be processed for the purposes of conducting storage
              and archiving activities.
            </span>
          </div>

          <p>
            Your location data will be processed{' '}
            <strong>based on your explicit consent</strong>
            <br></br> to serve you better and to show events near you.
          </p>
          <br></br>

          <p>
            Your photo data will be processed{' '}
            <strong>based on your explicit consent</strong>
            <br></br> to serve you better and allow you to create your own
            profile.
          </p>
          <br></br>

          <p>
            <strong>
              What Are the Methods of Collecting Your Personal Data?
            </strong>
            <br></br>
          </p>
          <br></br>

          <p>
            Your personal data specified in categories above; through your use
            of our website, such as filling out the membership form, membership
            information page, support requests, are collected via means that are
            partly or completely automatic.
          </p>
          <br></br>
        </div>

        <div>
          <p>
            <strong>
              (II) What Is the Legal Reason for Collecting Your Personal Data?
            </strong>
            <br></br>
          </p>
          <br></br>
          <p>
            Your personal data is processed by Panoyabak; in order to achieve
            the purposes explained above, based on the legal grounds specified
            in Article 5 of PDPL;
          </p>
          <br></br>

          <div>
            <span>
              <strong>(i)</strong>
              <br></br> Being necessary for the data controller to fulfill its
              legal obligations,
            </span>
          </div>
          <div>
            <span>
              <strong>(ii)</strong>
              <br></br> Being necessary for the processing of personal data of
              the parties of a contract, provided that it is directly related to
              the conclusion or fulfillment of that contract,
            </span>
          </div>
          <div>
            <span>
              <strong>(iii)</strong>
              <br></br> Being necessary for the purposes of the legitimate
              interests pursued by the data controller, provided that this
              processing does not violate the fundamental rights and freedoms of
              the data subject
            </span>
          </div>
          <div>
            <span>are processed based on legal reasons.</span>
          </div>
        </div>

        <div>
          <p>
            <strong>
              (III) Do We Transfer Your Personal Data to a Third Party?
            </strong>
            <br></br>
          </p>
          <br></br>
          <p>
            The personal data mentioned above are transferred to authorized
            public institutions and organizations within the scope stipulated by
            legal regulations, for the purpose of conducting activities in
            accordance with legislation, following up and executing legal
            affairs, providing information. Additionally, the mentioned personal
            data may be transferred to the law office we receive service from
            and to judicial authorities in order to be used as evidence in
            potential legal disputes.
          </p>
          <br></br>
        </div>

        <div>
          <p>
            <strong>
              (IV) How Can You Exercise Your Rights Regarding Your Personal
              Data?
            </strong>
            <br></br>
          </p>
          <br></br>
          <p>
            You can forward your requests within the scope of Article 11 of
            PDPL, which regulates the rights of the relevant persons, to us
            through the “Data Controller Application Form” prepared for your
            convenience in accordance with the “Communiqué on the Procedures and
            Principles of Application to the Data Controller” located at{' '}
            <a href="https://panoyabak.com">www.panoyabak.com</a>.
          </p>
          <br></br>
        </div>

        <div>
          <div>
            <span>
              <strong>Data Controller:</strong>
              <br></br> Panoyabak
            </span>
          </div>
          <div>
            <span>
              <strong>Address:</strong>
              <br></br> Veliefendi neighborhood 75 2nd street no 84 d4
              Zeytinburnu Istanbul
            </span>
          </div>
          <div>
            <span>
              <strong>Email:</strong>
              <br></br> contact@panoyabak.com
            </span>
          </div>
        </div>
      </div>
    </>
  ),
};

const Terms = () => {
  const {
    i18n: { language },
  } = useTranslation();
  return (
    <MainLayout>
      <Box my="10">{selectByLanguage(data, language)}</Box>
      <Footer />
    </MainLayout>
  );
};

export default Terms;
