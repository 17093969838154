import { Box, Text } from '@chakra-ui/react';
import React from 'react';
import { Footer, MainLayout } from '../../components';
import { useTranslation } from 'react-i18next';
import { selectByLanguage } from '../../language';

const data = {
  tr: (
    <>
      <Text fontWeight={'bold'} fontSize="3xl" textAlign={'center'}>
        Gizlilik Politikası
      </Text>
      <br></br>

      <p>
        Bundan böyle,&nbsp;"Panoyabak" olarak anılacak "Panoyabak" , mobil
        uygulamasını/sitesini kullananların&nbsp;gizliliğini korumayı ilke
        olarak benimsemiştir. Bu nedenle, işbu gizlilik beyanı&nbsp;(“Gizlilik
        Beyanı”), web sitemizi kullanırken kullanıcılara ilişkin ne tür
        bilgilerin "Panoyabak" tarafından alındığını, bunların nasıl
        kullanıldığını ve korunduğunu açıklamaktadır. Ayrıca işbu Gizlilik
        Beyanı çerçevesinde bu bilgilerin doğruluğunun sizin tarafınızdan nasıl
        denetlenebileceği ve istediğinizde "Panoyabak" firmasına başvurarak bu
        bilgilerin silinmesini nasıl sağlayabileceğiniz belirtilmektedir. Söz
        konusu bilgilerin, mevcut ve geçerli olan veri koruma düzenlemelerine
        uygun olarak işlenmesi ve kullanılması için gerekli olan her türlü işlem
        "Panoyabak" tarafından yerine getirilecektir.
      </p>
      <br></br>
      <p>
        İşbu Gizlilik Beyanı, "www.panoyabak.com" mobil
        uygulamasında/sitesinde&nbsp;link verilmiş başka siteler/uygulamalar
        (hyperlinked web siteleri) için geçerli değildir.
      </p>
      <br></br>
      <p>
        <strong>
          Gizlilik Kuralları, Site Kullanım Şartları ve İletişimler ile Kişisel
          Verilerinize İlişkin Bilgilendirme
        </strong>
      </p>
      <br></br>
      <p>
        Gizlilik Kurallarımız, İletişimler, Mobil Uygulama/Site Kullanım
        Şartları ve Kişisel Verilerinizin İşlenmesi ile ilgili uygulamalarımız
        ve&nbsp; 6698 Sayılı Kişisel Verilerin Korunması Kanunu kapsamındaki
        haklarınız hususunda sizleri bilgilendirmek isteriz.
      </p>
      <br></br>
      <div>
        <p>
          "Panoyabak" ve "www.panoyabak.com" adlı Mobil Uygulamamızda (internet
          sitesi dahil), ziyaretçi-üye-müşteri bilgilerinin gizliliği, kişisel
          verilerin (bilgilerin) korunması, saklanması,
          işlenmesi-kullanımı-imhası, ticari elektronik iletişimler ve diğer
          hususlarda, aşağıda cari esasları belirtilen Gizlilik Kuralları,
          Politikası ve Uygulama Kullanım Şartları geçerlidir.
        </p>
        <br></br>
      </div>
      <p>
        <strong>
          İşlenen Kişisel Verileriniz - İletişimler ve Kişisel Verilerinize Dair
          Kanuni Haklarınız
        </strong>
      </p>
      <br></br>
      <p>
        İsim, soyisim, e-posta, cinsiyet,, , iş adresi, yaş, yaşanılan bölge,
        eğitim düzeyi, ilgi alanları, kıyafetler dahil her türlü ürüne ve
        hizmetlere ilişkin alışveriş alışkanlığı-tercih, ilgi, zevk ve
        beğenileri kapsayabilecek sosyal medya profilleri, fatura içerikleri,
        cep telefonu numarası, , , web site adresi, , gibi kişisel bilgileriniz
        ile cihaz kimliği, cihaz&nbsp; mikrofonu, cihaz&nbsp; takvimi, cihaz
        depolama, fotoğraflar ve/veya medyası, cihaz arka planda veri
        yenilemeleri, cihaz flaş ışığı, cihaz konum verileri, cihaz
        çağrıları/smsleri, cihaz bildirimleri, cihaz uygulama geçmişi, cihaz
        bluetoothu, cihaz kamerası, cihaza kayıtlı kontaklar, cihaz ses yardımı,
        cihaz mobil verileri&nbsp; tarafınızdan oluşturulan içeriği toplama ve
        yayınlama gibi mobil uygulamayı/internet sitesini kullandığınız
        cihazınıza ait bilgilerinizin toplanma yöntemi ve hukuki sebebi, hangi
        amaçla işleneceği ve kimlere hangi amaçla aktarılabileceği hususlarında
        tarafınıza böylece bilgi verilmesinin yanı sıra; kişisel bilgilerinizin
        ve/veya cihaz bilgilerinizin&nbsp; işlenip işlenmediğini, işlenmişse
        buna ilişkin bilgileri, amacına uygun kullanılıp kullanılmadığını, yurt
        içinde ve dışında aktarıldığı üçüncü kişileri öğrenme, bilme ve tüm bu
        hususlarda bilgi talep etme haklarına, keza eksik veya yanlış işlenmiş
        olmaları halinde düzeltilmesini, kanuni şartlarına ve yöntemine uygun
        olarak silinmesini veya yok edilmesini ve bu durumun verilerin
        aktarıldığı üçüncü kişilere bildirilmesini isteme, ayrıca,
        bilgilerinizin otomatik sistemler ile analiz edilmesi sureti ile
        aleyhinize bir sonucun ortaya çıkmasına itiraz etme ve kanuna aykırı
        olarak işlenmesi sebebi ile zarara uğramanız halinde giderilmesini talep
        etme haklarına sahip olduğunuzu, tüm bu&nbsp; konularda&nbsp;
        aşağıda&nbsp;isim ve iletişim bilgileri yazılı olan "Panoyabak" veri
        sorumlusuna başvurabileceğinizi bilgilerinize sunarız.
      </p>
      <br></br>
      <p>
        Şirketimiz ilgili mevzuat uyarınca ve kişisel veri saklama-imha
        politikalarında öngöreceği periyodlarda kişisel bilgileri kısmen/tamamen
        imha (silme, yok etme veya anonimleştirme) işlemleri yapabileceği gibi,
        Ziyaretçiler/Üyelerimiz/Kullanıcılarımız arzu ettikleri her zaman ve
        hiçbir gerekçe göstermeksizin aşağıda isim ve iletişim bilgileri yazılı
        olan "Panoyabak" veri sorumlusuna veya "Panoyabak" iletişim
        kanallarından Şirketimize ulaşarak ve gereken kanuni-teknik prosedürleri
        yerine getirerek kişisel veri işlenmelerini ve/veya taraflarına ticari
        elektronik iletişimleri durdurabilirler. Bu husustaki açık
        bildirim-taleplerine göre, kişisel veri işlemleri ve/veya belirttiği
        kanallar için tarafına iletişimler yasal azami süre içinde durdurulur
        (yasalara göre mümkün-gereken veya yükümlü olunan kişisel veri
        işlemeleri ve iletişimler devam eder).
        <span> Ziyaretçi/Müşteri/Üye/Kullanıcı</span>
        Ziyaretçi/Müşteri/Üye/Kullanıcı dilerse ayrıca, hukuken muhafazası
        gereken ve/veya mümkün olanlar haricindeki bilgileri, veri kayıt
        sisteminden silinir ya da yok edilir veya aynı amacı sağlamak üzere
        kimliği belirli olmayacak biçimde anonim hale getirilir. Bu hususlardaki
        başvuru ve talepler yasal azami süreler içinde yerine getirilecek yahut
        hukuki gerekçesi tarafına açıklanarak kabul edilmeyebilecektir (İlgili
        kanuni haklar saklıdır).
      </p>
      <br></br>
      <p>
        Ziyaretçi/Müşteri/Üye/Kullanıcı dilerse ayrıca, hukuken muhafazası
        gereken ve/veya mümkün olanlar haricindeki bilgileri, veri kayıt
        sisteminden silinir ya da yok edilir veya aynı amacı sağlamak üzere
        kimliği belirli olmayacak biçimde anonim hale getirilir. Bu hususlardaki
        başvuru ve talepler yasal azami süreler içinde yerine getirilecek yahut
        hukuki gerekçesi tarafına açıklanarak kabul edilmeyebilecektir (İlgili
        kanuni haklar saklıdır).
      </p>
      <br></br>
      <p>
        Akıllı telefon ve diğer cihazlarınızdaki çerezler ve bildirimlere
        ilişkin bilgilendirmeler, bunları düzenlemek için yapabileceğiniz
        işlemler aşağıda ayrıca belirtilmiştir.
      </p>
      <br></br>
      <p>
        <strong>
          Kişisel Bilgilerin Toplanması, Amacı, Kullanımı ve İşlenmesi
        </strong>
      </p>
      <br></br>
      <p>
        Belirli bir kişiye veya bu belirli kişinin cihazına ait kullanılacak ve
        işlenecek bilgiler, yalnızca bu kişinin kendi istemiyle veri girmesi ya
        da bu hususta açıkça muvafakat vermesi halinde mümkündür. Veri girilmesi
        veya bu hususta açıkça muvafakat verilmesi, kişinin aşağıda belirtilen
        şartlar ile mutabık olduğunun göstergesidir.
      </p>
      <br></br>
      <p>
        Uygulamamızı kullandığınızda , cihaz bilgilerini ve kullanım
        istatistiklerini ve uygulamalarının gerektirdiği bazı bilgiler
        sunucularımızda otomatik olarak depolanacaktır. Bu verilerin arasında
        uygulamazı kullandığınız cihaz bilgileri (marka, model,işletim sistemi
        vd.) uygulamamızdan ulaştığınız ve ziyaret ettiğiniz diğer
        siteler/uygulamalar,&nbsp; Internet servis sağlayıcınızın ismi, bazı
        durumlarda IP adresiniz ve/veya konumunuz, akılllı telefon yazılımınızın
        versiyonu, erişim sağlanan cihazın işletim sistemi, bulunmaktadır.
        Duruma bağlı olarak bu tür veriler, mobil uygulama kullanıcısı ve/veya
        internet site ziyaretçisi hakkında bazı sonuçlar çıkarılmasını sağlar.
        Ancak hiçbir kişisel bilgi ve/veya cihaz bilgisi bu çerçevede
        kullanılmayacaktır. Bu tür kişiye özel veriler sadece anonimleştirilerek
        kullanılabilir. "Panoyabak Internet servis sağlayıcınızın ismi, bazı
        durumlarda IP adresiniz ve/veya konumunuz, akılllı telefon yazılımınızın
        versiyonu, erişim sağlanan cihazın işletim sistemi, bulunmaktadır.
        Duruma bağlı olarak bu tür veriler, mobil uygulama kullanıcısı ve/veya
        internet site ziyaretçisi hakkında bazı sonuçlar çıkarılmasını sağlar.
        Ancak hiçbir kişisel bilgi ve/veua cihaz bilgisi bu çerçevede
        kullanılmayacaktır. Bu tür kişiye özel veriler sadece anonimleştirilerek
        kullanılabilir. "Panoyabak", bu bilgileri yasada öngörülen veya
        ziyaretçinin muvafakat beyannamesinde belirtilen sınırlamalar dahilinde
        kullanacağını, işleme tabi tutulacağını veya aktarılacağını taahhüt
        eder. Söz konusu kişisel bilgilerinin üçüncü kişilere aktarımı, sadece
        adli ya da idari mercilerce öngörülmesi halinde&nbsp;ve/veya "Panoyabak"
        firmasının bu siteye ilişkin haklarını 3. kişilere devretmesi söz konusu
        olacaktır.
      </p>
      <br></br>
      <p>
        <strong>
          Kişisel bilgilerini aşağıda belirtilen amaçlar için kullanıyoruz;
        </strong>
      </p>
      <br></br>
      <ul>
        <li>Çekirdek platform özelliklerini sağlama</li>
        <li>Ödeme işlemleri</li>
        <li>Web sitesine erişim</li>
        <li>Kullanıcılarla iletişim</li>
        <li>Kayıt tutma ve yönetme</li>
        <li>Analytics ve pazar araştırması</li>
        <li>Reklam pazarlama</li>
        <li>Uyum ve anlaşmazlıkların çözümü</li>
      </ul>
      <p>
        Bir hata veya çökme durumunda otomatik olarak sunucularımıza hatanın
        veya çökmenin sebebini anlamamızı kolaylaştıracak verileri yollarız. Bu
        verileri teknik değerlendirme için kullanmaktayız.
      </p>
      <br></br>
      <p>
        <strong>Kişiye Dair ve Çocuklara Dair Bilgiler</strong>
      </p>
      <br></br>
      <p>
        Ziyaretçilerin/Üyelerin/Kullanıcıların mobil uygulamamızdan/internet
        sitemizden&nbsp;veya uygulamamıza link verilen diğer sitelerden / mobil
        uygulamalardan / her türlü bildirimlerden edindikleri bilgiler,
        taraflarına elektronik iletişimi yapılan bilgilendirme, tanıtım ve
        reklamlar, keza her türlü öneriler çerçevesinde aldıkları kararlardan,
        bunlara göre yaptıkları her türlü işlem ve uygulama ile sonuçlarından
        Ziyaretçiler/Üyeler/Kullanıcılar sorumludurlar.
      </p>
      <br></br>
      <p>
        "Panoyabak" web sitesinin yetişkinler tarafından kullanılması öngörülmüş
        olup, "Panoyabak" mobil uygulaması/web sitesi çoçuklara yönelik
        değildir. "Panoyabak", 18 yaşından küçük çoçuklara ilişkin bilgilerle
        ilgilenmemektedir. "Panoyabak", söz konusu çoçuklara, herhangi bir
        kişiye ait bilgileri göndermemeleri konusunda mobil uygulamasında/web
        sitesinde uygun bir yerde açık yönlendirmelerde bulunacaktır.
        Ebeveynlerin ya da yasal velinin, gözetimleri altındaki çocukların
        herhangi bir kişiye ilişkin bilgileri "Panoyabak" nezdine
        ulaştırıldığına dair şüpheleri olması halinde, eğer söz konusu
        bilgilerin silinmesi isteniyorsa, aşağıda&nbsp;isim ve iletişim
        bilgileri yazılı olan "Panoyabak" veri sorumlusuna başvurabileceğinizi
        bilgilerinize sunarız. Böyle bir durumda, söz konusu bilgiler hiç vakit
        kaybetmeden silinecektir.
      </p>
      <br></br>
      <p>
        <strong>Çerez&nbsp;("Cookie"ler)</strong>
      </p>
      <br></br>
      <p>
        <strong>Çerezler</strong>
      </p>
      <br></br>
      <p>
        Çerezleri mobil uygulamamızdan/web sitemizden daha iyi faydalanmanızı
        sağlamak için kullanıyoruz. Çerezler tarayıcınız tarafından mobil
        cihazınızda &nbsp;geçici olarak saklanan küçük veri birimleri olup,
        mobil uygulamamızı daha iyi deneyimlemenizi sağlar. Çerezler hakkınızda
        kişisel bilgi içermez ve bireysel kullanıcıların kimliklerinin tespit
        edilmesi için kullanılamaz. Çerezler genellikle benzersiz bir
        tanımlayıcıya sahip olup, bu tanımlayıcı, cihazınızda saklanan anonim
        bir sayıdır&nbsp;(rastgele oluşturulur). Bazıları&nbsp;mobil
        uygulama&nbsp;oturumunuz bittiğinde sonlanırken bazıları da cihazınızda
        daha uzun süre kalır.
      </p>
      <br></br>
      <p>
        <strong>Çerez Türleri</strong>
      </p>
      <br></br>
      <p>
        <strong>Birinci Taraf Çerezleri</strong>
      </p>
      <br></br>
      <p>
        Birinci taraf çerezleri kullanmakta olduğunuz mobil uygulama tarafından
        tanımlanır ve sadece bu uygulama tarafından okunabilirler.
      </p>
      <br></br>
      <p>
        <strong>Üçüncü Taraf Çerezleri</strong>
      </p>
      <br></br>
      <p>
        Üçüncü taraf çerezleri farklı hizmetler için kullandığımız diğer
        organizasyonlar tarafından tanımlanır. Örneğin, faydalanmakta olduğumuz
        harici veri analizi hizmetleri ve bu hizmeti sunan tedarikçiler, neyin
        popüler olup neyin olmadığını raporlamak üzere bizim adımıza çerezleri
        tanımlarlar. Gezmekte olduğunuz web sitesi ayrıca YouTube'dan gömülmüş
        içerikler gibi içeriklere de sahip olabilir ve bu siteler kendi
        çerezlerini tanımlayabilirler.
      </p>
      <br></br>
      <p>
        <strong>Neden Çerezlere İzin Vermeliyim?</strong>
      </p>
      <br></br>
      <p>
        Çerezlerin içerdiği bilgiler tarafınıza sunulan hizmetleri geliştirmek
        için kullanılır, örneğin: bir servisin cihazınızı tanımasını
        etkinleştirmeniz durumunda bir oturum sırasında aynı bilgileri defalarca
        vermek zorunda kalmazsınız örneğin,
      </p>
      <br></br>
      <ul>
        <li>bir web formunun ya da web anketinin doldurulması</li>
        <li>Favorilerim&nbsp;(İçeriğim) fonksiyonunun desteklenmesi</li>
        <li>
          video oynatıcının düzgün şekilde fonksiyon göstermesine izin verilmesi
        </li>
        <li>
          servisleri ne kadar kişinin kullandığının ölçülmesi, bu servislerin
          etkinleştirilerek kullanımlarının kolay hale getirilmesi ve hızlı
          şekilde sunulmaya devam edilmesi için yeterli kapasite olmasının
          sağlanması, anonim verilerin analiz edilerek insanların çevrim içi
          hizmetlerimizin farklı açılarıyla nasıl etkileşimde olduklarını
          anlamamızı sağlaması ve bu hizmetleri nasıl daha iyi hale
          getirebileceğimizi anlamamıza yardımcı olması
        </li>
      </ul>
      <p>
        <strong>Kabul Etmemem Durumunda Ne Olur?</strong>
      </p>
      <br></br>
      <p>
        Çerezleri kabul etmemeye karar vermeniz durumunda, bu web sitesinin
        belirli özellikleri görüntülenemeyebilir ya da doğru çalışmayabilir. Bu
        da web sitemizde yapacaklarınızı sınırlar ve tasarım ve kullanıcı
        deneyimi üzerinde etkiye sahip olabilir.Etkilenmesi muhtemel özellikler
        arasında şunlar bulunur:
      </p>
      <br></br>
      <p>
        <strong>
          mobil uygulamanın/web sitesinin kişiselleştirilmiş şekilde
          deneyimlenememesi
        </strong>
      </p>
      <br></br>
      <p>
        Panoyabak Çerez verilerinin iletişiminde SSL sertifikası kullanmaktadır.
      </p>
      <br></br>
      <p>
        <strong>Diğer</strong>
      </p>
      <br></br>
      <p>
        Diğer birinci taraf ya da üçüncü taraf çerezler de iFrames, JavaScript
        vs. gibi teknolojilerin kullanılmasıyla mobil uygulamada&nbsp;gömülü
        bulunan içerikte&nbsp;(harici) kullanılabilir. Çerezleri kabul ederek bu
        tarz içeriklerin de çerez kullanabileceğini kabul etmiş olursunuz.
      </p>
      <br></br>
      <p>
        Panoyabak üçüncü taraf çerezler üzerinde herhangi bir kontrole sahip
        olmadığı gibi bunlara ilişkin herhangi bir yükümlülük de kabul etmez.
        Daha ayrıntılı bilgi almak için üçüncü taraf web sitesine başvurun.
      </p>
      <br></br>
      <p>
        "Panoyabak" tarafından Opt-out çerez (cookie) ayarlanmıştır. Bu seçim
        çerezi silene kadar geçerlidir. "Panoyabak" uyglaması/websitesi için her
        cihaz için ayrı çerez ayarlanmıştır. Bu nedenle, farklı cihazlarla mobil
        uygulamamızı kullanırsanız, her bir aygıt için ayrıca veri depolamasını
        reddetmeniz gerekir.
      </p>
      <br></br>
      <p>
        <strong>
          Üçüncü Taraflara Ait Siteler-Dijital Platformlar ve Uygulamalar
        </strong>
      </p>
      <br></br>
      <p>
        Mobil uygulamamızdan&nbsp;(internet sitesi dahil) ulaşılan diğer
        sitelerde kendilerine ait gizlilik-güvenlik politikaları, kullanım,
        iletişim ve kişisel veri işlenme şartları geçerlidir; reklam, banner,
        içerik görmek veya başka herhangi bir amaç ile ulaşılan web
        sitelerinden/mobil uygulamalarından&nbsp;bilgi kullanımları, keza
        sitelerin (ve sair dijital platformların) etik ilkeleri,
        gizlilik-güvenlik prensipleri, kişisel veri saklama ve imha
        politikaları, servis kalitesi, kullanım şartları, çerezleri-web
        işaretçileri ve benzerleri, bildirimleri-önerileri ve diğer uygulamaları
        sebebi ile oluşabilecek ihtilaf, maddi-manevi zarar ve kayıplardan
        "Panoyabak" sorumlu değildir.
      </p>
      <br></br>
      <p>
        <strong>Kullanılan 3. Taraf Servisleri</strong>
      </p>
      <br></br>
      <ul>
        <li>Servis sağlayıcılar</li>
        <li>Ödeme servisi sunucuları</li>
        <li>Veri toplayıcıları ve işlemcileri</li>
      </ul>
      <p>
        <strong>Web İzleme</strong>
      </p>
      <br></br>
      <p>
        Bu mobil uygulama "Panoyabak" üçüncü taraf hizmetlerini kullanarak
        servis sağlayıcılarla, çalışan personel ve/veya taşeron aracılığıyla,
        sponsor ve destekleyecilerle, ödeme servisi sağlayıcıları, pazarlama
        şirketleri aracılığı ile, pazarlama ve optimizasyon amaçlı veri toplar
        ve saklar. Bu veriler anonimleştirilmiş kullanıcı profilleri oluşturmak
        için kullanılabilir. Çerezler ayarlanmış olabilir.
      </p>
      <br></br>
      <p>
        Kullanıcılarımız tarafından açık bir anlaşma olmaksızın "Panoyabak" ile
        toplanan veriler kişisel bir ziyaretçinin kimliğini belirlemek için
        kullanılmaz ve anonim herhangi diğer kişisel bilgileri ile
        birleştirilmez.
      </p>
      <br></br>
      <p>
        "panoyabak@gmail.com" adresine e-posta yollayarak veri toplama ve
        depolama işlemini reddettiğinizi bildirebilirsiniz. Bu durumda
        verileriniz toplanmaz.
      </p>
      <br></br>
      <p>
        <strong>Edinilen bilgilerin 3. kişilere devri</strong>
      </p>
      <br></br>
      <p>
        Panoyabak tarafından işbu web sitesinin 3. kişilere satılması veya devir
        edilmesi halinde, verileriniz de web sitesi ile birlikte tarafınızın
        ayrıca yazılı iznine gerek olmaksızın devir edilebilir/ aktarılabilir.
      </p>
      <br></br>
      <p>
        <strong>İletişimler</strong>
      </p>
      <br></br>
      <p>
        Ziyaretçi/Müşteri/Üyelerimiz/Kullanıcılarımız&nbsp;ile, yine kanunlara
        uygun şekilde, her türlü ürün ve hizmetlere ilişkin tanıtım, reklam,
        iletişim, promosyon, satış ve pazarlama amacıyla, keza mağaza kartı,
        kredi kartı ve üyelik bilgilendirme, işlem, uygulamaları için
        "Panoyabak" tarafından SMS/kısa mesaj, anlık bildirim, otomatik arama,
        bilgisayar, telefon, e-posta/mail, faks, (cihazınızda açık ise) her
        türlü bildirimler, bluetooth, beacon-diğer her türlü genel-özel kablosuz
        ağlar ve diğer elektronik iletişim araçları ile sosyal, ticari ve sair
        elektronik iletişimler yapılabilir, Ziyaretçi/Müşteri/Üyelerimize ticari
        elektronik iletiler gönderilebilir.
      </p>
      <br></br>
      <p>
        <strong>Alışverişler ve Diğer Tüketici İşlemleri</strong>
      </p>
      <br></br>
      <p>
        Ziyaretçilerin/Üyelerin/Kullanıcıların yukarıda belirtilen suretler ile
        bilgilenmeleri ve/veya taraflarına yapılan iletişimler,
        bilgilendirmeler, bildirimler, tanıtım ve reklamlar neticesi bir
        ürün/hizmet satın alması söz konusu olursa anılan işlem ayrıca ve kanuni
        usulünce ilgili satıcı/sağlayıcı ile yapacakları tüketici sözleşmesine
        tabidir. Tüketici sözleşmesi kendi şartlarında ve tarafları arasında
        uygulanır.
      </p>
      <br></br>
      <p>
        (Varsa) Uygulamamızdan/Sitemizden alışverişlerinizde, her işlem
        sırasında göreceğiniz sipariş ön bilgilendirme formu-mesafeli satış
        sözleşmesi şartları geçerli olacaktır.
      </p>
      <br></br>
      <p>
        <strong>İstekleriniz ve Sorularınız</strong>
      </p>
      <br></br>
      <p>
        Depolanan bilgiler, yasal veya sözleşmesel dosyalama süresi sona
        erdiğinde "Panoyabak" veya varsa "Panoyabak" partnerlerinin artık
        gereksinimi kalmaması halinde "Panoyabak" veya "Panoyabak" partnerleri
        tarafından imha (silme, yok etme veya anonimleştirme) edilecektir.
      </p>
      <br></br>
      <p>
        Ancak, elbette siz istediğiniz zaman size ait bilgilerin silinmesini
        talep edebilirsiniz. Ayrıca gelecekte, istediğiniz zaman kişisel
        bilgilerinizin kullanım ya da işleme tabi tutulmasına dair
        muvafakatinizi geri çekebilirsiniz. Bu husustaki açık
        bildirim-taleplerine göre, kişisel veri işlemleri ve/veya belirttiği
        kanallar için tarafına iletişimler yasal azami süre içinde durdurulur
        (yasalara göre mümkün-gereken veya yükümlü olunan kişisel veri
        işlemeleri ve iletişimler devam eder).Bu tür durumlarda ya da kişisel
        bilgilerinize ait diğer tüm sorularınız konusunda elektronik posta
        yoluyla veri koruma sorumlusu "Ahmet Topal" adlı kişiye
        danışabilirsiniz. Size ait bilgilerin saklanıp ve bu bilgilerin kullanım
        alanlarına ilişkin sorularınız için bizimle yine aynı yöntemle temasa
        geçebilirsiniz. Dileklerinize anında yanıt vermek için elimizden gelen
        tüm çabayı göstereceğimizden emin olabilirsiniz.
      </p>
      <br></br>
      <p>
        <strong>Mobil Uygulama&nbsp;Üzerinde Haklar</strong>
      </p>
      <br></br>
      <p>
        Mobil uygulamamızdaki/sitemizdeki&nbsp;her türlü bilgi ve içerik ile
        bunların düzenlenmesi, revizyonu ve kısmen/tamamen kullanımı konusunda;
        diğer üçüncü şahıslara ait olanlar hariç, tüm fikri-sınai haklar ve
        mülkiyet hakları şirketimiz "Panoyabak" şirketine aittir.
      </p>
      <br></br>
      <p>
        <strong>Değişiklikler</strong>
      </p>
      <br></br>
      <p>
        Şirketimiz gizlilik, kişisel veri saklama-kullanım ve imha politikası
        ve&nbsp;mobil uygulama&nbsp;kullanım şartları gibi konularda, keza
        müşteri-üyelerine sunacağı ürün, hizmet ve fırsatlarda, kampanya
        vb.lerinde gerekli görebileceği her türlü değişikliği yapma hakkını
        saklı tutar; bu değişiklikler şirketimizce uygulamadan/siteden veya
        diğer uygun yöntemler ile duyurulduğu andan itibaren geçerli olur.
      </p>
      <br></br>
      <p>
        <strong>Sosyal Medya Eklentilerinin Kullanımı</strong>
      </p>
      <br></br>
      <p>
        Sosyal ağlar facebook.com ve Twitter
        eklentileri&nbsp;(“eklentiler”)&nbsp;mobil
        uygulamamıza/sitemize&nbsp;gömülebilir. İlgili servisler Facebook Inc.
        ve Twitter Inc.&nbsp;(“servis sağlayıcılar”) şirketleri tarafından
        sağlanır.
      </p>
      <br></br>
      <p>
        Facebook, Facebook Inc., 1601 S. California Ave, Palo Alto, CA 94304,
        ABD ("Facebook") tarafından işletilmektedir. Facebook eklentileri ve
        görünümlerini gözden geçirmek için şu adresi ziyaret edebilirsiniz:
        <ul>
          <li>
            <a
              href="https://developers.facebook.com/docs/plugins"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://developers.facebook.com/docs/plugins
            </a>
          </li>
        </ul>
      </p>
      <br></br>
      <p>
        Twitter, Twitter Inc., 1355 Market St, Suite 900, San Francisco, CA
        94103, ABD tarafından işletilmektedir. Twitter butonları ve
        görünümlerini gözden geçirmek için şu adresi ziyaret edebilirsiniz:
        <ul>
          <li>
            <a
              href="https://dev.twitter.com/web/tweet-button"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://dev.twitter.com/web/tweet-button
            </a>
          </li>
        </ul>
      </p>
      <br></br>
      <p>
        Mobil uygulamamızı veya internet sitemizi kullandığınızda&nbsp;ziyaret
        ettiğinizde verilerinizin korunma seviyesini artırmak için bu eklentiler
        “Çift tıklanan butonlar” olarak uygulamaya konmuştur. Bu tarz bir
        entegrasyon bu tarz eklentiler içeren web sitelerine/mobil
        uygulamalarına, web sitemiz içerisinden eriştiğinizde, otomatik olarak
        servis sağlayıcıların sunucularıyla bağlantı kurmamanızı sağlar. Sadece
        bu eklentileri etkinleştirmeniz durumunda veri aktarımına izin vermiş
        olursunuz ve tarayıcı servis sağlayıcıların sunucularına doğrudan bir
        bağlantı oluşturur. Çeşitli eklentilerin içeriği ardından ilgili servis
        sağlayıcı tarafından doğrudan tarayıcınıza aktarılır ve ardından
        ekranınızda görüntülenir.
      </p>
      <br></br>
      <p>
        Eklenti servis sağlayıcıya hangi&nbsp;mobil
        uygulama&nbsp;sayfalarımızı/internet sitemizi ziyaret ettiğiniz söyler.
        Mobil uygulamamızı veya internet sitemizi kullanırken/gezerken Facebook
        ya da Twitter kullanıcı hesabınıza bağlı olmanız durumunda, servis
        sağlayıcı kullanıcı hesabınızla eriştiğiniz bilgiler gibi ilgi
        duyduğunuz alanlar hakkında veri toplayabilir. Herhangi eklenti
        fonksiyonunu kullanırken&nbsp;(örneğin “Like” [Beğeni] butonuna
        tıklandığında, yorum yazıldığında) bu bilgi de tarayıcı tarafından
        doğrudan saklanmak üzere servis sağlayıcıya aktarılır.
      </p>
      <br></br>
      <p>
        Facebook ya da Twitter tarafından veri toplanması ve kullanımı hakkında
        daha ayrıntılı bilgilere ve bu şartlar altında gizliliğinizi korumak
        için faydalanabileceğiniz haklar ve olasılıklara servis sağlayıcının
        veri koruma/gizlilik tavsiyeleri altından ulaşabilirsiniz.
      </p>
      <br></br>
      <p>
        Veri koruma/Facebook tarafından yayınlanan gizlilik tavsiyesi:
        <ul>
          <li>
            <a
              href="http://www.facebook.com/policy.php"
              target="_blank"
              rel="noopener noreferrer"
            >
              http://www.facebook.com/policy.php
            </a>
          </li>
        </ul>
      </p>
      <br></br>
      <p>
        Veri koruma/Twitter tarafından yayınlanan gizlilik tavsiyesi:
        <ul>
          <li>
            <a
              href="https://twitter.com/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://twitter.com/privacy
            </a>
          </li>
        </ul>
      </p>
      <br></br>
      <p>
        Facebook ya da Twitter'ın, sitelerinde/uygulamalarında sahip olduğunuz
        kullanıcı hesabınızla web sitemize ziyaretinize ilişkin bağlantı
        kurmasını engellemek için, mobil uygulamamıza erişmeden önce ilgili
        kullanıcı hesaplarında oturumunuzu kapatmanız gerekir.
      </p>
      <br></br>
      <p>
        <strong>Verilerinizin Depolandığı ve / veya İşlendiği Ülkeler</strong>
      </p>
      <br></br>
      <p>TR - Türkiye</p>
      <br></br>
      <p>
        <strong>İletişim</strong>
      </p>
      <br></br>
      <p>
        Gizlilik Politikası hakkında herhangi bir sorunuz veya öneriniz varsa,
        info@panoyabak.com adresinden bizimle iletişime geçmekten çekinmeyin.
      </p>
    </>
  ),
  en: (
    <>
      <>
        <Text fontWeight={'bold'} fontSize="3xl" textAlign={'center'}>
          Privacy Policy
        </Text>
        <br></br>

        <p>
          From now on, "Panoyabak," which will be referred to as "Panoyabak,"
          has adopted the principle of protecting the privacy of those using its
          mobile application/website. Therefore, this privacy statement
          ("Privacy Statement") explains what kind of information regarding
          users is collected by "Panoyabak" while using our website, how these
          are used, and protected. Additionally, within the framework of this
          Privacy Statement, it is indicated how the accuracy of this
          information can be controlled by you and how you can request the
          deletion of this information by applying to the "Panoyabak" company
          whenever you wish. All necessary actions will be carried out by
          "Panoyabak" for the processing and usage of the said information in
          accordance with the current and applicable data protection
          regulations.
        </p>
        <br></br>
        <p>
          This Privacy Statement does not apply to other sites/applications
          (hyperlinked websites) linked on the "www.panoyabak.com" mobile
          application/website.
        </p>
        <br></br>
        <p>
          <strong>
            Privacy Rules, Terms of Use of the Site, Communications, and
            Information Regarding Your Personal Data
          </strong>
        </p>
        <br></br>
        <p>
          We would like to inform you about our Privacy Rules, Communications,
          Mobile Application/Site Terms of Use, and our practices regarding the
          Processing of Your Personal Data, including your rights under the
          Personal Data Protection Law No. 6698.
        </p>
        <br></br>
        <div>
          <p>
            The privacy of visitor-member-customer information, the protection,
            storage, processing-use-destruction of personal data (information),
            commercial electronic communications, and other matters are governed
            by the Privacy Rules, Policy, and Application Use Terms specified
            below at "Panoyabak" and "www.panoyabak.com" Mobile Application
            (including the website).
          </p>
          <br></br>
        </div>
        <p>
          <strong>
            Your Personal Data Processed - Communications and Legal Rights
            Regarding Your Personal Data
          </strong>
        </p>
        <br></br>
        <p>
          Personal information such as name, surname, email, gender, business
          address, age, region of residence, educational level, interests,
          shopping habits-preferences related to all kinds of products and
          services, tastes, and likes that may cover social media profiles,
          invoice contents, mobile phone number, website address, and
          information regarding the device you use when utilizing the mobile
          application/internet site such as device ID, microphone, calendar,
          storage, photos and/or media, background data refresh, flash light,
          location data, device calls/SMS, notifications, app history,
          bluetooth, camera, registered contacts, voice assistant, and mobile
          data. We hereby inform you about the method and legal reason for
          collecting and publishing such content created by you, for what
          purposes it will be processed, and to whom it can be transferred,
          alongside your rights to learn whether your personal data has been
          processed, to request information if processed, to learn whether it is
          used in accordance with its purpose, to know the third parties to whom
          it is transferred domestically or abroad, to request correction if
          processed incompletely or inaccurately, to request deletion or
          destruction under the legal conditions and method, and to request
          notification of these actions to third parties to whom personal data
          has been transferred. You also have the right to object to the
          emergence of a result against you through the analysis of your data by
          automatic systems and to demand compensation for damages arising from
          the unlawful processing of your data. We present that you can apply to
          the "Panoyabak" data controller, whose name and contact information
          are provided below, on all these matters.
        </p>
        <br></br>
        <p>
          Our company may destroy (delete, destroy, or anonymize) personal
          information partially/completely at periods envisaged in the relevant
          legislation and personal data storage-destruction policies.
          Visitors/Members/Users can stop the processing of their personal data
          and/or commercial electronic communications to them at any time and
          without giving any reason by reaching out to the "Panoyabak" data
          controller or by contacting our Company through the "Panoyabak"
          communication channels and fulfilling the required legal-technical
          procedures. According to the explicit notifications-requests in this
          regard, personal data operations and/or communications to the
          specified channels are stopped within the legal maximum period
          (personal data operations and communications required or obliged by
          law continue).
          <span> Visitor/Customer/Member/User</span>
          The Visitor/Customer/Member/User may also request the deletion or
          destruction of their information from the data recording system or
          anonymized to achieve the same purpose for those legally preserved
          and/or possible, excluding. These applications and requests will be
          fulfilled within the legal maximum periods or may not be accepted by
          explaining the legal reason to the party (Related legal rights are
          reserved).
        </p>
        <br></br>
        <p>
          Information about cookies and notifications on your smartphones and
          other devices, and the actions you can take to regulate them are
          specified below separately.
        </p>
        <br></br>
        <p>
          <strong>
            Collection, Purpose, Use, and Processing of Personal Information
          </strong>
        </p>
        <br></br>
        <p>
          Information that will be used and processed specific to a person or
          this specific person's device is only possible with the data entry by
          the person themselves or explicitly consenting to this matter.
          Entering data or explicitly consenting to this matter indicates that
          the person agrees with the conditions stated below.
        </p>
        <br></br>
        <p>
          When you use our application, device information and usage statistics,
          and some information required by our applications will be
          automatically stored on our servers. This data includes the device
          information (brand, model, operating system, etc.) you use to access
          our application, other sites/applications you visit through our
          application, your Internet service provider's name, in some cases your
          IP address and/or location, the version of your smartphone software,
          the operating system of the accessed device. Depending on the
          situation, such data may allow some conclusions to be drawn about the
          mobile application user and/or internet site visitor. However, no
          personal information and/or device information will be used in this
          context. Such personal data can only be used in an anonymized manner.
          "Panoyabak" commits to using, processing, or transferring this
          information within the limitations provided by law or stated in the
          visitor's consent declaration. The transfer of such personal
          information to third parties will only be in the case of judicial or
          administrative authorities' forecast and/or if "Panoyabak" company
          transfers the rights of this site to third parties.
        </p>
        <br></br>
        <p>
          <strong>
            We use your personal information for the following purposes;
          </strong>
        </p>
        <br></br>
        <ul>
          <li>Providing core platform features</li>
          <li>Payment transactions</li>
          <li>Access to the website</li>
          <li>Communication with users</li>
          <li>Record keeping and management</li>
          <li>Analytics and market research</li>
          <li>Advertising marketing</li>
          <li>Compliance and dispute resolution</li>
        </ul>
        <p>
          In case of an error or crash, we automatically send data that will
          help us understand the cause of the error or crash to our servers. We
          use this data for technical evaluation.
        </p>
        <br></br>
        <p>
          <strong>Information about Individuals and Children</strong>
        </p>
        <br></br>
        <p>
          Information obtained by Visitors/Members/Users from our mobile
          application/website or other sites / mobile applications / all kinds
          of notifications linked to our application, the decisions taken by the
          parties within the framework of the information, promotion, and
          advertisements communicated electronically to them, and all kinds of
          transactions and applications made according to these and their
          results are the responsibility of the Visitors/Members/Users.
        </p>
        <br></br>
        <p>
          The "Panoyabak" website is intended for use by adults, and the
          "Panoyabak" mobile application/website is not aimed at children.
          "Panoyabak" is not interested in information about children under the
          age of 18. "Panoyabak" will provide appropriate guidance in its mobile
          application/website to ensure that children do not send any personal
          information. If parents or legal guardians have doubts that children
          under their supervision have conveyed personal information to
          "Panoyabak," and if they wish for this information to be deleted, they
          can contact the "Panoyabak" data controller, whose name and contact
          information are written below. In such a case, the said information
          will be deleted without delay.
        </p>
        <br></br>
        <p>
          <strong>Cookies&nbsp;("Cookies")</strong>
        </p>
        <br></br>
        <p>
          <strong>Cookies</strong>
        </p>
        <br></br>
        <p>
          We use cookies to make your use of our mobile application/website
          better. Cookies are small data units that are temporarily stored on
          your mobile device by your browser, making your experience of our
          mobile application better. Cookies do not contain personal information
          and cannot be used to identify individual users. Cookies usually have
          a unique identifier, which is an anonymous number stored on your
          device (randomly generated). Some end when your mobile application
          session ends, while some stay on your device longer.
        </p>
        <br></br>
        <p>
          <strong>Types of Cookies</strong>
        </p>
        <br></br>
        <p>
          <strong>First-Party Cookies</strong>
        </p>
        <br></br>
        <p>
          First-party cookies are defined by the mobile application you are
          using and can only be read by this application.
        </p>
        <br></br>
        <p>
          <strong>Third-Party Cookies</strong>
        </p>
        <br></br>
        <p>
          Third-party cookies are defined by other organizations we use for
          different services. For example, the external data analysis services
          we use and the suppliers that offer this service define cookies on our
          behalf to report what is popular and what is not. The website you are
          browsing may also contain content such as embedded videos from
          YouTube, and these sites can define their own cookies.
        </p>
        <br></br>
        <p>
          <strong>Why Should I Allow Cookies?</strong>
        </p>
        <br></br>
        <p>
          The information contained in cookies is used to improve the services
          offered to you, for example: allowing a service to recognize your
          device so you don't have to give the same information several times
          during one task, for example,
        </p>
        <br></br>
        <ul>
          <li>filling out a web form or a web survey</li>
          <li>supporting the My Favorites (My Content) function</li>
          <li>allowing the video player to function properly</li>
          <li>
            measuring how many people are using services, enabling those
            services to be activated more easily and ensuring that there is
            enough capacity to keep them fast, analyzing anonymous data to help
            us understand how people interact with different aspects of our
            online services, and how we can make these services better
          </li>
        </ul>
        <p>
          <strong>What Happens If I Don't Accept?</strong>
        </p>
        <br></br>
        <p>
          If you decide not to accept cookies, certain features of this website
          may not be displayed or work correctly. This limits what you can do on
          our website and can have an impact on design and user experience.
          Features that might be affected include:
        </p>
        <br></br>
        <p>
          <strong>
            not experiencing the mobile application/website in a personalized
            way
          </strong>
        </p>
        <br></br>
        <p>Panoyabak uses an SSL certificate for cookie communications.</p>
        <br></br>
        <p>
          <strong>Other</strong>
        </p>
        <br></br>
        <p>
          Other first-party or third-party cookies can also be used in content
          embedded in the mobile application (external) using technologies such
          as iFrames, JavaScript, etc. By accepting cookies, you consent to
          these types of content being able to use cookies as well.
        </p>
        <br></br>
        <p>
          Panoyabak does not have any control over third-party cookies nor does
          it assume any responsibility. For more detailed information, refer to
          the third-party website.
        </p>
        <br></br>
        <p>
          An Opt-out cookie (cookie) has been set by "Panoyabak." This choice is
          valid until the selection cookie is deleted. "Panoyabak" has set
          separate cookies for each device for the "Panoyabak"
          application/website. Therefore, if you use our mobile application with
          different devices, you must separately refuse data storage for each
          device.
        </p>
        <br></br>
        <p>
          <strong>Third-Party Sites-Digital Platforms and Applications</strong>
        </p>
        <br></br>
        <p>
          Other sites accessed from our mobile application (including the
          website) have their own privacy-security policies, usage,
          communication, and personal data processing terms; information usages
          from websites/mobile applications accessed for advertisement, banner,
          content viewing, or any other purpose, as well as the ethical
          principles, privacy-security principles, personal data storage, and
          destruction policies, service quality, usage conditions, cookies-web
          beacons, and similar, notifications-proposals, and other applications
          of the sites (and other digital platforms) are not the responsibility
          of "Panoyabak."
        </p>
        <br></br>
        <p>
          <strong>Used 3rd Party Services</strong>
        </p>
        <br></br>
        <ul>
          <li>Service providers</li>
          <li>Payment service providers</li>
          <li>Data collectors and processors</li>
        </ul>
        <p>
          <strong>Web Tracking</strong>
        </p>
        <br></br>
        <p>
          This mobile application "Panoyabak" collects and stores data for
          marketing and optimization purposes using third-party services through
          service providers, working personnel and/or subcontractors, sponsors
          and supporters, payment service providers, marketing companies, and
          collects and stores data. This data can be used to create anonymized
          user profiles. Cookies may be set.
        </p>
        <br></br>
        <p>
          Data collected by "Panoyabak" without a clear agreement from our users
          will not be used to personally identify a visitor and will not be
          merged with any anonymous personal information.
        </p>
        <br></br>
        <p>
          You can inform us that you refuse the collection and storage of data
          by sending an email to "panoyabak@gmail.com." In this case, your data
          will not be collected.
        </p>
        <br></br>
        <p>
          <strong>Transfer of acquired information to third parties</strong>
        </p>
        <br></br>
        <p>
          In case this website is sold or transferred to third parties by
          Panoyabak, your data can also be transferred/transferred without the
          need for your separate written consent along with the website.
        </p>
        <br></br>
        <p>
          <strong>Communications</strong>
        </p>
        <br></br>
        <p>
          We can communicate with our Visitors/Customers/Members/Users in
          compliance with the laws, for any product and service related to
          promotion, advertisement, communication, promotion, sales, and
          marketing purposes, as well as for store card, credit card, and
          membership information, transactions, applications through SMS/short
          message, instant notification, automatic call, computer, phone,
          email/mail, fax, (if open on your device) all kinds of notifications,
          bluetooth, beacon-other all kinds of general-private wireless networks
          and other electronic communication tools with social, commercial, and
          other electronic communications, commercial electronic messages can be
          sent to our Visitors/Customers/Members.
        </p>
        <br></br>
        <p>
          <strong>Shopping and Other Consumer Transactions</strong>
        </p>
        <br></br>
        <p>
          If a product/service purchase occurs as a result of the information,
          communications, notifications, promotions, and advertisements
          communicated to the Visitors/Members/Users in the above-mentioned
          manners, such transaction is subject to the consumer contract to be
          made legally and according to the procedure with the relevant
          seller/provider. The consumer contract applies under its own
          conditions and between the parties.
        </p>
        <br></br>
        <p>
          (If applicable) During your purchases from Our Application/Site, the
          terms of the order pre-information form-distance sales contract seen
          during each transaction will apply.
        </p>
        <br></br>
        <p>
          <strong>Your Requests and Questions</strong>
        </p>
        <br></br>
        <p>
          Stored information will be destroyed (deleted, destroyed, or
          anonymized) by "Panoyabak" or "Panoyabak" partners when the legal or
          contractual filing period expires and there is no longer a need for
          "Panoyabak" or "Panoyabak" partners.
        </p>
        <br></br>
        <p>
          However, of course, you can request the deletion of your information
          at any time. Additionally, in the future, you can withdraw your
          consent to the use or processing of your personal information at any
          time. According to the explicit notifications-requests in this regard,
          personal data operations and/or communications to the specified
          channels are stopped within the legal maximum period (personal data
          operations and communications required or obliged by law continue).
          You can consult the data protection officer "Ahmet Topal" by email for
          these situations or any other questions regarding your personal
          information. You can also contact us in the same way for questions
          about the storage of your information and its areas of use. We assure
          you that we will do our utmost to respond to your wishes immediately.
        </p>
        <br></br>
        <p>
          <strong>Rights Over the Mobile Application</strong>
        </p>
        <br></br>
        <p>
          All intellectual and industrial property rights and ownership rights
          regarding all information and content in our mobile application/our
          site, including their arrangement, revision, and partial/complete use;
          except those belonging to other third parties, belong to our company
          "Panoyabak."
        </p>
        <br></br>
        <p>
          <strong>Changes</strong>
        </p>
        <br></br>
        <p>
          Our company reserves the right to make any changes it deems necessary
          in topics such as privacy, personal data storage-use and destruction
          policy, and mobile application usage terms, as well as in the
          products, services, and opportunities offered to its
          customers-members, campaigns, etc.; these changes become effective
          immediately upon announcement by our company through the
          application/site or other appropriate methods.
        </p>
        <br></br>
        <p>
          <strong>Use of Social Media Plugins</strong>
        </p>
        <br></br>
        <p>
          Social networks facebook.com and Twitter
          plugins&nbsp;("plugins")&nbsp;can be embedded in our mobile
          application/site. The relevant services are provided by Facebook Inc.
          and Twitter Inc.&nbsp;("service providers").
        </p>
        <br></br>
        <p>
          Facebook is operated by Facebook Inc., 1601 S. California Ave, Palo
          Alto, CA 94304, USA ("Facebook"). You can review Facebook plugins and
          appearances by visiting the following address:
          <ul>
            <li>
              <a
                href="https://developers.facebook.com/docs/plugins"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://developers.facebook.com/docs/plugins
              </a>
            </li>
          </ul>
        </p>
        <br></br>
        <p>
          Twitter is operated by Twitter Inc., 1355 Market St, Suite 900, San
          Francisco, CA 94103, USA. You can review Twitter buttons and
          appearances by visiting the following address:
          <ul>
            <li>
              <a
                href="https://dev.twitter.com/web/tweet-button"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://dev.twitter.com/web/tweet-button
              </a>
            </li>
          </ul>
        </p>
        <br></br>
        <p>
          When you visit our mobile application or website, to increase the
          level of data protection, these plugins are implemented as
          "Double-click buttons." Such integration ensures that you do not
          automatically connect to the service providers' servers when you
          access websites/mobile applications containing such plugins from our
          site. Only by activating these plugins do you give permission for data
          transmission, and your browser creates a direct connection to the
          service providers' servers. The content of the various plugins is then
          transferred from the relevant service provider directly to your
          browser and displayed on your screen.
        </p>
        <br></br>
        <p>
          The plugin tells the service provider which of our mobile application
          pages/internet site you have visited. If you are logged into your
          Facebook or Twitter user account while using our mobile application or
          browsing our website, the service provider can collect data on areas
          of interest such as the information you access. If you use any plugin
          function (for example, clicking the "Like" button, writing a comment),
          this information is also transferred directly to the service provider
          for storage by the browser.
        </p>
        <br></br>
        <p>
          You can find more detailed information on data collection and use by
          Facebook or Twitter and the rights and possibilities available to
          protect your privacy under the service provider's data
          protection/privacy advisories.
        </p>
        <br></br>
        <p>
          Data protection/Facebook published privacy advice:
          <ul>
            <li>
              <a
                href="http://www.facebook.com/policy.php"
                target="_blank"
                rel="noopener noreferrer"
              >
                http://www.facebook.com/policy.php
              </a>
            </li>
          </ul>
        </p>
        <br></br>
        <p>
          Data protection/Twitter published privacy advice:
          <ul>
            <li>
              <a
                href="https://twitter.com/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://twitter.com/privacy
              </a>
            </li>
          </ul>
        </p>
        <br></br>
        <p>
          To prevent Facebook or Twitter from linking your visit to our site/app
          to your user account on their sites/applications, you need to log out
          of your user accounts before accessing our mobile application.
        </p>
        <br></br>
        <p>
          <strong>Countries Where Your Data is Stored and/or Processed</strong>
        </p>
        <br></br>
        <p>TR - Turkey</p>
        <br></br>
        <p>
          <strong>Contact</strong>
        </p>
        <br></br>
        <p>
          If you have any questions or suggestions about the Privacy Policy, do
          not hesitate to contact us at info@panoyabak.com.
        </p>
      </>
    </>
  ),
};

const Privacy = () => {
  const {
    i18n: { language },
  } = useTranslation();
  return (
    <MainLayout>
      <Box my="10">
        {selectByLanguage(data, language)} <br></br>
      </Box>
      <Footer />
    </MainLayout>
  );
};
export default Privacy;
