import React, { useState } from 'react';
import { Button, Text } from '@chakra-ui/react';
import {
  MainLayout,
  MainSection,
  SecondarySection,
  SelectImage,
  SectionsWrapper,
} from '../../components';
import useMainStore from '../../hooks/useMainStore';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../constants/routes';
import { useTranslation } from 'react-i18next';

const PickImage = () => {
  const [image, setImage] = useState({ url: null, image: null });
  const { updateSharePost } = useMainStore();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onContinue = () => {
    updateSharePost({ image });
    navigate(routes.shareEditPost.path);
  };

  return (
    <MainLayout>
      <SectionsWrapper>
        <MainSection>
          <SelectImage
            image={image}
            onChange={setImage}
            width={600}
            height={300}
          />
        </MainSection>
        <SecondarySection p="3" pt="5">
          <Text fontWeight={'600'} fontSize={'2xl'} mb="3">
            {t('upload_picture')}
          </Text>
          {/*<Text fontWeight={'500'} fontSize={'md'} mb="3">*/}
          {/*  Lorem ipsum dolor sit amet, consectetur adipiscing elit.{' '}*/}
          {/*</Text>*/}
          <Button
            isFullWidth
            borderRadius={'full'}
            size={'lg'}
            backgroundColor={'primary'}
            color="white"
            my="4"
            onClick={onContinue}
            disabled={!image.image}
          >
            {t('approve_and_continue')}
          </Button>
        </SecondarySection>
      </SectionsWrapper>
    </MainLayout>
  );
};

export default PickImage;
