import React from 'react';
import { Box, Image, Text, Icon } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../constants/routes';
import { Location } from '../icons';
import { useTranslation } from 'react-i18next';

const PostImageCard = ({
  image,
  title,
  location,
  userId,
  isOnline,
  //avatar,
  slug,
  id,
  //showAvatar = true,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onDetail = () => navigate(routes.detail.getPath(slug));

  return (
    <Box
      borderRadius={'lg'}
      position={'relative'}
      alignSelf={'center'}
      cursor={'pointer'}
      onClick={onDetail}
      overflow={'hidden'}
      boxShadow={'xl'}
    >
      <Image
        src={image}
        alt={title}
        alignSelf={'center'}
        height="320px"
        justifyContent={'center'}
        borderRadius={{ base: 'sm', md: 'md', lg: 'lg' }}
        objectFit="cover"
        boxShadow="md"
      />
      {title ? (
        <Text m="1" fontSize={'15'} display={'flex'} fontWeight={'bold'}>
          {title.length > 28 ? (
            <>
              {title.slice(0, 28)} <Text> ...</Text>
            </>
          ) : (
            title
          )}
        </Text>
      ) : null}
      <Box display={'flex'} alignItems={'center'} m={'1'}>
        {location ? (
          <Box display={'flex'}>
            <Icon fontSize="xl" as={Location} color={'darkgrey'} />
            <Text fontSize={'13'} fontWeight={'bold'} color={'darkgrey'}>
              {location}
            </Text>
          </Box>
        ) : null}
        {isOnline ? (
          <Text fontSize={'13'} fontWeight={'bold'} color={'darkgrey'} ml="1">
            / {t('online')}
          </Text>
        ) : null}
      </Box>
    </Box>
  );
};

export default PostImageCard;
