import React from 'react';
import { Box, Button, Icon } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import MainHeader from '../headers/MainHeader';
import { MAIN_LAYOUT_MAX_WIDTH } from '../../constants';
import { Back } from '../icons';

const MainLayout = ({
  children,
  title = 'Panoyabak',
  withBackButton,
  onBack,
  ...props
}) => {
  const navigate = useNavigate();

  return (
    <Box minH={'100vh'} marginX="auto" backgroundColor={'white'}>
      <MainHeader />
      <Box
        as="main"
        maxWidth={MAIN_LAYOUT_MAX_WIDTH}
        marginX={'auto'}
        {...props}
      >
        {withBackButton && (
          <Button
            onClick={() => {
              if (onBack && typeof onBack === 'function') {
                onBack();
              } else {
                navigate(-1);
              }
            }}
            backgroundColor={'light'}
            variant={'ghost'}
            borderRadius={'full'}
            my="3"
            mx="1"
          >
            <Icon width={'unset'} as={Back} color="primary" />
          </Button>
        )}
        {children}
      </Box>
    </Box>
  );
};

export default MainLayout;
