import API from '../api';
import endpoints from '../api/endpoints';

const login = async ({ username, password }) => {
  const { data } = await API.post(endpoints.login, { username, password });
  if (data.accessToken) return [data, null];
  return [null, data];
};

const register = async ({ username, email, name, password, phone }) => {
  const { data } = await API.post(endpoints.register, {
    username,
    password,
    email,
    name,
    phone,
  });
  return [data, null];
};

const resetPassword = async email => {
  const { data } = await API.post(endpoints.resetPassword, { email });
  return [data, null];
};

const resetNewPassword = async ({ key, password }) => {
  const { data } = await API.post(endpoints.newPassword, { key, password });
  return [data, null];
};

const verifyEmail = async key => {
  const { data } = await API.post(endpoints.emailVerification, { key });
  return [data, null];
};

export const getToken = () => localStorage.getItem('token');

export const setToken = token => localStorage.setItem('token', token);

export const setAuthorizationToken = token => {
  API.defaults.headers.common['afitapp-secret-key'] = `${token}`;
};

const AuthService = {
  login,
  register,
  resetPassword,
  getToken,
  setToken,
  setAuthorizationToken,
  resetNewPassword,
  verifyEmail,
};

export default AuthService;
