import React from 'react';
import { Box, Spinner as CSpinner } from '@chakra-ui/react';

const Spinner = ({ isVisible = true }) => {
  if (!isVisible) return false;
  return (
    <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
      <CSpinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray"
        color="primary"
        size="xl"
        alignSelf={'center'}
        m="10"
      />
    </Box>
  );
};

export default Spinner;
