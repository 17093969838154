import React from 'react';
import { Box, Text, Image, Icon, SimpleGrid } from '@chakra-ui/react';
import WhatIsImage from '../../assets/images/what-is.png';
import {
  BadgeCheck,
  Upload,
  Users,
  Document,
  Ticket,
} from '../../components/icons';
import { selectByLanguage } from '../../language';
import { useTranslation } from 'react-i18next';

const WhatIs = () => {
  const {
    i18n: { language },
  } = useTranslation();
  const info = [
    {
      text1: 'Etkinlik düzenleyen organizasyonlar ile katılımcıları buluşturan',
      text1_en: 'It is a mobile application that is an',
      text2: 'Etkinlik Ekosistemi',
      text2_en: 'Event Ecosystem',
      text3: 'niteliğindeki mobil uygulamadır',
      text3_en:
        'that brings together event organizing organizations and participants.',
    },
    {
      text1: 'Etkinlik organizasyonları için',
      text1_en: 'It is',
      text2: 'Event Management',
      text2_en: 'Event Management',
      text3: 'niteliği taşımaktadır',
      text3_en: 'for event organizations.',
    },
    {
      text1: 'Katılımcıların ilgi duydukları etkinlikler ulaşabileceği',
      text1_en: 'It is a',
      text2: 'Dijital Pano',
      text2_en: 'Digital Board',
      text3: '’dur',
      text3_en:
        'where participants can access the events they are interested in.',
    },
  ];

  const badge_info = [
    {
      icon: Ticket,
      text: 'Tüm Etkinliklerden Haberdar Ol',
      text_en: 'Be Informed of All Events',
    },
    {
      icon: Upload,
      text: 'Yükle Yönet',
      text_en: 'Upload Manage',
    },
    {
      icon: BadgeCheck,
      text: 'Alanını Takip Et',
      text_en: 'Follow Your Field',
    },
    {
      icon: Users,
      text: 'Sosyalleş',
      text_en: 'Socialize',
    },
    {
      icon: Document,
      text: 'Sertifikalarını Çoğalt Kendini Her Alanda Geliştir',
      text_en: 'Multiply Your Certificates Develop Yourself in Every Field',
    },
  ];

  return (
    <Box marginY={15}>
      <Box
        display="flex"
        justifyContent={'center'}
        p={'5'}
        flexDirection={{ base: 'column', md: 'row', lg: 'row' }}
      >
        <Image maxW={'400px'} src={WhatIsImage} objectFit="cover" />
        <Box
          w={{ md: '50%' }}
          fontSize={20}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          color="#000059"
        >
          {info.map((text, index) => {
            return (
              <Text key={index} p={'5'}>
                {selectByLanguage(
                  {
                    tr: text.text1,
                    en: text.text1_en,
                  },
                  language
                )}{' '}
                <strong>
                  {' '}
                  {selectByLanguage(
                    {
                      tr: text.text2,
                      en: text.text2_en,
                    },
                    language
                  )}
                </strong>{' '}
                {selectByLanguage(
                  {
                    tr: text.text3,
                    en: text.text4_en,
                  },
                  language
                )}
              </Text>
            );
          })}
        </Box>
      </Box>
      <SimpleGrid
        marginX={{ base: 5 }}
        display={{ base: 'column', md: 'flex' }}
        justifyContent={'center'}
        gap={5}
        mt={5}
      >
        {badge_info.map((text, index) => {
          return (
            <Box
              key={index}
              marginY={2}
              display={'flex'}
              boxShadow="lg"
              alignItems={'center'}
              p={{ base: '2', md: '3' }}
              borderWidth={1}
              borderColor={'primary'}
              borderRadius={10}
            >
              <Icon
                mr={'2'}
                fontSize={{ base: '2xl', md: '2xl' }}
                as={text.icon}
                color="primary"
              />
              <Text
                fontSize={{ base: 15, md: 10, lg: 15 }}
                color="#000059"
                fontWeight={'bold'}
              >
                {selectByLanguage(
                  {
                    tr: text.text,
                    en: text.text_en,
                  },
                  language
                )}
              </Text>
            </Box>
          );
        })}
      </SimpleGrid>
    </Box>
  );
};
export default WhatIs;
