import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { routes } from '../constants/routes';
import { PostService } from '../services';
import useMainStore from './useMainStore';

const usePost = (
  id,
  {
    navigateWhenNotFound = false,
    notFoundRoute = routes.home.path,
    navigateWhenNotMyPost = false,
    notMyPostRoute = routes.home.path,
  } = {
    navigateWhenNotFound: false,
    notFoundRoute: routes.home.path,
    navigateWhenNotMyPost: false,
    notMyPostRoute: routes.home.path,
  }
) => {
  const {
    data: { user },
  } = useMainStore();
  const navigate = useNavigate();
  const [post, setPost] = useState(null);
  const [isMyPost, setIsMyPost] = useState(false);

  const { data, isLoading, isFetched } = useQuery(['postDetailsData', id], () =>
    PostService.getPostDetails(id)
  );

  useEffect(() => {
    if (isFetched && data) {
      if (data?.[0]) {
        setPost(data[0]);
        const isMine = data?.[0]?.userId === user?.id;
        setIsMyPost(isMine);
        if (!isMine && navigateWhenNotMyPost) {
          navigate(notMyPostRoute);
        }
      } else {
        if (navigateWhenNotFound) {
          navigate(notFoundRoute);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isFetched, user?.id]);

  return {
    post,
    isLoading,
    isMyPost,
  };
};

export default usePost;
