/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {
  Avatar,
  Box,
  Text,
  MenuButton,
  MenuList,
  MenuItem,
  Menu,
  Icon,
  Image,
  IconButton,
  Link,
} from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../constants/routes';
import { CommentOutline, User, Menu as MenuIcon } from '../icons';
import useMainStore from '../../hooks/useMainStore';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';
import { Link as RLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const UserPostDetailedCard = ({
  image,
  title = '',
  userId,
  postId,
  date,
  createdAt,
  description,
  user,
  event,
  id,
  likeCount,
  post,
  commentCount,
}) => {
  const { t } = useTranslation();
  const { blockUser, reportUser, onShare } = useMainStore();
  const navigate = useNavigate();

  const day = moment(createdAt).format('DD');
  const monthYear = moment(createdAt).format('MMMM YYYY');

  const onDetail = () =>
    navigate(routes.userPostDetail.getPath({ postId, id }));

  const onPostDetail = () => {
    navigate(routes.detail.getPath(post.slug));
  };

  const onUser = () => navigate(routes.user.getPath(user.username));

  const isMobile = useMediaQuery({ query: '(max-width: 786px)' });

  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      justifyContent={'center'}
      alignItems={'flex-start'}
      my={'3'}
      fontSize={'md'}
    >
      <Box
        marginX={'auto'}
        borderRadius={'2xl'}
        backgroundColor={'light'}
        px={'5'}
        py="4"
        flex="1"
      >
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Box display={'flex'} alignItems={'center'}>
            <Box cursor={'pointer'} onClick={onUser}>
              <Avatar src={user.avatar} size={'sm'} />
            </Box>
            <Box mb="2">
              <Box cursor={'pointer'} onClick={onUser}>
                <Text color="default" ml={'2'} fontWeight={'600'}>
                  {user.username}
                </Text>
              </Box>
              <Text
                fontWeight={'300'}
                ml={'2'}
                fontSize={'sm'}
                lineHeight={'none'}
              >
                {t('shared_at_1')}
                <Box
                  cursor={'pointer'}
                  onClick={onPostDetail}
                  textDecorationColor={'cyan.300'}
                  display="inline"
                >
                  {event}
                </Box>
                {t('shared_at_2')}
              </Text>
            </Box>
          </Box>
          <Menu>
            <MenuButton
              borderWidth={0}
              as={IconButton}
              aria-label="Options"
              icon={<Icon as={MenuIcon} />}
              variant="outline"
            />
            <MenuList borderWidth={0}>
              <MenuItem
                onClick={() => {
                  blockUser(userId);
                  navigate(routes.home.path);
                }}
              >
                {t('block_user')}
              </MenuItem>
              <MenuItem onClick={() => reportUser(userId)}>
                {t('report')}
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          //justifyContent={'center'}
          cursor={'pointer'}
          onClick={onDetail}
        >
          <Box display={'flex'} justifyContent={'center'}>
            <Image
              //maxH={'50vh'}
              maxW={isMobile ? '45vh' : '40vh'}
              objectFit="cover"
              //alignSelf={'flex-start'}
              alignSelf={'center'}
              borderWidth={2}
              borderColor={'primary'}
              src={image}
              borderRadius={'2xl'}
              alt={title}
            />
          </Box>
          <Box
            justifyContent={'space-between'}
            display={'flex'}
            flexDirection={'column'}
          >
            <Box m="2">
              <Text color="dark" m="1" fontWeight={'bold'}>
                {title}
              </Text>
              <Text color="default" m="1" fontWeight={'500'}>
                {description?.length > 150 ? (
                  <>
                    {description.slice(0, isMobile ? 50 : 150)}
                    <Text as="strong" fontWeight={'bold'}>
                      ...{t('see_more')}.
                    </Text>
                  </>
                ) : (
                  description
                )}
              </Text>
            </Box>
            <Box
              display={'flex'}
              justifyContent={'flex-start'}
              fontSize={'lg'}
              m="1"
            >
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                mr={'3'}
              >
                <Icon as={User} color="default" />
                <Text ml={'2'} fontWeight={'600'} color="default">
                  {likeCount}
                </Text>
              </Box>
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                mr={'3'}
              >
                <Icon as={CommentOutline} color="default" />
                <Text ml={'2'} fontWeight={'600'} color="default">
                  {commentCount}
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default UserPostDetailedCard;
