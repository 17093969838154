const endpoints = {
  login: '/signin',
  register: '/signup',
  updateToken: '/user/token',
  getPosts: '/post/Home',
  getDiscoverPosts: '/v2/posts',
  updateUser: '/users/me',
  changePassword: '/change_password',
  createPost: '/post/share',
  getUserFeed: userId => `/user/${userId}/profileUserFeed`,
  getModFeed: userId => `/user/${userId}/profileModFeed`,
  getPostDetails: postId => `/post/${postId}`,
  getPostDetailsBySlug: slug => `/v2/posts/slug/${slug}`,
  getParticipants: postId => `/post/${postId}/participants`,
  getComments: postId => `/v2/posts/${postId}/comments`,
  sendComment: postId => `/post/${postId}/comment`,
  getUser: userId => `/user/${userId}`,
  getMe: '/user/me',
  checkFollow: userId => `/user/${userId}/checkFollow`,
  follow: userId => `/user/${userId}/follow`,
  unfollow: userId => `/user/${userId}/unfollow`,
  getFollowers: userId => `/user/${userId}/followers`,
  getFollowList: userId => `/user/${userId}/follow`,
  blockUser: '/user/block',
  join: postId => `/post/${postId}/like`,
  unjoin: postId => `/post/${postId}/unlike`,
  updatePost: postId => `/post/${postId}/update`,
  resetPassword: '/reset_password',
  newPassword: '/reset_password/new_password',
  emailVerification: '/email-activate',
  changeAvatar: '/change_avatar',
  getUsers: '/users',
  sendMessage: '/user/message',
  deletePost: postId => `/post/delete/${postId}`,
  ratePost: postId => `/post/${postId}/rate`,
  canRatePost: postId => `/post/${postId}/canRate`,
  canSendMessage: '/user/canSendMessage',
  registerClub: modId => `/user/${modId}/registerClub`,
  getClubApplications: '/user/club',
  updateClubApplication: userId => `/user/club/${userId}`,
  createStory: '/user/me/story',
  getStoriesOfUser: userId => `/user/${userId}/story`,
  deleteStory: id => `/user/me/story/${id}`,
  getBlockedUsers: '/user/me/blocked-users',
  deleteBlock: blockId => `/user/block/${blockId}`,
  getUserProfile: userId => `/user/${userId}/profile`,
  getUserProfileByUsername: username =>
    `/v2/users/username/${username}/profile`,
  withoutSigninGetUserProfile: username =>
    `/v2/users/username/${username}/profile/web`,
  getRecomendedUsers: '/users/me/recommended',
  search: query => `/search/${query}`,
  getPostStats: postId => `/post/${postId}/stats`,
  sendMailToClub: '/user/club/email',
  sendEmailToParticipants: postId => `/post/${postId}/email`,
  getCertificates: '/certificates',
  generateCertificates: postId => `/post/${postId}/generate-certificates`,
  getCertificatesOfUser: '/user/me/certificates',
  searchUser: query => `/users/search?query=${query}`,
  addUserToClub: '/user/club',
  deleteUser: '/users/me',
  createUserPost: userPostId => `/user-post/${userPostId}/share`,
  getUserPosts: '/user-posts',
  getProfileUserPost: userId => `/user/user-post/${userId}`,
  likeUserPost: userPostId => `/user-post/${userPostId}/like`,
  unlikeUserPost: userPostId => `/user-post/${userPostId}/unlike`,
  checkUserLike: userPostId => `/user-post/${userPostId}/checkLike`,
  checkPostMe: userPostId => `/user-post/${userPostId}/checkMe`,
  getUserPostLikes: userPostId => `/user-post/${userPostId}/likes`,
  createUserComment: userPostId => `/user-post/${userPostId}/comment`,
  getUserPostComments: userPostId => `/user-post/${userPostId}/getComment`,
  deleteUserComment: commentId => `/comment/delete/${commentId}`,
  findUserPostById: userPostId => `/user-post/${userPostId}`,
  deleteUserPost: userPostId => `/user-post/delete/${userPostId}`,
  updateUserPost: userPostId => `/user-post/${userPostId}/update`,
  getEventUserPost: postId => `/user-post/event/${postId}`,
  checkQuota: postId => `/posts/${postId}/check-quota`,
  getHomePosts: '/v2/posts/home',
  getHomeUserPosts: '/v2/user-posts/home',
  queryFollowers: userId => `/v2/users/${userId}/followers`,
  queryFollowees: userId => `/v2/users/${userId}/followees`,
  resultPayment: '/payments/result',
  initiatePayment: '/payments/initiate',
  getApiKey: '/v2/users/me/api-key',
  getExternalEvents: '/v2/posts/external',
  fetchUserPostLikes: id => `/v2/user-posts/${id}/likes`,
};

export default endpoints;
