import { Box, Text } from '@chakra-ui/react';
import React from 'react';
import { Footer, MainLayout } from '../../components';
import { selectByLanguage } from '../../language';
import { useTranslation } from 'react-i18next';

const data = {
  tr: (
    <Box my="10">
      <Text fontWeight={'bold'} fontSize="3xl" textAlign={'center'}>
        Çerez Politikası
      </Text>
      <br></br>

      <p>
        Panoyabak olarak internet sitelerimizden en verimlişekilde
        faydalanabilmeniz ve kullanıcı deneyiminizi geliştirebilmek için
        çerezlerkullanmaktayız. Çerez kullanılmasını tercih etmezseniz
        tarayıcınızın ayarlarından veyaaşağıdaki detaylı çerez kaldırım
        açıklamasından çerezleri silebilir ya da engelleyebilirsiniz.Ancak bunun
        internet sitelerimizin kullanımınızı etkileyebileceğini hatırlatmak
        isteriz. Tarayıcınızdan çerez ayarlarınızı değiştirmediğiniz sürece
        internet sitelerimizde çerezkullanımını kabul ettiğinizi varsayacağız.
        Toplanan verilerle ilgili düzenlemelere internetsitelerimizde yer alan
        Aydınlatma Metninden ulaşabilirsiniz.
      </p>
      <br></br>

      <p>
        Çerezlerimiz, internet sitelerimizi kullanmanız sırasında size
        kişiselleştirilmiş bir deneyimsunmak, hizmetlerimizi geliştirmek ve
        deneyiminizi iyileştirmek için kullanılır.
      </p>
      <br></br>

      <p>
        Çerezler kullanım amaç ve şekillerine göre farklı başlıklarda
        değerlendirilebilirler. Bubaşlıklardan bazıları aşağıda açıklanmıştır;
      </p>
      <br></br>

      <p>
        <strong>Oturum çerezleri:</strong> Geçici çerezlerdir, tarayıcınızı
        kapattığınızda silinirler, yani kalıcıdeğillerdir. Sitelerimizin ve
        sizin, ziyaretinizde güvenliğini, sürekliliğini sağlamak gibiamaçlarla
        kullanılırlar.
      </p>
      <br></br>

      <p>
        <strong>Kalıcı çerezler:</strong> Sizin bir hareketinizle ya da çerezin
        içerisindeki süre aralığı sona erene kadartarayıcınızın alt
        klasörlerinde tutulurlar. Bu çerezler sizin bilgilerinizi ve
        seçimlerinizi birsonraki ziyaretinizde internet sitemizin hatırlamasına
        yardımcı olurlar.
      </p>
      <br></br>

      <p>
        <strong>Birinci ve üçüncü kişi çerezler:</strong> Birinci kişi çerezler
        sitemiz tarafından kullanılançerezlerdir. Üçüncü kişi çerezler ise
        sitemiz haricinde bilgisayarınıza kurulan çerezlerdir.
      </p>
      <br></br>

      <p>
        <strong>Zorunlu çerezler:</strong> www.panoyabak.com internet
        sitelerinin düzgün şekilde çalışabilmesi için,özelliklerinin düzgün
        şekilde sunulabilmesi amacıyla kurulan anonim nitelikte çerezlerdir.
      </p>
      <br></br>

      <p>
        <strong>İşlevsel ve analitik çerezler:</strong> Tercihlerinizin
        hatırlanması ve gezinme deneyiminizigeliştirmemize yarayan işlevsel
        çerezler ve hangi sayfalarımızın daha fazla ilgi çektiğini,hangi
        kaynakların daha çok görüntülendiğini görmemize yarayan, sitelerimizdeki
        trafiğigörerek bu trafiğe uygun hizmet sağlamamızı sağlayan analitik
        çerezler kullanılmaktadır. Bunitelikte çerezler de anonimdir.
      </p>
      <br></br>

      <p>
        Panoyabak’ın, internet sitesi ve/veya sitelerinde veyamobil web de
        oturum çerezleri, zorunlu çerezler, kalıcı çerezler kullanmaktadır.
      </p>
      <br></br>

      <p>
        Kullanıcı internet tarayıcılarının "yardım" dosyasında verilen
        talimatları izleyerek veya" www.allaboutcookies.org " veya
        "www.youronlinechoices.eu " adresini ziyaret ederek kalıcıçerezleri
        kaldırabilir ve hem oturum çerezlerini hem de kalıcı çerezleri
        reddedebilir. Kullanıcıkalıcı çerezleri veya oturum çerezlerini
        reddetmesi halinde, söz konusu web sitesini, veyamobil webi kullanmaya
        devam edebilir, fakat söz konusu mecraların tüm
        işlevlerineerişemeyebilir veya erişimi sınırlı olabilir.
      </p>
      <br></br>

      <h2 className="App-title">
        <strong>Çerezler nasıl silinir?</strong>
      </h2>
      <p>
        Çerezlerin çoğunun silinmesi kolaydır. Sadece tarayıcınızı seçin ve
        talimatları izleyin.
      </p>
      <br></br>
      <p>
        <strong>Chrome</strong>
      </p>
      <br></br>
      <p>1. Klavyenizde Ctrl + Shift + Delete tuşlarına basın.</p>
      <br></br>
      <p>2. Bir zaman aralığı seçin.</p>
      <br></br>
      <p>3. 'Çerezler ve diğer site verileri' onay kutusunu seçin.</p>
      <br></br>
      <p>4. "Verileri temizle" düğmesini tıklayın.</p>
      <br></br>

      <p>
        <strong>Fire fox</strong>
      </p>
      <br></br>
      <p>1. Klavyenizde Ctrl + Shift + Delete tuşlarına basın.</p>
      <br></br>
      <p>2. Temizlenecek bir zaman aralığı seçin.</p>
      <br></br>
      <p>3. 'Çerezler' onay kutusunu seçin.</p>
      <br></br>
      <p>4. "Şimdi temizle" düğmesini tıklayın.</p>
      <br></br>

      <p>
        <strong>Safari</strong>
      </p>
      <br></br>
      <p>1. Safari menüsünden Tercihler' i seçin.</p>
      <br></br>
      <p>2. Gizlilik sekmesine gidin.</p>
      <br></br>
      <p>3. 'Web sitesi verilerini yönet' i tıklayın.</p>
      <br></br>
      <p>4. 'Tümünü Kaldır' ı tıklayın.</p>
      <br></br>

      <p>
        <strong>Edge</strong>
      </p>
      <br></br>
      <p>1. Klavyenizde Ctrl + Shift + Delete tuşlarına basın.</p>
      <br></br>
      <p>
        2. 'Çerezler ve kaydedilmiş web sitesi verileri' onay kutusunu seçin.
      </p>
      <br></br>
      <p>3. "Temizle" düğmesini tıklayın.</p>
      <br></br>

      <p>
        <strong>Internet Explorer</strong>
      </p>
      <br></br>
      <p>1. Klavyenizde Ctrl + Shift + Delete tuşlarına basın.</p>
      <br></br>
      <p>
        2. 'Çerezler ve kaydedilmiş web sitesi verileri' onay kutusunu seçin.
      </p>
      <br></br>
      <p>3. "Sil" düğmesini tıklayın.</p>
      <br></br>
    </Box>
  ),
  en: (
    <Box my="10">
      <Text fontWeight={'bold'} fontSize="3xl" textAlign={'center'}>
        Cookie Policy
      </Text>
      <br></br>

      <p>
        As Panoyabak, we use cookies to enable you to make the most efficient
        use of our websites and to improve your user experience. If you prefer
        not to use cookies, you can delete or block cookies from your browser
        settings or the detailed cookie removal description below. However, we
        would like to remind you that this may affect your use of our websites.
        Unless you change your cookie settings in your browser, we will assume
        that you accept the use of cookies on our websites. You can access the
        regulations related to the collected data from the Illumination Text on
        our websites.
      </p>
      <br></br>

      <p>
        Our cookies are used to offer you a personalized experience while using
        our websites, to improve our services, and to enhance your experience.
      </p>
      <br></br>

      <p>
        Cookies can be evaluated under different headings according to their
        purpose and manner of use. Some of these headings are explained below;
      </p>
      <br></br>

      <p>
        <strong>Session cookies:</strong> These are temporary cookies that are
        deleted when you close your browser, hence they are not permanent. They
        are used for purposes such as ensuring the security and continuity of
        our sites and your visit.
      </p>
      <br></br>

      <p>
        <strong>Persistent cookies:</strong> They are stored in the subfolders
        of your browser until you perform an action or the time interval in the
        cookie expires. These cookies help our website remember your information
        and choices on your next visit.
      </p>
      <br></br>

      <p>
        <strong>First and third party cookies:</strong> First-party cookies are
        the cookies used by our site. Third-party cookies are the cookies
        installed on your computer outside of our site.
      </p>
      <br></br>

      <p>
        <strong>Mandatory cookies:</strong> These are anonymous cookies that are
        necessary for the www.panoyabak.com internet sites to function properly
        and for their features to be presented correctly.
      </p>
      <br></br>

      <p>
        <strong>Functional and analytical cookies:</strong> Functional cookies
        that help us remember your preferences and improve your navigation
        experience, and analytical cookies that allow us to see which pages
        attract more interest, which resources are viewed more, and to provide
        services appropriate to this traffic on our sites, are used. These types
        of cookies are also anonymous.
      </p>
      <br></br>

      <p>
        Panoyabak uses session cookies, mandatory cookies, and persistent
        cookies on its website and/or mobile web.
      </p>
      <br></br>

      <p>
        Users can remove persistent cookies and reject both session cookies and
        persistent cookies by following the instructions given in the "help"
        file of their internet browsers, or by visiting
        "www.allaboutcookies.org" or "www.youronlinechoices.eu". If the user
        rejects persistent cookies or session cookies, they may continue to use
        the website or mobile web, but may not be able to access or have limited
        access to all functions of these platforms.
      </p>
      <br></br>

      <h2 className="App-title">
        <strong>How are cookies deleted?</strong>
      </h2>
      <p>
        Deleting most cookies is easy. Simply select your browser and follow the
        instructions.
      </p>
      <br></br>
      <p>
        <strong>Chrome</strong>
      </p>
      <br></br>
      <p>1. Press Ctrl + Shift + Delete on your keyboard.</p>
      <br></br>
      <p>2. Select a time range.</p>
      <br></br>
      <p>3. Check the 'Cookies and other site data' box.</p>
      <br></br>
      <p>4. Click the "Clear data" button.</p>
      <br></br>

      <p>
        <strong>Firefox</strong>
      </p>
      <br></br>
      <p>1. Press Ctrl + Shift + Delete on your keyboard.</p>
      <br></br>
      <p>2. Select a time range to clear.</p>
      <br></br>
      <p>3. Check the 'Cookies' box.</p>
      <br></br>
      <p>4. Click the "Clear now" button.</p>
      <br></br>

      <p>
        <strong>Safari</strong>
      </p>
      <br></br>
      <p>1. Select Preferences from the Safari menu.</p>
      <br></br>
      <p>2. Go to the Privacy tab.</p>
      <br></br>
      <p>3. Click 'Manage Website Data'.</p>
      <br></br>
      <p>4. Click 'Remove All'.</p>
      <br></br>

      <p>
        <strong>Edge</strong>
      </p>
      <br></br>
      <p>1. Press Ctrl + Shift + Delete on your keyboard.</p>
      <br></br>
      <p>2. Check the 'Cookies and saved website data' box.</p>
      <br></br>
      <p>3. Click the "Clear" button.</p>
      <br></br>

      <p>
        <strong>Internet Explorer</strong>
      </p>
      <br></br>
      <p>1. Press Ctrl + Shift + Delete on your keyboard.</p>
      <br></br>
      <p>2. Check the 'Cookies and saved website data' box.</p>
      <br></br>
      <p>3. Click the "Delete" button.</p>
      <br></br>
    </Box>
  ),
};

const Cookie = () => {
  const {
    i18n: { language },
  } = useTranslation();
  return (
    <MainLayout>
      {selectByLanguage(data, language)}
      <Footer />
    </MainLayout>
  );
};

export default Cookie;
