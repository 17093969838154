import React, { useMemo } from 'react';
import { Avatar, Box, Spinner, Text } from '@chakra-ui/react';
import { useQuery } from 'react-query';
import { UserService } from '../../services';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../constants/routes';
import { useTranslation } from 'react-i18next';

const RecommendedUsers = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data, isLoading } = useQuery(
    'getRecommendedUsers',
    UserService.getRecomendedUsers
  );

  const users = useMemo(() => {
    return data ? data[0] : [];
  }, [data]);

  const onUser = username => {
    username && navigate(routes.user.getPath(username));
  };

  return (
    <Box backgroundColor={'light'} p={'5'} m={'3'} borderRadius={'lg'}>
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Text fontSize={'md'} color="dark" fontWeight={'600'}>
          {t('recommended_accounts')}
        </Text>
        {/* <Text color="primary">Tümünü Gör</Text> */}
      </Box>
      {isLoading && (
        <Box display={'flex'} justifyContent={'center'} mt="3">
          <Spinner />
        </Box>
      )}
      <Box flex="1">
        {users?.map((item, index) => {
          return (
            <Box
              key={index}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'flex-start'}
              py={'2'}
              borderBottomWidth={index === users.length - 1 ? 0 : 1}
              borderColor={'#C4C4C4'}
              cursor={'pointer'}
              onClick={() => onUser(item?.username)}
            >
              <Avatar
                size="sm"
                src={item?.avatar ?? ''}
                name={item?.name || item?.username}
              />
              <Text ml={'2'} fontWeight={'500'} color="default">
                {item?.name?.trim?.() || item?.username}
              </Text>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default RecommendedUsers;
