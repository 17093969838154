import { roles } from '.';
import Explore from '../pages/Explore';
import PostDetail from '../pages/PostDetail';
import Profile from '../pages/Profile';
import EditPostDetails from '../pages/share/EditPostDetails';
import PickImage from '../pages/share/PickImage';
import User from '../pages/User';
import Home from '../pages/Home';
import Login from '../pages/Login';
import ForgotPassword from '../pages/ForgotPassword';
import Register from '../pages/Register';
import ChangePassword from '../pages/ChangePassword';
import EmailVerification from '../pages/EmailVerification';
import Privacy from '../pages/policies/Privacy';
import Terms from '../pages/policies/Terms';
import Cookie from '../pages/policies/Cookie';
import Search from '../pages/Search';
import Messages from '../pages/messages/Messages';
import Chat from '../pages/messages/Chat';
import ClubDashboard from '../pages/club/ClubDashboard';
import ClubMembers from '../pages/club/ClubMembers';
import ClubSendEmail from '../pages/club/ClubSendEmail';
import ClubNewMember from '../pages/club/ClubNewMember';
import PostDashboard from '../pages/post/PostDashboard';
import PostSendEmail from '../pages/post/PostSendEmail';
import PostStatistics from '../pages/post/PostStatistics';
import CreateCertificate from '../pages/post/CreateCertificate';
import AboutUs from '../pages/AboutUs';
import HowToClupUse from '../pages/information/HowToClupUse';
import HowToUserUse from '../pages/information/HowToUserUse';
import UserPostDetail from '../pages/user-post/UserPostDetail';
import WhatIsPage from '../pages/information/WhatIsPage';
import Delivery from '../pages/policies/Delivery';
import Contact from '../pages/information/Contact';
import API from '../pages/API';

export const routes = {
  home: {
    path: '/',
    name: 'Anasayfa',
    component: Home,
    isSecured: true,
    isExact: true,
  },
  login: {
    path: '/giris-yap',
    name: 'Giriş Yap',
    component: Login,
    isSecured: false,
    isExact: true,
  },
  register: {
    path: '/kayit-ol',
    name: 'Kayıt Ol',
    component: Register,
    isSecured: false,
    isExact: true,
  },
  forgotPassword: {
    path: '/sifremi-unuttum',
    name: 'Şifremi Unuttum',
    component: ForgotPassword,
    isSecured: false,
    isExact: true,
  },
  changePassword: {
    path: '/sifremi-yenile',
    name: 'Şifremi Yenile',
    component: ChangePassword,
    isSecured: false,
    isExact: false,
  },
  emailVerification: {
    path: '/account_verification',
    name: 'Email Doğrulama',
    component: EmailVerification,
    isSecured: false,
    isExact: false,
  },
  explore: {
    path: '/kesfet',
    name: 'Keşfet',
    component: Explore,
    isSecured: false,
    isExact: true,
  },
  profile: {
    path: '/profil',
    name: 'Profil',
    component: Profile,
    isSecured: true,
    isExact: true,
  },
  user: {
    path: '/kullanici/:username',
    getPath: username => `/kullanici/${username}`,
    name: 'Kullanıcı Profili',
    component: User,
    isSecured: false,
    isExact: true,
  },

  share: {
    path: '/paylas/',
    name: 'Etkinlik Paylaş',
    component: PickImage,
    isSecured: true,
    isExact: true,
    roles: [roles.mod],
  },
  shareEditPost: {
    path: '/paylas/detaylar',
    name: 'Etkinlik Detaylarını Düzenle',
    component: EditPostDetails,
    isSecured: true,
    isExact: true,
    roles: [roles.mod],
  },
  detail: {
    path: '/etkinlikler/:slug',
    getPath: slug => `/etkinlikler/${slug}`,
    name: 'Etkinlik Detay',
    component: PostDetail,
    isSecured: false,
    isExact: true,
  },
  userPostDetail: {
    path: `/etkinlik/:postId/user-post/:id`,
    getPath: ({ postId, id }) => `/etkinlik/${postId}/user-post/${id}`,
    name: 'User-Post Detay',
    component: UserPostDetail,
    isSecured: false,
    isExact: true,
  },
  privacyPolicy: {
    path: '/privacy-policy',
    name: 'Gizlilik Politikası',
    component: Privacy,
    isSecured: false,
    isExact: true,
  },
  terms: {
    path: '/terms-conditions',
    name: 'Üyelik Aydınlatma Metni',
    component: Terms,
    isSecured: false,
    isExact: true,
  },
  cookie: {
    path: '/cookie-policy',
    name: 'Çerez Politikası',
    component: Cookie,
    isSecured: false,
    isExact: true,
  },
  deliveryAndReturn: {
    path: '/delivery-and-return',
    name: 'Teslimat ve Dönüş',
    component: Delivery,
    isSecured: false,
    isExact: true,
  },
  contact: {
    path: '/contact',
    name: 'İletişim',
    component: Contact,
    isSecured: false,
    isExact: true,
  },
  search: {
    path: '/arama',
    name: 'Arama',
    getPath: q => `/arama?q=${q}`,
    component: Search,
    isExact: true,
  },
  messages: {
    path: '/mesajlar',
    name: 'Mesajlar',
    component: Messages,
    isExact: true,
    isSecured: true,
  },
  chat: {
    path: `/mesajlar/:userId/:roomId`,
    getPath: ({ userId, roomId }) => `/mesajlar/${userId}/${roomId}`,
    name: 'Mesajlaşma',
    component: Chat,
    isExact: true,
    isSecured: true,
  },
  clubDashboard: {
    path: '/kulup/kontrol-paneli',
    name: 'Kulüp Kontrol Paneli',
    component: ClubDashboard,
    isExact: true,
    isSecured: true,
    roles: [roles.mod],
  },
  api: {
    path: '/api',
    name: 'API',
    component: API,
    isExact: true,
    isSecured: true,
    roles: [roles.mod],
  },
  clubMembers: {
    path: '/kulup/uyeler',
    name: 'Kulüp Üyeleri',
    component: ClubMembers,
    isExact: true,
    isSecured: true,
    roles: [roles.mod],
  },
  clubNewMember: {
    path: '/kulup/yeni-uye',
    name: 'Kulübe Yeni Üye Ekle',
    component: ClubNewMember,
    isExact: true,
    isSecured: true,
    roles: [roles.mod],
  },
  clubEmail: {
    path: '/kulup/email',
    name: 'Kulübe Email Gönder',
    component: ClubSendEmail,
    isExact: true,
    isSecured: true,
    roles: [roles.mod],
  },
  postDashboard: {
    path: '/etkinlikler/:id/kontrol-paneli',
    getPath: id => `/etkinlikler/${id}/kontrol-paneli`,
    component: PostDashboard,
    isExact: true,
    isSecured: true,
    roles: [roles.mod],
  },
  postCreateCertificate: {
    path: '/etkinlikler/:id/sertifika-olustur',
    getPath: id => `/etkinlikler/${id}/sertifika-olustur`,
    component: CreateCertificate,
    isExact: true,
    isSecured: true,
    roles: [roles.mod],
  },
  postSendEmail: {
    path: '/etkinlikler/:id/email-gonder',
    getPath: id => `/etkinlikler/${id}/email-gonder`,
    component: PostSendEmail,
    isExact: true,
    isSecured: true,
    roles: [roles.mod],
  },
  postStats: {
    path: '/etkinlikler/:id/istatistikler',
    getPath: id => `/etkinlikler/${id}/istatistikler`,
    component: PostStatistics,
    isExact: true,
    isSecured: true,
    roles: [roles.mod],
  },
  aboutUs: {
    path: '/hakkimizda',
    component: AboutUs,
    isExact: true,
    isSecured: false,
  },
  howClupUse: {
    path: '/how-to-clup-use',
    component: HowToClupUse,
    isExact: true,
    isSecured: false,
  },
  howUserUse: {
    path: '/how-to-users-use',
    component: HowToUserUse,
    isExact: true,
    isSecured: false,
  },
  whatIs: {
    path: '/panoyabak-nedir',
    component: WhatIsPage,
    isExact: true,
    isSecured: false,
  },
};

export const navigation = Object.values(routes);

export const AUTH_NAVBAR_LINKS = [
  {
    title: 'Hakkımızda',
    key: 'navbar_about_us',
    to: routes.aboutUs.path,
  },
  {
    title: 'Panoyabak Nedir',
    key: 'navbar_what_is',
    to: routes.whatIs.path,
  },
  {
    title: 'Topluluklar Nasıl Kullanır',
    key: 'navbar_club_use',
    to: routes.howClupUse.path,
  },
  {
    title: 'Kullanıcılar Nasıl Kullanır',
    key: 'navbar_user_use',
    to: routes.howUserUse.path,
  },
];
