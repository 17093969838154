/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Avatar, Box, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../constants/routes';
import useMainStore from '../../hooks/useMainStore';

const PostCommentItem = ({ avatar, text, userId, username }) => {
  const { isLoggedIn } = useMainStore();
  const navigate = useNavigate();

  const onUser = () => {
    isLoggedIn && navigate(routes.user.getPath(username));
  };

  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      my={'1'}
    >
      <Box pr={'2'} cursor={'pointer'} onClick={onUser}>
        <Avatar size={'sm'} src={avatar} name={username} />
      </Box>
      <Text pr={'2'} fontWeight={'600'} color="dark">
        {username}:{' '}
      </Text>
      <Box display={'flex'} flex="1">
        <Text>{text}</Text>
      </Box>
    </Box>
  );
};

export default PostCommentItem;
