/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
} from 'react';
import {
  Avatar,
  Box,
  Text,
  MenuButton,
  MenuList,
  MenuItem,
  Menu,
  Icon,
  Image,
  IconButton,
  Link,
  useDisclosure,
  Button,
  Divider,
  AvatarGroup,
} from '@chakra-ui/react';
import { useMutation, useQuery } from 'react-query';
import {
  PostCommentItem,
  TabLine,
  CommentInput,
  PostParticipantItem,
  RaffleModal,
  Spinner,
  UserPostBoard,
  AuthLinkButon,
  InfiniteScroll,
  PaymentModal,
} from '..';
import { getLocationFromCoordinates } from '../../helpers/location';
import { PostService, UserPostService } from '../../services';
import {
  CommentOutline,
  User,
  Calendar,
  Location,
  CreditCard,
  Link as LinkIcon,
  Menu as MenuIcon,
} from '../icons';
import useMainStore from '../../hooks/useMainStore';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../constants/routes';
import moment from 'moment';
import commentTmp from '../../assets/images/chat.png';
import useFetch from '../../hooks/useFetch';
import { useTranslation } from 'react-i18next';

const tabs = {
  comments: 'comments',
  details: 'details',
  participants: 'participants',
  qr: 'qr',
};

const PostDetails = ({ id }) => {
  const { t } = useTranslation();
  const {
    data: { user },
    isLoggedIn,
    onShare,
    blockUser,
    reportUser,
  } = useMainStore();
  const navigate = useNavigate();
  const raffleModal = useDisclosure();
  const [tab, setTab] = useState(tabs.details);
  const [location, setLocation] = useState({
    coordinates: { latitude: 0, longitude: 0 },
    display: '',
  });
  const [isJoined, setIsJoined] = useState(false);
  const [allParticipants, setParticipants] = useState([]);
  const [comments, setComments] = useState([]);
  const [comment, setComment] = useState('');
  const [pending, setPending] = useState(false);
  const [hasQuota, setHasQuota] = useState(true);
  const paymentModal = useDisclosure();

  const commentsEnd = useRef();

  const { data, isLoading } = useQuery(['postDetailsData', id], () =>
    PostService.getPostDetails(id)
  );

  const { data: userPost } = useQuery(
    ['userPost', id],
    () => isLoggedIn && UserPostService.getEventUserPosts(id)
  );

  const commentsQuery = useFetch({
    queryKey: 'comments',
    fetchFn: params => PostService.getComments(id, { ...params, limit: 30 }),
  });

  const { data: participantsData } = useQuery(
    ['postDetailParticipants', id],
    () => PostService.getParticipants(id)
  );

  const { mutateAsync: like, isLoading: isLiking } = useMutation(
    PostService.join
  );
  const { mutateAsync: unlike, isLoading: isUnliking } = useMutation(
    PostService.unjoin
  );
  const { mutateAsync: sendComment, isLoading: isSendingComment } = useMutation(
    PostService.sendComment
  );

  const participants = useMemo(() => {
    return allParticipants.filter(p => Number(p.status) === 1);
  }, [allParticipants]);

  const post = useMemo(() => {
    if (data) {
      if (data[0] === null) {
        navigate(routes.home.path);
      }
      return data[0];
    }
    return {};
  }, [data]);

  const userPosts = useMemo(() => {
    return userPost && userPost.length > 0 ? userPost[0] : [] || [];
  }, [userPost]);

  useEffect(() => {
    if (commentsQuery.data) setComments([...commentsQuery.data]);
  }, [commentsQuery.data]);

  const participantsArr = useMemo(() => {
    setParticipants(participantsData ? participantsData[0] : []);
  }, [participantsData]);

  const getLocation = async () => {
    try {
      const coordinates =
        typeof post.location === 'string'
          ? JSON.parse(post.location)
          : post.location;
      let location = '';
      if (coordinates?.address) {
        location = coordinates.address;
      } else {
        location = await getLocationFromCoordinates(coordinates);
      }
      setLocation({ coordinates, display: location });
    } catch (error) {
      console.log(error);
    }
  };

  const isMyPost = useMemo(() => {
    return post?.userId === user?.id;
  }, [post, user]);

  const isOutdated = useMemo(() => {
    if (!post || !post.date) return false;
    const now = Date.now();
    const date = new Date(post.date).getTime() + 15 * 60000;
    return date - now < 0;
  }, [post]);

  const checkQuota = async id => {
    const [result, error] = await PostService.checkQuota(id);
    setHasQuota(result);
  };

  const waitingApproval = useMemo(() => {
    return allParticipants.some(
      item => item?.userId === user?.id && Number(item.status) === 0
    );
  }, [allParticipants]);

  useEffect(() => {
    if (post?.location) getLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [post]);

  useEffect(() => {
    isLoggedIn && checkQuota(id);
  }, []);

  const joined = useMemo(() => {
    const result = participants.some(item => item?.userId === user?.id);
    setIsJoined(result);
    return result;
  }, [participants]);

  const join = async () => {
    if (post?.isPaid) {
      paymentModal.onOpen();
      return;
    }

    setPending(true);
    const [data] = await like(id);
    if (data.like) {
      setIsJoined(true);
      allParticipants.unshift({ ...data.like, user });
      setParticipants([...allParticipants]);
    }
    setPending(false);
  };

  const unjoin = async () => {
    const [data] = await unlike(id);
    setIsJoined(false);
    if (joined) {
      const newArr = allParticipants.filter(item => item.userId !== user.id);
      setParticipants([...newArr]);
    }
  };

  const onSendComment = async () => {
    comments.unshift({ text: comment, user });
    setComments([...comments]);
    commentsEnd.current?.scrollIntoView({ behavior: 'smooth' });
    const [result, error] = await sendComment({ postId: id, text: comment });
    setComment('');
  };

  const onUser = username => {
    const isMe = user?.username === username;
    if (isMe) return navigate(routes.profile.path);
    navigate(routes.user.getPath(username));
  };

  const onDashboard = () => navigate(routes.postDashboard.getPath(id));

  if (isLoading) return <Spinner />;

  return (
    <Box>
      <Box
        display={'flex'}
        my={'3'}
        fontSize={'md'}
        backgroundColor={'light'}
        flex="1"
        flexDirection={{ base: 'column', lg: 'row' }}
        height={{ base: 'unset', lg: '70vh' }}
      >
        <Box
          borderLeftRadius={'2xl'}
          borderBottomRightRadius={'2xl'}
          px={'5'}
          py="4"
          display={'flex'}
          flexDirection={'column'}
          flex="1"
        >
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Box
              display={'flex'}
              alignItems={'center'}
              cursor={'pointer'}
              onClick={() => onUser(post?.user.username)}
            >
              <Avatar
                src={post?.user?.avatar}
                name={post?.user?.username}
                size={'sm'}
              />
              <Text color="default" ml={'2'} fontWeight={'600'}>
                {post?.user?.username}
              </Text>
            </Box>
            {isLoggedIn && (
              <Menu>
                <MenuButton
                  borderWidth={0}
                  as={IconButton}
                  aria-label="Options"
                  icon={<Icon as={MenuIcon} />}
                  variant="outline"
                />
                <MenuList borderWidth={0}>
                  {isMyPost ? (
                    <MenuItem onClick={onDashboard}>
                      {t('event_dashboard')}
                    </MenuItem>
                  ) : null}
                  {isMyPost ? (
                    <MenuItem onClick={raffleModal.onOpen}>
                      {t('make_raffle')}
                    </MenuItem>
                  ) : null}
                  <MenuItem onClick={() => onShare(post)}>{t('')}</MenuItem>
                  {!isMyPost && (
                    <MenuItem
                      onClick={() => {
                        blockUser(post.userId);
                        navigate(routes.home.path);
                      }}
                    >
                      {t('block_user')}
                    </MenuItem>
                  )}
                  {!isMyPost && (
                    <MenuItem onClick={() => reportUser(post.userId)}>
                      {t('report')}
                    </MenuItem>
                  )}
                </MenuList>
              </Menu>
            )}
          </Box>
          <Box
            flex={'1'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            position={'relative'}
          >
            <Image
              maxH={'60vh'}
              borderWidth={2}
              borderColor={'primary'}
              src={post?.image}
              borderRadius={'2xl'}
              alt={post?.title}
            />
          </Box>
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'space-between'}
          p={'5'}
          flex={1}
        >
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-around'}
            mt={'5'}
            fontWeight={'600'}
            fontSize={'md'}
            color="default"
          >
            <Box
              px="2"
              cursor={'pointer'}
              mr="2"
              onClick={() => setTab(tabs.details)}
            >
              <Text>{t('details')}</Text>
              <TabLine isActive={tab === tabs.details} />
            </Box>
            <Box
              px="2"
              cursor={'pointer'}
              mr="2"
              onClick={() => setTab(tabs.comments)}
            >
              <Text>{t('comments')}</Text>
              <TabLine isActive={tab === tabs.comments} />
            </Box>
            <Box
              px="2"
              cursor={'pointer'}
              mr="2"
              onClick={() => setTab(tabs.participants)}
            >
              <Text>{t('participants')}</Text>
              <TabLine isActive={tab === tabs.participants} />
            </Box>
            {isMyPost && (
              <Box
                px="2"
                cursor={'pointer'}
                mr="2"
                onClick={() => setTab(tabs.qr)}
              >
                <Text>{t('qr_code_scanneds')}</Text>
                <TabLine isActive={tab === tabs.qr} />
              </Box>
            )}
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            flex="1"
            py={'5'}
            maxHeight="80vh"
            overflow={'scroll'}
            wordBreak={'break-word'}
            css={{
              '&::-webkit-scrollbar': {
                width: '4px',
              },
              '&::-webkit-scrollbar-track': {
                width: '6px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: 'blue',
                borderRadius: '24px',
              },
            }}
          >
            {tab === tabs.details ? (
              <Box height="100%">
                <Box>
                  <Text pb={'2'} color="dark" fontWeight={'600'}>
                    {post?.title}
                  </Text>
                  <Text pb={'2'} color="default" fontWeight={'500'}>
                    {post?.description}
                  </Text>
                  {post?.date ? (
                    <Box display={'flex'} alignItems={'center'} pb={'2'}>
                      <Icon
                        mr={'2'}
                        fontSize="3xl"
                        as={Calendar}
                        color="blue"
                      />
                      <Text color="dark" fontWeight={'500'}>
                        {'  '}
                        {moment(post.date).format('DD.MM.YYYY HH:mm')}
                      </Text>
                    </Box>
                  ) : null}
                  {post?.endDate ? (
                    <Box pb={'2'}>
                      <Text fontWeight={'bold'} fontSize={20} px={3}>
                        |
                      </Text>
                      <Box display={'flex'} alignItems={'center'}>
                        <Icon
                          mr={'2'}
                          fontSize="3xl"
                          as={Calendar}
                          color="blue"
                        />
                        <Text color="dark" fontWeight={'500'}>
                          {' '}
                          {moment(post.endDate).format('DD.MM.YYYY HH:mm')}
                        </Text>
                      </Box>
                    </Box>
                  ) : null}
                  {location?.display ? (
                    <Box display={'flex'} alignItems={'center'} pb={'2'}>
                      <Icon
                        mr={'2'}
                        fontSize="3xl"
                        as={Location}
                        color="blue"
                      />
                      <Text color="dark" fontWeight={'500'}>
                        {' '}
                        <Link
                          as="a"
                          color={'blue'}
                          display={'inline'}
                          target={'_blank'}
                          href={`https://www.google.com/maps/search/?api=1&query=${encodeURI(
                            location.display
                          )}`}
                          // to={`https://www.google.com/maps/search/?api=1&query=${encodeURI(
                          //   location.coordinates.latitude +
                          //     ',' +
                          //     location.coordinates.longitude
                          // )}`}
                        >
                          {location.display}
                        </Link>
                      </Text>
                    </Box>
                  ) : null}
                  {post?.price ? (
                    <Box display={'flex'} alignItems={'center'} pb={'2'}>
                      <Icon
                        mr={'2'}
                        fontSize="3xl"
                        as={CreditCard}
                        color="blue"
                      />
                      <Text color="dark" fontWeight={'500'}>
                        {' '}
                        {post?.price}
                      </Text>
                    </Box>
                  ) : null}
                  {post?.conditions ? (
                    <Text pb={'2'} color="dark" fontWeight={'500'}>
                      <Text as="span" fontWeight={'600'}>
                        {t('conditions')}:
                      </Text>{' '}
                      {post?.conditions}
                    </Text>
                  ) : null}
                  {post?.link ? (
                    <Box display={'flex'} alignItems={'center'} pb={'2'}>
                      <Icon
                        mr={'2'}
                        fontSize="3xl"
                        as={LinkIcon}
                        color="blue"
                      />
                      <Text color="dark" fontWeight={'500'}>
                        {' '}
                        <Link target={'_blank'} as="a" href={post?.link}>
                          {post?.link}
                        </Link>
                      </Text>
                    </Box>
                  ) : null}
                  {post?.sessions?.length > 0 ? (
                    <>
                      <Divider color="#ccc" width={'50%'} mr={'auto'} my="2" />
                      <Text fontWeight={'600'}>{t('sessions')}</Text>
                      {post.sessions.map((session, index) => {
                        return (
                          <Box key={index} mt="2">
                            <Text pb={'2'} fontWeight={'600'}>
                              {session.title}
                            </Text>
                            {session?.startDate ? (
                              <Text pb={'2'} color="dark" fontWeight={'500'}>
                                <Text as="span" fontWeight={'600'}>
                                  {t('start_date')}:
                                </Text>{' '}
                                {moment(session.startDate).format(
                                  'DD.MM.YYYY HH:mm'
                                )}
                              </Text>
                            ) : null}
                            {session?.endDate ? (
                              <Text pb={'2'} color="dark" fontWeight={'500'}>
                                <Text as="span" fontWeight={'600'}>
                                  {t('end_date')}:
                                </Text>{' '}
                                {moment(session.endDate).format(
                                  'DD.MM.YYYY HH:mm'
                                )}
                              </Text>
                            ) : null}
                          </Box>
                        );
                      })}{' '}
                    </>
                  ) : null}
                </Box>
                <Box>
                  {isLoggedIn && (
                    <>
                      {!isMyPost ? (
                        joined || isJoined || waitingApproval ? (
                          <Button
                            onClick={true ? () => {} : unlike}
                            isFullWidth
                            disabled={true}
                            backgroundColor="primary"
                            color="white"
                            _disabled={{ opacity: 1, cursor: 'not-allowed' }}
                            isLoading={isUnliking}
                          >
                            {waitingApproval
                              ? t('pending_approve')
                              : t('im_going_to_event')}
                          </Button>
                        ) : (
                          <Button
                            variant="outline"
                            onClick={isOutdated ? () => {} : join}
                            isFullWidth
                            colorScheme={'main'}
                            disabled={isOutdated || pending}
                            borderWidth={2}
                            _disabled={{ opacity: 1, cursor: 'not-allowed' }}
                            isLoading={isUnliking}
                          >
                            {isOutdated
                              ? t('outdated_event')
                              : post?.isPaid
                              ? t('pay_with_iyzico')
                              : t('join_event')}
                          </Button>
                        )
                      ) : null}
                    </>
                  )}
                  {!isLoggedIn && (
                    <AuthLinkButon text={t('login_to_join_desc')} />
                  )}
                </Box>
              </Box>
            ) : null}
            {tab === tabs.comments ? (
              <>
                {commentsQuery?.data?.length > 0 ? (
                  <Box
                    id="comments"
                    overflow="scroll"
                    height="500px"
                    display="flex"
                    flexDirection="column-reverse"
                  >
                    <InfiniteScroll
                      {...commentsQuery}
                      scrollableTarget="comments"
                      inverse={true}
                    >
                      {comments?.map(item => (
                        <PostCommentItem
                          key={item.id}
                          text={item?.text}
                          avatar={item?.user?.avatar}
                          userId={item?.userId}
                          username={item?.user?.username}
                        />
                      ))}
                    </InfiniteScroll>
                  </Box>
                ) : (
                  <Box
                    display={'flex'}
                    flex={'1'}
                    flexDirection={'column'}
                    alignItems={'center'}
                  >
                    <Image boxSize="150px" objectFit="cover" src={commentTmp} />
                    <Text>{t('no_comments_make_first')}</Text>
                    {!isLoggedIn && <Text>Hemen oturum açın</Text>}
                  </Box>
                )}
                {isLoggedIn && (
                  <Box ref={commentsEnd}>
                    <CommentInput
                      placeholder={t('write_comment')}
                      onChange={e => setComment(e.target.value)}
                      value={comment}
                      onSubmit={onSendComment}
                      isSending={isSendingComment}
                    />
                  </Box>
                )}
              </>
            ) : null}
            {tab === tabs.participants ? (
              <>
                {participants?.map(item => (
                  <PostParticipantItem
                    key={item.id}
                    avatar={item?.user?.avatar}
                    userId={item?.userId}
                    username={item?.user?.username}
                  />
                ))}
              </>
            ) : null}
            {tab === tabs.qr ? (
              <>
                {participants
                  ?.filter(item => item.isParticipant)
                  .map(item => (
                    <PostParticipantItem
                      key={item.id}
                      avatar={item?.user?.avatar}
                      userId={item?.userId}
                      username={item?.user?.username}
                    />
                  ))}
              </>
            ) : null}
          </Box>
          <Box
            flex={0}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            fontSize={'lg'}
          >
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'flex-start'}
            >
              <Button
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                mr={'3'}
                cursor={'pointer'}
                onClick={() => setTab(tabs.participants)}
              >
                {isJoined || joined ? (
                  <Icon fontSize="2xl" as={User} color="red" />
                ) : (
                  <Icon fontSize="2xl" as={User} color="default" />
                )}

                <AvatarGroup ml={'2'} max={3}>
                  {participants?.map(item => (
                    <Avatar
                      size="sm"
                      name={item?.user?.username}
                      src={item?.user?.avatar}
                    />
                  ))}
                </AvatarGroup>
              </Button>
              <Button
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                mr={'3'}
                onClick={() => setTab(tabs.comments)}
              >
                <Icon fontSize="2xl" as={CommentOutline} color="default" />
                <Text ml={'2'} fontWeight={'600'} color="default">
                  {comments.length}
                </Text>
              </Button>
            </Box>
            {/* <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            mr={'3'}
          >
            <Icon as={BookmarkOutline} fontSize="2xl" color="default" />
          </Box> */}
          </Box>
        </Box>
        <RaffleModal
          onClose={raffleModal.onClose}
          isOpen={raffleModal.isOpen}
          users={allParticipants?.filter(item => item.isParticipant)}
        />
      </Box>
      {isLoggedIn && userPosts.length > 0 ? (
        <Box>
          <Text fontWeight={'600'}>{t('photos_from_event')}</Text>
          <UserPostBoard
            userPosts={userPosts}
            title={post?.title}
            gridGap={'2'}
            gridTemplateColumns={{
              sm: '1fr 1fr 1fr 1fr',
              md: '1fr 1fr 1fr 1fr 1fr',
              lg: '1fr 1fr 1fr 1fr 1fr 1fr',
            }}
            pb="5"
          />
        </Box>
      ) : null}
      <PaymentModal
        eventId={post?.id}
        visible={paymentModal.isOpen}
        onClose={paymentModal.onClose}
      />
    </Box>
  );
};

export default PostDetails;
