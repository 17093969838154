import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Text, Textarea, useDisclosure } from '@chakra-ui/react';
import {
  AlertModal,
  MainLayout,
  MainSection,
  SecondarySection,
  SectionsWrapper,
} from '../../components';
import { routes } from '../../constants/routes';
import toast from '../../helpers/toast';
import { UserService } from '../../services';
import { useTranslation } from 'react-i18next';

const ClubSendEmail = () => {
  const { t } = useTranslation();
  const [content, setContent] = useState('');
  const [pending, setPending] = useState(false);
  const navigate = useNavigate();
  const modal = useDisclosure();

  const onSend = async () => {
    if (!content || !content.trim()) {
      return toast({ title: t('enter_message') });
    }
    try {
      setPending(true);
      const [data] = await UserService.sendEmailToClub(content);
      modal.onClose();
      if (data?.result) {
        toast({
          title: t('email_sent'),
          status: 'success',
        });
        setContent('');
        navigate(-1);
      } else {
        toast({ title: data?.message || t('an_error') });
      }
      setPending(false);
    } catch (error) {
      toast({ title: t('an_error') });
      console.log(error);
      setPending(false);
    }
  };

  return (
    <MainLayout>
      <SectionsWrapper>
        <MainSection>
          <Text mt="3" color={'primary'} fontSize="xl" fontWeight={'bold'}>
            {t('send_email')}
          </Text>
          <Box>
            <Text>{t('send_email_to_club_desc')}</Text>
            <Textarea
              my="3"
              noOfLines={10}
              borderColor="primary"
              minH={'30vh'}
              value={content}
              onChange={e => setContent(e.target.value)}
            />
            <Button
              isFullWidth
              my="2"
              disabled={pending || !content}
              onClick={modal.onOpen}
              backgroundColor="primary"
              color="white"
            >
              {t('send_email')}
            </Button>
          </Box>
        </MainSection>
        <SecondarySection>
          <Button
            onClick={() => navigate(routes.clubDashboard.path)}
            m="3"
            backgroundColor="primary"
            color="white"
          >
            {t('back_to_club_dashboard')}
          </Button>
        </SecondarySection>
      </SectionsWrapper>
      <AlertModal
        description={t('send_email_warning')}
        continueText={t('send')}
        isOpen={modal.isOpen}
        onClose={modal.onClose}
        continueButtonProps={{ isDisabled: pending, isLoading: pending }}
        onContinue={onSend}
      />
    </MainLayout>
  );
};

export default ClubSendEmail;
