import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { Footer, MainLayout } from '../components';
import { useTranslation } from 'react-i18next';

const AboutUs = () => {
  const { t } = useTranslation();
  return (
    <MainLayout>
      <Box my="10">
        <Text fontWeight={'bold'} fontSize="3xl" textAlign={'center'}>
          {t('about_us')}
        </Text>
        <br></br>
        <div>
          <p>
            <strong>{t('about_us_title')}</strong>
          </p>
          <br></br>
          <p>{t('about_us_text_1')}</p>
          <br></br>
          <p>{t('about_us_text_2')}</p>
          <br></br>
          <p>{t('about_us_text_3')}</p>
          <br></br>
        </div>
        <div>
          <div>
            <span>
              <strong>{t('follow_for_more')}: </strong>
              <a href="https://linktr.ee/panoyabak" style={{ color: 'blue' }}>
                @panoyabak
              </a>
            </span>
          </div>
          <div>
            <span>
              <strong>{t('email')}:</strong> iletisim@panoyabak.com
            </span>
          </div>
        </div>
      </Box>
      <Footer />
    </MainLayout>
  );
};

export default AboutUs;
