import React from 'react';
import { Avatar, Box, Image } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../constants/routes';

const PostImageUserCard = ({
  image,
  title = '',
  userId,
  avatar,
  id,
  showAvatar = true,
  username,
  slug,
}) => {
  const navigate = useNavigate();

  const onUser = () => navigate(routes.user.getPath(username));
  const onDetail = () => navigate(routes.detail.getPath(slug));

  return (
    <Box
      borderRadius={'lg'}
      position={'relative'}
      alignSelf={'center'}
      cursor={'pointer'}
      onClick={onDetail}
      overflow={'hidden'}
      boxShadow={'xl'}
    >
      <Image
        src={image}
        alt={title}
        borderRadius={{ base: 'sm', md: 'md', lg: 'lg' }}
        objectFit="cover"
        boxShadow="md"
      />
      {showAvatar && (
        <Avatar
          onClick={onUser}
          cursor={'pointer'}
          position={'absolute'}
          top={'4%'}
          left={'4%'}
          src={avatar.avatar}
          name={avatar.username}
          size="md"
          borderWidth={2}
          borderColor={'primary'}
          borderRadius={'full'}
        />
      )}
    </Box>
  );
};

export default PostImageUserCard;
