import React from 'react';
import { Button, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import {
  MainLayout,
  MainSection,
  SecondarySection,
  SectionsWrapper,
  UserCard,
} from '../../components';
import { routes } from '../../constants/routes';
import useMainStore from '../../hooks/useMainStore';
import { useTranslation } from 'react-i18next';

const ClubDashboard = () => {
  const { t } = useTranslation();
  const {
    data: { user },
  } = useMainStore();

  const navigate = useNavigate();

  const items = [
    {
      key: 'participants',
      label: t('member_operations'),
      onPress: () => {
        navigate(routes.clubMembers.path);
      },
    },
    {
      key: 'sendEmail',
      label: t('send_mail_to_members'),
      onPress: () => {
        navigate(routes.clubEmail.path);
      },
    },
    {
      key: 'newUser',
      label: t('add_new_member'),
      onPress: () => {
        navigate(routes.clubNewMember.path);
      },
    },
  ];

  return (
    <MainLayout>
      <SectionsWrapper>
        <MainSection>
          <Text mt="3" color={'primary'} fontSize="xl" fontWeight={'bold'}>
            {t('operations')}
          </Text>
          {items.map(item => {
            return (
              <Button
                key={item.key}
                size="lg"
                onClick={item.onPress}
                backgroundColor="light"
                borderRadius={'md'}
                margin="2"
              >
                {item.label}
              </Button>
            );
          })}
        </MainSection>
        <SecondarySection>
          <Text mt="3" color={'primary'} fontSize="xl" fontWeight={'bold'}>
            {t('club_info')}
          </Text>
          <UserCard user={user} />
        </SecondarySection>
      </SectionsWrapper>
    </MainLayout>
  );
};

export default ClubDashboard;
