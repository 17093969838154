import React, { useRef, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  Divider,
  Box,
  Avatar,
  Input as CInput,
} from '@chakra-ui/react';
import useMainStore from '../../hooks/useMainStore';
import { Input, InputWrapper, PasswordInput, Textarea } from '..';
import useForm from '../../hooks/useForm';
import { UserService } from '../../services';
import { useMutation } from 'react-query';
import * as yup from 'yup';
import toast from '../../helpers/toast';
import { useTranslation } from 'react-i18next';

const EditProfileModal = ({ onClose = () => {}, isOpen = false }) => {
  const { t } = useTranslation();
  const {
    data: { user },
    updateUser,
  } = useMainStore();

  const { mutateAsync: updateUserAsync, isLoading } = useMutation(
    UserService.updateUser
  );
  const { mutateAsync: updatePassword, isLoading: updatePassLoading } =
    useMutation(UserService.changePassword);
  const { mutateAsync: updateAvatar, isLoading: updateAvatarLoading } =
    useMutation(UserService.updateAvatar);

  const [image, setImage] = useState({ url: null, image: null });
  const imageInput = useRef();

  const passwordValidation = yup.object({
    password: yup
      .string()
      .min(6, t('min_x_characters', { x: 6 }))
      .required(t('mandatory_field')),
    newPassword: yup
      .string()
      .min(6, t('min_x_characters', { x: 6 }))
      .required(t('mandatory_field')),
  });

  const passwordValues = useForm(
    {
      password: '',
      newPassword: '',
    },
    passwordValidation
  );

  const validation = yup.object({
    username: yup.string().required(t('mandatory_field')),
    name: yup.string().required(t('mandatory_field')),
    biyografi: yup.string(),
    email: yup
      .string()
      .email(t('invalid_email'))
      .required(t('mandatory_field')),
  });

  const { values, setValues, isValid, onFormSubmit, errors } = useForm(
    {
      username: user?.username || '',
      name: user?.name || '',
      email: user?.email || '',
      biyografi: user.biyografi || user?.bio || '',
    },
    validation
  );

  const onSubmit = async () => {
    onFormSubmit();
    if (!isValid) return;
    try {
      const [data] = await updateUserAsync(values);
      await onUpdateAvatar();
      if (data) {
        updateUser(values);
        toast({
          title: t('info_saved'),
          status: 'success',
        });
        onCloseModal();
      }
    } catch (error) {
      toast({
        title: t('an_error'),
        status: 'warning',
      });
    }
  };

  const onUpdatePassword = async () => {
    passwordValues.onFormSubmit();
    if (!passwordValues.isValid) return;
    try {
      const [data] = await updatePassword({
        oldPassword: passwordValues.values.password,
        password: passwordValues.values.newPassword,
      });
      if (data.result) {
        toast({
          title: t('password_changed'),
          status: 'info',
        });
        return onCloseModal();
      } else if (data.message)
        toast({
          title: data.message,
          status: 'info',
        });
    } catch (error) {
      toast({
        title: t('an_error'),
        status: 'warning',
      });
    }
  };

  const onUpdateAvatar = async () => {
    try {
      if (image.image) {
        const [data] = await updateAvatar(image.image);
        if (data) {
          updateUser({ avatar: data });
        }
      }
      return true;
    } catch (error) {
      toast({
        title: error || t('an_error'),
        status: 'warning',
      });
      return false;
    }
  };

  const onSelectImage = async () => {
    imageInput.current?.click();
  };

  const onUploadImage = async e => {
    const image = e.target.files[0];
    setImage({ url: URL.createObjectURL(image), image });
  };

  const onCloseModal = () => {
    setImage({ url: null, image: null });
    onClose();
  };

  return (
    <Modal onClose={onCloseModal} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent backgroundColor={'light'}>
        <ModalHeader>{''}</ModalHeader>
        <ModalCloseButton />
        <ModalBody
          overflow={'scroll'}
          css={{
            '&::-webkit-scrollbar': {
              width: '4px',
            },
            '&::-webkit-scrollbar-track': {
              width: '6px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: 'blue',
              borderRadius: '24px',
            },
          }}
        >
          <Box cursor={'pointer'} onClick={onSelectImage} display={'flex'}>
            <Avatar
              borderWidth={3}
              borderColor={'primary'}
              mx="auto"
              size="2xl"
              src={image.image ? image.url : user.avatar}
            />
          </Box>
          <CInput
            ref={imageInput}
            type="file"
            accept="image/*"
            display="none"
            onChange={onUploadImage}
          />
          <InputWrapper
            label={t('username')}
            id="username"
            error={errors.username}
          >
            <Input
              name="username"
              id="username"
              onChange={setValues}
              value={values.username}
            />
          </InputWrapper>
          <InputWrapper
            label={t('name_lastname')}
            id="name"
            error={errors.name}
          >
            <Input
              id="name"
              name="name"
              onChange={setValues}
              value={values.name}
            />
          </InputWrapper>
          <InputWrapper label={t('email')} id="email" error={errors.email}>
            <Input
              name="email"
              id="email"
              onChange={setValues}
              value={values.email}
              type="email"
            />
          </InputWrapper>
          <InputWrapper
            label={t('bio')}
            id="biyografi"
            error={errors.biyografi}
          >
            <Textarea
              label={t('bio')}
              name="biyografi"
              id="biyografi"
              onChange={setValues}
              value={values.biyografi}
            />
          </InputWrapper>
          <Button
            onClick={onSubmit}
            isFullWidth
            backgroundColor="primary"
            color="white"
            disabled={!isValid || isLoading || updateAvatarLoading}
            isLoading={isLoading || updateAvatarLoading}
          >
            {t('save')}
          </Button>
          <Divider my={'5'} color="gray" />
          <InputWrapper
            label={t('current_password')}
            id="password"
            error={passwordValues.isSubmitted && passwordValues.errors.password}
          >
            <PasswordInput
              name="password"
              id="password"
              onChange={passwordValues.setValues}
              value={passwordValues.values.password}
              showLeftIcon={false}
              backgroundColor="white"
              inputGroupProps={{ my: 0 }}
            />
          </InputWrapper>
          <InputWrapper
            label={t('new_password')}
            id="newPassword"
            error={
              passwordValues.isSubmitted && passwordValues.errors.newPassword
            }
          >
            <PasswordInput
              name="newPassword"
              id="newPassword"
              onChange={passwordValues.setValues}
              value={passwordValues.values.newPassword}
              showLeftIcon={false}
              backgroundColor="white"
              inputGroupProps={{ my: 0 }}
            />
          </InputWrapper>
          <Button
            onClick={onUpdatePassword}
            isFullWidth
            backgroundColor="primary"
            color="white"
            disabled={!passwordValues.isValid || updatePassLoading}
            isLoading={updatePassLoading}
          >
            {t('change_password')}
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default EditProfileModal;
