import React, { useState } from 'react';
import {
  InputGroup,
  Input,
  InputRightElement,
  Button,
  Icon,
  Box,
} from '@chakra-ui/react';

import { Search } from './icons';

const SearchBox = ({
  onSearch = () => {},
  maxWidth = 400,
  placeholder,
  ...props
}) => {
  const [text, setText] = useState('');

  const onSubmit = e => {
    e?.preventDefault();
    onSearch(text);
    setText('');
  };

  return (
    <Box as="form" onSubmit={onSubmit} w="100%">
      <InputGroup maxW={maxWidth} mx="auto" backgroundColor="white">
        <Input
          type={'text'}
          placeholder={placeholder}
          borderWidth={0}
          fontWeight={'500'}
          value={text}
          onChange={e => setText(e.target.value)}
          {...props}
        />
        <InputRightElement>
          <Button
            borderBottomLeftRadius={'none'}
            borderTopLeftRadius={'none'}
            backgroundColor={'primary'}
            onClick={onSubmit}
          >
            <Icon as={Search} color={'white'} />
          </Button>
        </InputRightElement>
      </InputGroup>
    </Box>
  );
};

export default SearchBox;
