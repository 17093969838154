import React, { useRef } from 'react';
import { Box, Text, Icon, Input, Image } from '@chakra-ui/react';
import { Bookmark, CommentOutline, HeartOutline, Plus } from '../icons';

const opacity = 0.5;

const SelectImage = ({ onChange, image }) => {
  const day = '01';
  const monthYear = 'Ocak 2021';
  const imageInput = useRef();

  const onSelectImage = async () => {
    imageInput.current?.click();
  };

  const onUploadImage = async e => {
    const image = e.target.files[0];
    onChange({ url: URL.createObjectURL(image), image });
  };

  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      justifyContent={'center'}
      alignItems={'flex-start'}
      my={'3'}
      fontSize={'md'}
    >
      <Box
        marginX={'auto'}
        borderLeftRadius={'2xl'}
        borderBottomRightRadius={'2xl'}
        backgroundColor={'light'}
        px={'5'}
        py="4"
        flex="1"
      >
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Box display={'flex'} alignItems={'center'}>
            <Box
              height={'50px'}
              width={'50px'}
              borderRadius={'full'}
              backgroundColor={'#c4c4c4'}
              mb={'2'}
            />
          </Box>
        </Box>
        <Box
          flex={'1'}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          height={'60vh'}
          backgroundColor={'white'}
          borderRadius={'2xl'}
          my="2"
          cursor={'pointer'}
          onClick={onSelectImage}
        >
          {image.image ? (
            <Image
              width={'100%'}
              borderRadius={'2xl'}
              height={'100%'}
              objectFit={'contain'}
              src={image.url}
            />
          ) : (
            <Icon as={Plus} color="default" fontSize={'10rem'} />
          )}
          <Input
            ref={imageInput}
            type="file"
            accept="image/*"
            display="none"
            onChange={onUploadImage}
          />
        </Box>
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          fontSize={'lg'}
        >
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'flex-start'}
          >
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              mr={'3'}
              opacity={opacity}
            >
              <Icon as={HeartOutline} color="default" />
              <Text ml={'2'} fontWeight={'600'} color="default">
                {999}
              </Text>
            </Box>
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              mr={'3'}
              opacity={opacity}
            >
              <Icon as={CommentOutline} color="default" />
              <Text ml={'2'} fontWeight={'600'} color="default">
                {999}
              </Text>
            </Box>
          </Box>
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            mr={'3'}
            opacity={opacity}
          >
            <Icon as={Bookmark} color="default" />
          </Box>
        </Box>
      </Box>
      <Box
        p={'5'}
        backgroundColor={'light'}
        borderRightRadius={'2xl'}
        justifyContent={'center'}
        alignItems={'center'}
        display={'flex'}
        flexDirection={'column'}
      >
        <Text
          opacity={opacity}
          fontWeight={'600'}
          fontSize={'8xl'}
          lineHeight={'none'}
        >
          {day}
        </Text>
        <Text opacity={opacity} fontWeight={'500'}>
          {monthYear}
        </Text>
      </Box>
    </Box>
  );
};

export default SelectImage;
