/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useMemo, useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  Button,
  Spinner,
  Text,
  Image,
  useDisclosure,
  Icon,
  IconButton,
  MenuButton,
  MenuList,
  MenuItem,
  Menu,
} from '@chakra-ui/react';
import {
  MainLayout,
  MainSection,
  RecommendedUsers,
  SecondarySection,
  Footer,
  UserBoard,
  UserPostBoard,
  TabLine,
  FollowListModal,
  SectionsWrapper,
} from '../components';
import {
  ProfileEvent,
  UserPost,
  Certificate,
  Menu as MenuIcon,
} from '../components/icons';
import useMainStore from '../hooks/useMainStore';
import { PostService, UserService } from '../services';
import { useNavigate, useParams } from 'react-router-dom';
import toast from '../helpers/toast';
import { routes } from '../constants/routes';
import FirebaseChat from '@metehankurucu/firebase-chat';
import ProfileTmp from '../assets/images/ProfileTmp.png';
import {
  communityBadge,
  speakerBadge,
  govBadge,
  stkBadge,
} from '../assets/images/badge';
import { Action, checkPermission } from '../constants/roles';
import { useMediaQuery } from 'react-responsive';
import useFetch from '../hooks/useFetch';
import { useTranslation } from 'react-i18next';

const tabs = {
  posts: 'posts',
  userPosts: 'userPosts',
  certificates: 'certificates',
};

const User = () => {
  const { username } = useParams();

  const {
    isUserMod,
    data: { user },
    isLoggedIn,
    blockUser,
    reportUser,
  } = useMainStore();
  const [id, setId] = useState(null);
  const [tab, setTab] = useState(tabs.posts);
  const followersModal = useDisclosure();
  const followsModal = useDisclosure();
  const [userData, setUserData] = useState(null);
  const [userPosts, setUserPosts] = useState([]);
  const [following, setFollowing] = useState(false);
  const [followers, setFollowers] = useState([]);
  const [follows, setFollows] = useState([]);
  const [stories, setStories] = useState([]);
  const [blockedByMe, setBlockedByMe] = useState(null);
  const [blockedByUser, setBlockedByUser] = useState(null);
  const [fetching, setFetching] = useState(true);
  const [posts, setPosts] = useState([]);
  const [canSendMessage, setCanSendMessage] = useState(false);
  const navigate = useNavigate();
  const rooms = isLoggedIn && FirebaseChat.rooms();
  const { t } = useTranslation();

  useEffect(() => {
    init(username);
  }, [username]);

  const isMod = useMemo(() => {
    if (userData?.roles) return isUserMod(userData.roles);
    return false;
  }, [userData]);

  const init = async username => {
    setFetching(true);
    const [data, error] = isLoggedIn
      ? await UserService.getUserProfileByUsername(username)
      : await UserService.withoutSigninGetUserProfile(username);
    if (data.user) {
      setUserData(data.user);
      const id = data.user.id;
      setId(id);
      const isMod = isUserMod(data.user?.roles);
      getPosts(isMod, id);
      getUserPosts(id);
      const {
        followers,
        following,
        isBlockedByMe,
        isBlockedByUser,
        stories,
        isFollowing,
        canSendMessage,
      } = data;
      isLoggedIn && setStories(stories);
      setFollowers(followers);
      setFollowing(isFollowing);
      setFollows(following);
      isLoggedIn && setCanSendMessage(canSendMessage);
      isLoggedIn && setBlockedByMe(isBlockedByMe);
      isLoggedIn && setBlockedByUser(isBlockedByUser);
    }
    setFetching(false);
  };

  const getPosts = async (isMod, userId) => {
    if (isMod) {
      const [data, error] = await UserService.getModFeed(userId);
      if (data) setPosts(data);
    } else {
      const [data, error] = await UserService.getUserFeed(userId);
      if (data) setPosts(data);
    }
  };

  const getUserPosts = async userId => {
    const [data, error] = await UserService.getProfileUserPosts(userId);
    if (data) setUserPosts(data);
  };

  const registerClub = async () => {
    const [data, error] = await UserService.registerClub(id);
    if (data) {
      toast({ title: t('register_club_success'), status: 'success' });
    }
    if (error) {
      toast({
        title: t('an_error'),
        status: 'warning',
      });
    }
  };

  const unfollow = async () => {
    setFollowing(false);
    const newFollowers = followers.filter(item => {
      const userId =
        item?.user?.id ||
        item?.userId ||
        item?.user?.userId ||
        item?.users?.userId;
      return userId != user.id;
    });
    setFollowers(newFollowers);
    const [data] = await UserService.unfollow(id);
  };

  const follow = async () => {
    setFollowing(true);
    const index = followers.findIndex(item => {
      const userId =
        item?.user?.id ||
        item?.userId ||
        item?.user?.userId ||
        item?.users?.userId;
      return userId == user?.id;
    });
    if (index === -1) {
      followers.unshift({ user });
      setFollowers(followers);
    }
    await UserService.follow(id);
  };

  const onBlock = async () => {
    if (id === user?.id) return;
    const [data] = await UserService.block(id);
    if (data.code === 200) {
      setBlockedByMe(data.result);
      toast({
        title: t('user_blocked'),
      });
    }
  };

  const onDeleteBlock = async () => {
    if (!blockedByMe?.id) return;
    const [data] = await UserService.deleteBlock(blockedByMe.id);
    if (data.code === 200) {
      setBlockedByMe(false);
      toast({
        title: t('user_block_removed'),
      });
    }
  };

  const onMessage = async () => {
    const room = await rooms.getRoom(id, { createIfNotExists: true });
    if (room && userData)
      navigate(routes.chat.getPath({ userId: id, roomId: room.id }));
  };

  const showClubMenu = checkPermission(userData?.roles, Action.ShowClubMenu);

  //show badge
  const showSpeaker = checkPermission(userData?.roles, Action.ShowSpeaker);
  const showCommunity = checkPermission(userData?.roles, Action.showCommunity);
  const showGoverment = checkPermission(userData?.roles, Action.showGoverment);
  const showNonGoverment = checkPermission(
    userData?.roles,
    Action.showNonGoverment
  );

  const isMobile = useMediaQuery({ query: '(max-width: 786px)' });

  return (
    <MainLayout>
      <SectionsWrapper>
        <MainSection>
          <Box
            display={'flex'}
            //alignItems={'center'}
            justifyContent={'space-between'}
            mt={isMobile ? '1' : '1'}
            p={isMobile ? '1' : '5'}
          >
            <Avatar
              size={isMobile ? 'xl' : '2xl'}
              src={userData?.avatar}
              alt={t('profile_photo')}
              name={userData?.name || userData?.username}
              objectFit="cover"
            />
            <Box
              display={'flex'}
              flex="1"
              flexDirection={'column'}
              alignItems={'flex-start'}
              justifyContent={'center'}
              pl={'5'}
            >
              <Text mb={'2'} fontWeight={'600'} fontSize="md">
                {userData?.username}
                {userData?.name ? ' - ' + userData?.name : ''}
              </Text>
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'flex-start'}
                color="default"
                mb="2"
              >
                <Button
                  size="md"
                  variant={'ghost'}
                  pl="0"
                  _focus={{ boxShadow: 'none' }}
                  onClick={followsModal.onOpen}
                >
                  {follows?.length} {t('following')}
                </Button>
                <Button
                  size="md"
                  variant={'ghost'}
                  pl="0"
                  _focus={{ boxShadow: 'none' }}
                  onClick={followersModal.onOpen}
                >
                  {followers?.length} {t('followers')}
                </Button>
              </Box>
              {fetching ? (
                <Box>
                  <Spinner size={'md'} color={'primary'} />
                </Box>
              ) : (
                <Box>
                  {isLoggedIn && blockedByMe ? (
                    <Button
                      variant={'outline'}
                      color="primary"
                      size="sm"
                      px={'6'}
                      borderWidth={2}
                      onClick={onDeleteBlock}
                    >
                      {t('remove_block')}
                    </Button>
                  ) : (
                    <>
                      {isLoggedIn && (
                        <Button
                          variant={following ? 'outline' : 'solid'}
                          color={following ? 'primary' : 'white'}
                          backgroundColor={following ? 'white' : 'primary'}
                          size="sm"
                          mr="2"
                          px={'3'}
                          borderWidth={1}
                          borderColor={'primary'}
                          _hover={
                            following
                              ? { borderColor: 'red', color: 'red' }
                              : { backgroundColor: 'blue' }
                          }
                          onClick={() => {
                            if (following) unfollow();
                            else follow();
                          }}
                        >
                          {following ? t('unfollow') : t('follow')}
                        </Button>
                      )}
                      {isLoggedIn && canSendMessage ? (
                        <Button
                          colorScheme="blue"
                          variant={'outline'}
                          color="primary"
                          size="sm"
                          mr="2"
                          px={'3'}
                          borderWidth={1}
                          onClick={onMessage}
                        >
                          {t('send_message')}
                        </Button>
                      ) : null}
                    </>
                  )}
                </Box>
              )}
            </Box>
            {isLoggedIn && (
              <Menu>
                <MenuButton
                  borderWidth={0}
                  as={IconButton}
                  aria-label="Options"
                  icon={<Icon as={MenuIcon} />}
                  variant="outline"
                />
                <MenuList borderWidth={0}>
                  <MenuItem onClick={() => reportUser(userData.id)}>
                    {t('report')}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      blockUser(userData.id);
                      navigate(routes.home.path);
                    }}
                  >
                    {t('block_user')}
                  </MenuItem>
                </MenuList>
              </Menu>
            )}
          </Box>
          <Box display={'flex'} ml={'2'} mb={'2'}>
            {showCommunity ? (
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                borderRadius={10}
                borderWidth={1}
                borderColor={'#194a94'}
                p={'1'}
              >
                <Icon fontSize="2xl" as={communityBadge} />
                <Text
                  ml={'1'}
                  fontWeight={'300'}
                  fontSize="sm"
                  color={'#194a94'}
                >
                  {t('community')}
                </Text>
              </Box>
            ) : null}
            {showSpeaker ? (
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                borderRadius={10}
                borderWidth={1}
                borderColor={'#b72020'}
                p={'1'}
              >
                <Icon fontSize="2xl" as={speakerBadge} />
                <Text
                  ml={'1'}
                  fontWeight={'300'}
                  fontSize="sm"
                  color={'#b72020'}
                >
                  {t('speaker')}
                </Text>
              </Box>
            ) : null}
            {showGoverment ? (
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                borderRadius={10}
                borderWidth={1}
                borderColor={'#366472'}
                p={'1'}
              >
                <Icon fontSize="2xl" as={govBadge} />
                <Text
                  ml={'1'}
                  fontWeight={'300'}
                  fontSize="sm"
                  color={'#366472'}
                >
                  {t('government')}
                </Text>
              </Box>
            ) : null}
            {showNonGoverment ? (
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                borderRadius={10}
                borderWidth={1}
                borderColor={'#5c2d1f'}
                p={'1'}
              >
                <Icon fontSize="2xl" as={stkBadge} />
                <Text
                  ml={'1'}
                  fontWeight={'300'}
                  fontSize="sm"
                  color={'#5c2d1f'}
                >
                  {t('non_goverment')}
                </Text>
              </Box>
            ) : null}
          </Box>
          {userData?.biyografi ? (
            <Text ml={'2'} mb={'1'} fontWeight={'300'} fontSize="sm">
              {userData?.biyografi}
            </Text>
          ) : null}
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'flex-start'}
            mt={'2'}
            fontWeight={'600'}
            fontSize={'md'}
            color="default"
          >
            <Box
              px="2"
              cursor={'pointer'}
              mr="2"
              onClick={() => setTab(tabs.posts)}
            >
              <Box display={'flex'} alignItems={'center'}>
                <Icon mr={'2'} fontSize="2xl" as={ProfileEvent} />
                <Text>{isMod ? t('shared_events') : t('joined_events')}</Text>
              </Box>
              <TabLine isActive={tab === tabs.posts} />
            </Box>
            <Box
              px="2"
              cursor={'pointer'}
              mr="2"
              onClick={() => setTab(tabs.userPosts)}
            >
              <Box display={'flex'} alignItems={'center'}>
                <Icon mr={'2'} fontSize="2xl" as={UserPost} />
                <Text>{t('posts')}</Text>
              </Box>
              <TabLine isActive={tab === tabs.userPosts} />
            </Box>
            {/* <Box
              px="2"
              cursor={'pointer'}
              mr="2"
              onClick={() => setTab(tabs.certificates)}
            >
              <Text>Sertifikalar</Text>
              <TabLine isActive={tab === tabs.certificates} />
            </Box> */}
          </Box>
          {tab === tabs.posts ? (
            <>
              {posts.length > 0 ? (
                <UserBoard showAvatar={false} posts={posts} />
              ) : (
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  alignItems={'center'}
                >
                  <Image boxSize="200px" src={ProfileTmp} />
                  <Text>{t('no_posts_yet')}</Text>
                  <Text>
                    {isMod
                      ? t('shared_events_shows_here')
                      : t('joined_events_shows_here')}
                  </Text>
                </Box>
              )}
            </>
          ) : null}
          {tab === tabs.userPosts ? (
            <>
              {userPosts.length > 0 ? (
                <>
                  <UserPostBoard userPosts={userPosts} />
                </>
              ) : (
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  alignItems={'center'}
                >
                  <Image boxSize="200px" src={ProfileTmp} />
                  <Text>{t('no_posts_yet')}</Text>
                  <Text>{t('shared_photos_shows_here')}</Text>
                </Box>
              )}
            </>
          ) : null}
          {tab === tabs.certificates ? <></> : null}
        </MainSection>
        <SecondarySection>
          {isLoggedIn && <RecommendedUsers />}
          <Footer />
        </SecondarySection>
      </SectionsWrapper>
      <FollowListModal
        title={t('followers')}
        onClose={followersModal.onClose}
        isOpen={followersModal.isOpen}
        userId={id}
        type="followers"
        users={followers}
      />
      <FollowListModal
        title={t('following')}
        onClose={followsModal.onClose}
        isOpen={followsModal.isOpen}
        userId={id}
        type="followees"
        users={follows}
      />
    </MainLayout>
  );
};

export default User;
