import { Box, Avatar, Text } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../constants/routes';
import useMainStore from '../../hooks/useMainStore';

const UserCard = ({ user }) => {
  const navigate = useNavigate();
  const {
    data: { user: currentUser },
  } = useMainStore();

  const onUser = userId => {
    const isMe = currentUser?.id === userId;
    if (isMe) return navigate(routes.profile.path);
    navigate(routes.user.getPath(user.username));
  };

  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      my={'1'}
      cursor={'pointer'}
      onClick={() => onUser(user?.id)}
      bg={'light'}
      p="2"
      borderRadius={'lg'}
    >
      <Box pr={'2'}>
        <Avatar
          borderWidth={1}
          borderColor={'primary'}
          size={'md'}
          src={user?.avatar}
          name={user?.name || user?.username}
        />
      </Box>
      <Box>
        <Text fontWeight={'600'} fontSize={'lg'} color="dark">
          {user?.name} • {user?.username}
        </Text>
      </Box>
    </Box>
  );
};

export default UserCard;
