import React from 'react';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Image, Box } from '@chakra-ui/react';
import carousel1 from '../assets/images/phone/8_50.png';
import carousel2 from '../assets/images/phone/13_50.png';
import carousel3 from '../assets/images/phone/4_50.png';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import { useBreakpointValue } from '@chakra-ui/react';

const slides = [
  {
    image: carousel1,
  },
  {
    image: carousel2,
  },
  {
    image: carousel3,
  },
];

const Carousel = () => {
  const maxWidth = useBreakpointValue({ base: '90vw', md: '50vw' });

  return (
    <Swiper
      modules={[Pagination]}
      style={{ maxWidth, margin: 'auto' }}
      pagination={{ clickable: true }}
    >
      {slides.map((slide, index) => {
        return (
          <SwiperSlide key={index}>
            <Box
              marginX="auto"
              //maxW={'65%'}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              flexDirection={'column'}
            >
              <Image src={slide.image} h={500} />
            </Box>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default Carousel;
