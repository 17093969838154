import React, { useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Text, useDisclosure } from '@chakra-ui/react';
import {
  AlertModal,
  MainLayout,
  SelectCertificate,
  Spinner,
} from '../../components';
import usePost from '../../hooks/usePost';
import { PostService } from '../../services';
import toast from '../../helpers/toast';
import { routes } from '../../constants/routes';
import { useTranslation } from 'react-i18next';

const CreateCertificate = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const confirmModal = useDisclosure();
  const [selectedCert, setSelectedCert] = useState(null);
  const { post, isLoading } = usePost(id, {
    navigateWhenNotFound: true,
    navigateWhenNotMyPost: true,
  });

  const { mutateAsync, isLoading: pending } = useMutation(
    PostService.generateCertificate
  );

  const { data: certsData } = useQuery(
    'getCertificates',
    PostService.getCertificates
  );

  const certs = useMemo(() => {
    if (certsData?.[0]) return certsData?.[0];
    return {};
  }, [certsData]);

  const onCreate = async () => {
    if (!selectedCert)
      return toast({ title: t('select_certificate'), status: 'warning' });
    try {
      const [data, error] = await mutateAsync({
        postId: id,
        certificateKey: selectedCert.key,
      });
      confirmModal.onClose();
      if (data?.result) {
        toast({ title: t('cert_created'), status: 'success' });
        navigate(routes.postDashboard.getPath(id));
      }
      if (error || data?.error) {
        toast({
          title: t('an_error'),
          status: 'error',
        });
      }
    } catch (error) {
      confirmModal.onClose();
      toast({
        title: t('an_error'),
        description: String(error),
        status: 'error',
      });
    }
  };

  return (
    <MainLayout
      withBackButton
      onBack={() => navigate(routes.postDashboard.getPath(id))}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <Box p="2">
          <Text my="3" fontSize="2xl" fontWeight={'bold'}>
            {t('create_cert_for_1')}
            <Text as="strong" color={'primary'}>
              {post?.title}
            </Text>{' '}
            {t('create_cert_for_2')}
          </Text>
          <SelectCertificate
            data={certs}
            onSelect={setSelectedCert}
            selected={selectedCert}
            title={t('cert_selection')}
          />
          <Button
            isLoading={pending}
            disabled={isLoading || pending || !selectedCert}
            backgroundColor={'primary'}
            color="white"
            size={'lg'}
            isFullWidth
            my="3"
            onClick={confirmModal.onOpen}
          >
            {t('create')}
          </Button>
        </Box>
      )}
      <AlertModal
        title={t('create_cert_warning_title')}
        description={t('create_cert_warning_desc')}
        continueText={t('create')}
        isOpen={confirmModal.isOpen}
        onClose={confirmModal.onClose}
        continueButtonProps={{ isDisabled: pending, isLoading: pending }}
        onContinue={onCreate}
      />
    </MainLayout>
  );
};

export default CreateCertificate;
