/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import API from '../api';
import endpoints from '../api/endpoints';

const initiatePayment = async ({ eventId }) => {
  const { data } = await API.post(endpoints.initiatePayment, { eventId });
  return [data, null];
};

const resultPayment = async ({ token }) => {
  const { data } = await API.post(endpoints.resultPayment, { token });
  return [data, null];
};

const PaymentsService = {
  resultPayment,
  initiatePayment,
};
export default PaymentsService;
