import React from 'react';
import { Box, Button, Heading, Text } from '@chakra-ui/react';
import { AuthLayout, AuthInput } from '../components';
import { User } from '../components/icons';
import useForm from '../hooks/useForm';
import { AuthService } from '../services';
import toast, { showFormErrorsToast } from '../helpers/toast';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import * as yup from 'yup';
import { routes } from '../constants/routes';
import { useTranslation } from 'react-i18next';

const ForgotPassword = () => {
  const { t } = useTranslation();
  const validation = yup.object({
    email: yup.string().email(t('invalid_email')).required(t('enter_email')),
  });
  const { values, setValues, errors, isValid } = useForm(
    {
      email: '',
    },
    validation
  );
  const { mutateAsync, isLoading } = useMutation(AuthService.resetPassword);

  const navigate = useNavigate();

  const onSubmit = async () => {
    if (!isValid) {
      return showFormErrorsToast(errors, toast);
    }
    try {
      const [data] = await mutateAsync(values.email);
      toast({
        title: data.message || t('check_email'),
        status: 'success',
      });
      navigate(routes.login.path);
    } catch (error) {
      toast({
        title: error?.response?.data?.message || t('an_error'),
        status: 'warning',
      });
    }
  };

  return (
    <AuthLayout withBackButton>
      <Box width={{ base: '100%', md: '80%', lg: '70%' }} mx="auto">
        <Heading>{t('forgot_password_title')} 🔐</Heading>
        <Text ml="1" my={'5'} fontWeight={'600'}>
          {t('forgot_password_description')}
        </Text>
        <AuthInput
          Icon={User}
          placeholder={t('email')}
          name="email"
          onChange={setValues}
          value={values.email}
          type="email"
        />
        <Button
          backgroundColor={'primary'}
          borderRadius={'full'}
          height={'65px'}
          isFullWidth
          my={'5'}
          onClick={onSubmit}
          isLoading={isLoading}
        >
          <Text color={'white'} fontWeight={'600'} fontSize={'1.3rem'}>
            {t('send')}
          </Text>
        </Button>
      </Box>
    </AuthLayout>
  );
};

export default ForgotPassword;
