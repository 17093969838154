import React from 'react';
import Spinner from './Spinner';
import InfiniteScrollComponent from 'react-infinite-scroll-component';

const InfiniteScroll = ({
  children,
  dataLength,
  isLoadingMore,
  loadMore,
  hasMore,
  ...props
}) => {
  return (
    <InfiniteScrollComponent
      dataLength={dataLength}
      next={loadMore}
      hasMore={hasMore}
      loader={<Spinner isVisible={isLoadingMore} />}
      {...props}
    >
      {children}
    </InfiniteScrollComponent>
  );
};

export default InfiniteScroll;
