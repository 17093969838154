import React from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogCloseButton,
  AlertDialogBody,
  AlertDialogFooter,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const AlertModal = ({
  title,
  description = '',
  continueText,
  cancelText,
  onClose = () => {},
  isOpen = false,
  onContinue = () => {},
  continueButtonProps = {},
}) => {
  const { t } = useTranslation();

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader>{title || t('are_you_sure')}</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>{description}</AlertDialogBody>
        <AlertDialogFooter>
          <Button onClick={onClose}>{cancelText || t('cancel')}</Button>
          <Button
            onClick={onContinue}
            colorScheme="red"
            ml={3}
            {...continueButtonProps}
          >
            {continueText || t('continue')}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default AlertModal;
