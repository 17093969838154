/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {
  Avatar,
  Box,
  Text,
  MenuButton,
  MenuList,
  MenuItem,
  Menu,
  Icon,
  Image,
  IconButton,
} from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../constants/routes';
import { CommentOutline, User, Menu as MenuIcon } from '../icons';
import useMainStore from '../../hooks/useMainStore';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';

const PostCard = ({
  avatar,
  image,
  username,
  title,
  description,
  likeCount,
  commentCount,
  date,
  userId,
  id,
  slug,
  isOnline,
  price,
  city,
  //sessions = [],
}) => {
  const { t } = useTranslation();
  const { blockUser, reportUser, onShare } = useMainStore();
  const navigate = useNavigate();

  const day = moment(date).format('DD');
  const monthYear = moment(date).format('MMMM YYYY');

  const onDetail = () => navigate(routes.detail.getPath(slug));

  const onUser = () => navigate(routes.user.getPath(username));

  const isMobile = useMediaQuery({ query: '(max-width: 786px)' });

  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      justifyContent={'center'}
      alignItems={'flex-start'}
      my={'3'}
      fontSize={'md'}
    >
      {!isMobile && (
        <Box
          p={'5'}
          backgroundColor={'light'}
          borderLeftRadius={'2xl'}
          justifyContent={'center'}
          alignItems={'center'}
          display={'flex'}
          flexDirection={'column'}
          //flex="0"
          w={150}
        >
          <Text fontWeight={'600'} fontSize={'7xl'} lineHeight={'none'}>
            {day}
          </Text>
          <Text fontWeight={'500'}>{monthYear}</Text>
        </Box>
      )}
      <Box
        marginX={'auto'}
        borderRightRadius={'2xl'}
        borderBottomRadius={'2xl'}
        backgroundColor={'light'}
        px={'5'}
        py="4"
        flex="1"
      >
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Box
            display={'flex'}
            alignItems={'center'}
            cursor={'pointer'}
            onClick={onUser}
          >
            <Avatar src={avatar} size={'sm'} />
            <Box mb="2">
              <Text color="default" ml={'2'} fontWeight={'600'}>
                {username}
              </Text>
              {isMobile ? (
                <Text
                  fontWeight={'300'}
                  ml={'2'}
                  fontSize={'sm'}
                  lineHeight={'none'}
                >
                  {t('planned_at_x_y', {
                    day,
                    monthYear,
                  })}
                </Text>
              ) : null}
            </Box>
          </Box>
          <Menu>
            <MenuButton
              borderWidth={0}
              as={IconButton}
              aria-label="Options"
              icon={<Icon as={MenuIcon} />}
              variant="outline"
            />
            <MenuList borderWidth={0}>
              <MenuItem onClick={() => onShare({ id, title, slug })}>
                {t('share_event')}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  blockUser(userId);
                  navigate(routes.home.path);
                }}
              >
                {t('block_user')}
              </MenuItem>
              <MenuItem onClick={() => reportUser(userId)}>
                {t('report')}
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
        <Box
          display={'flex'}
          flexDirection={isMobile ? 'column' : 'row'}
          //justifyContent={'center'}
          cursor={'pointer'}
          onClick={onDetail}
        >
          <Box display={'flex'} justifyContent={'center'}>
            <Image
              //maxH={'50vh'}
              maxW={isMobile ? '45vh' : '30vh'}
              objectFit="cover"
              //alignSelf={'flex-start'}
              alignSelf={'center'}
              borderWidth={2}
              borderColor={'primary'}
              src={image}
              borderRadius={'2xl'}
              alt={title}
            />
          </Box>
          <Box
            justifyContent={'space-between'}
            display={'flex'}
            flexDirection={'column'}
          >
            <Box m="2">
              <Text color="dark" m="1" fontWeight={'bold'}>
                {title}
              </Text>
              <Text color="default" m="1" fontWeight={'500'}>
                {description?.length > 150 ? (
                  <>
                    {description.slice(0, isMobile ? 50 : 150)}
                    <Text as="strong" fontWeight={'bold'}>
                      ...{t('see_more')}.
                    </Text>
                  </>
                ) : (
                  description
                )}
              </Text>
            </Box>
            <Box
              display={'flex'}
              justifyContent={'flex-start'}
              fontSize={'lg'}
              m="1"
            >
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                mr={'3'}
              >
                <Icon as={User} color="default" />
                <Text ml={'2'} fontWeight={'600'} color="default">
                  {likeCount}
                </Text>
              </Box>
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                mr={'3'}
              >
                <Icon as={CommentOutline} color="default" />
                <Text ml={'2'} fontWeight={'600'} color="default">
                  {commentCount}
                </Text>
              </Box>
            </Box>
            {/*<Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              {isOnline === true ? (
                <Text ml={'2'} fontWeight={'300'} color="default">
                  Online
                </Text>
              ) : (
                <Text ml={'2'} fontWeight={'300'} color="default">
                  Yüzyüze
                </Text>
              )}

              {price ? (
                <Text ml={'2'} fontWeight={'300'} color="default">
                  {price}
                </Text>
              ) : (
                <Text ml={'2'} fontWeight={'300'} color="default">
                  Ücretsiz
                </Text>
              )}

              {city ? (
                <Text ml={'2'} fontWeight={'300'} color="default">
                  {city}
                </Text>
              ) : null}
              </Box>*/}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PostCard;
