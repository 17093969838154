import React from 'react';
import { Box } from '@chakra-ui/react';

const SectionsWrapper = props => {
  return (
    <Box
      display={'flex'}
      flex={1}
      flexDirection={{ base: 'column', sm: 'column', md: 'column', lg: 'row' }}
      p={{ base: '2', sm: '4', md: 0 }}
      //pl={{ md: 3 }}
      justifyContent={'space-between'}
      {...props}
    />
  );
};

export default SectionsWrapper;
