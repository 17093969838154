/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PaymentsService from '../services/PaymentsService';
import toast from '../helpers/toast';
import useWindowDimensions from '../hooks/useWindowDimensions';
import Iframe from 'react-iframe';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Box,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const PaymentModal = ({ eventId, visible = false, onClose }) => {
  const { t } = useTranslation();
  const [pageUrl, setPageUrl] = useState(null);
  const [callbackUrl, setCallbackUrl] = useState(null);
  const [token, setToken] = useState(null);
  const { height } = useWindowDimensions();

  useEffect(() => {
    if (visible) initiatePayment();
  }, [visible]);

  const initiatePayment = async () => {
    const [data] = await PaymentsService.initiatePayment({ eventId });
    if (data) {
      setPageUrl(data.paymentUrl);
      setCallbackUrl(data.callbackUrl);
      setToken(data.token);
    }
  };

  const onNavigationStateChange = async e => {
    const {
      target: { src },
    } = e;

    if (src.includes('panoyabak.com')) {
      const [result] = await PaymentsService.resultPayment({ token });
      if (result) {
        // Success
        toast({
          title: t('payment_success'),
          status: 'success',
        });
        onClose();
      } else {
        toast({
          title: t('payment_failed_desc'),
          status: 'danger',
        });
        onClose();
      }
    }
  };

  return (
    <Modal onClose={onClose} isOpen={visible}>
      <ModalOverlay />
      <ModalContent maxWidth={'100vw'} width={'80vw'} height={'80vh'}>
        <ModalHeader>{t('pay_with_iyzico')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            {visible && pageUrl ? (
              <Iframe
                title={t('payment_form')}
                src={pageUrl}
                onLoad={onNavigationStateChange}
                width={'100%'}
                height={height * 0.7 + 'px'}
              />
            ) : null}
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PaymentModal;
