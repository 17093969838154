import React, { useEffect, useState } from 'react';
import { Avatar, Button, Text, Box } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import {
  MainLayout,
  MainSection,
  SecondarySection,
  SectionsWrapper,
  Spinner,
} from '../../components';
import { routes } from '../../constants/routes';
import toast from '../../helpers/toast';
import useMainStore from '../../hooks/useMainStore';
import { UserService } from '../../services';
import { useTranslation } from 'react-i18next';

const ClubMembers = () => {
  const { t } = useTranslation();
  const {
    data: { user },
  } = useMainStore();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    const [list, error] = await UserService.getClubApplications();
    if (list) setData(list);
    if (error) toast({ description: error, status: 'warning' });
    setLoading(false);
  };

  const onProfile = username => {
    if (user?.username === username) {
      navigate(routes.profile.path);
    } else {
      navigate(routes.user.getPath(username));
    }
  };

  const onUpdateApplication = async (userId, status) => {
    const [result, error] = await UserService.updateClubApplication({
      userId,
      status,
    });
    if (result) {
      toast({ description: t('success'), status: 'info' });
      const index = data.findIndex(item => userId === item.userId);
      if (index !== -1) {
        data[index] = { ...data[index], status };
        setData([...data]);
      }
    }
    if (error)
      toast({ title: t('an_error'), description: error, status: 'info' });
  };

  return (
    <MainLayout>
      <SectionsWrapper>
        <MainSection>
          {loading && <Spinner />}
          <Text mt="3" color={'primary'} fontSize="xl" fontWeight={'bold'}>
            {t('club_members')}
          </Text>
          <Box
            display={'flex'}
            flexDirection="row"
            justifyContent={'space-evenly'}
            fontWeight="500"
            fontSize={'large'}
            my="3"
          >
            <Text>
              {t('approved')}:{' '}
              <Text as="span" fontWeight={'bold'} color="primary">
                {data.filter(({ status }) => String(status) === '1').length}
              </Text>
            </Text>
            <Text>
              {t('pending')}:{' '}
              <Text as="span" fontWeight={'bold'} color="primary">
                {data.filter(({ status }) => String(status) === '0').length}
              </Text>
            </Text>
            <Text>
              {t('rejected')}:{' '}
              <Text as="span" fontWeight={'bold'} color="primary">
                {data.filter(({ status }) => String(status) === '-1').length}
              </Text>
            </Text>
            <Text>
              {t('total')}:{' '}
              <Text as="span" fontWeight={'bold'} color="primary">
                {data.length}
              </Text>
            </Text>
          </Box>
          <Box display={'flex'} flexDirection="column" flex="1">
            {data?.map?.((item, index) => {
              const user = item?.user;
              return (
                <Box
                  key={index.toString()}
                  display={'flex'}
                  flexDirection="row"
                  justifyContent={'space-between'}
                  m="2"
                  p={'5'}
                  bgColor="light"
                  borderRadius={'md'}
                  alignItems="center"
                >
                  <Box
                    cursor="pointer"
                    onClick={() => onProfile(user.username)}
                    display={'flex'}
                    alignItems="center"
                  >
                    <Avatar size={'md'} src={user?.avatar} />
                    <Text mx="3">{user?.username}</Text>
                  </Box>
                  <Box>
                    {String(item?.status) === '0' ? (
                      <>
                        <Button
                          backgroundColor={'green'}
                          color="white"
                          onClick={() => onUpdateApplication(item.userId, 1)}
                          mr="2"
                        >
                          {t('approve')}
                        </Button>
                        <Button
                          onClick={() => onUpdateApplication(item.userId, -1)}
                          backgroundColor={'red'}
                          color="white"
                          title="Reddet"
                        >
                          {t('reject')}
                        </Button>
                      </>
                    ) : null}
                    {String(item?.status) === '1' ? (
                      <Text color="green"> {t('approved')}</Text>
                    ) : null}
                    {String(item?.status) === '-1' ? (
                      <Text color="red"> {t('rejected')}</Text>
                    ) : null}
                  </Box>
                </Box>
              );
            })}
          </Box>
        </MainSection>
        <SecondarySection>
          <Button
            onClick={() => navigate(routes.clubDashboard.path)}
            m="3"
            backgroundColor="primary"
            color="white"
          >
            {t('back_to_club_dashboard')}
          </Button>
        </SecondarySection>
      </SectionsWrapper>
    </MainLayout>
  );
};

export default ClubMembers;
