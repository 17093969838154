/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from 'react';
import { MainLayout, Spinner } from '../../components';
import useMainStore from '../../hooks/useMainStore';
import useMessages from '../../hooks/useMessages';
import { UserService } from '../../services';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Text,
  InputGroup,
  Input,
  Button,
  Icon,
  Avatar,
} from '@chakra-ui/react';
import { Back, Send } from '../../components/icons';
import { routes } from '../../constants/routes';
import { useTranslation } from 'react-i18next';

const Chat = () => {
  const { t } = useTranslation();
  const { userId, roomId } = useParams();
  const {
    data: { user: currentUser },
  } = useMainStore();
  const {
    messages: messagesData,
    sendMessage,
    loadMore,
    isEndOfList,
    loading,
  } = useMessages(roomId, userId);
  const [messages, setMessages] = useState([]);
  const [userData, setUserData] = useState({});
  const [text, setText] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    setMessages(messagesData);
  }, [messagesData]);

  const getUserData = async () => {
    const [data] = await UserService.getUser(userId);
    data && setUserData(data);
  };

  const items = useMemo(() => {
    return (
      messagesData.map(message => {
        const sender =
          message?.senderId?.toString?.() === currentUser?.id?.toString()
            ? currentUser
            : userData;
        return {
          _id: message?.id,
          text: message?.message,
          createdAt: new Date(message?.date),
          user: {
            _id: sender.id,
            name: `${sender?.username}`,
            avatar: sender?.avatar,
          },
        };
      }) || []
    );
  }, [messages]);

  const onLoadMore = async () => {
    if (messages.length > 0) loadMore(messages[messages.length - 1].date);
  };

  const onSendMessage = message => {
    sendMessage(message.text);
    UserService.sendMessage({ message: message.text, receiverId: userData.id });
  };

  const onSend = async (messages = []) => {
    const val = text.trim();
    if (val) {
      onSendMessage({ text: val });
      setText('');
    }
  };

  const onUser = () => {
    navigate(routes.user.getPath(userData.username));
  };

  return (
    <MainLayout>
      <Box>
        <Box
          display={'flex'}
          flex="0"
          justifyContent={'space-between'}
          alignItems={'center'}
          p="2"
        >
          <Button
            onClick={() => navigate(-1)}
            backgroundColor={'light'}
            variant={'ghost'}
            borderRadius={'full'}
          >
            <Icon width={'unset'} as={Back} color="primary" />
          </Button>
          <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'center'}
            cursor={'pointer'}
            onClick={onUser}
          >
            <Avatar src={userData?.avatar} size="md" />
            <Text textAlign={'center'} fontSize={'sm'} fontWeight={'600'}>
              {userData?.name || userData?.username}
            </Text>
          </Box>
        </Box>
        <Box
          flex={1}
          height="70vh"
          flexDirection={'column-reverse'}
          display={'flex'}
          overflowY={'scroll'}
        >
          {items.map(item => {
            const isMe = item.user._id === currentUser.id;
            return (
              <Box
                key={item._id}
                display={'flex'}
                justifyContent={isMe ? 'flex-end' : 'flex-start'}
                alignItems={'center'}
              >
                {!isMe && <Avatar mx="1" src={item.user.avatar} size={'sm'} />}
                <Box
                  p={'2'}
                  px="4"
                  minW={'40px'}
                  my="1"
                  borderRadius={'full'}
                  borderBottomLeftRadius={!isMe ? 0 : 'undefined'}
                  borderBottomRightRadius={isMe ? 0 : 'undefined'}
                  backgroundColor={isMe ? 'primary' : 'light'}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Text color={isMe ? 'white' : 'default'}>{item.text}</Text>
                </Box>
                {isMe && <Avatar mx="1" src={item.user.avatar} size={'sm'} />}
              </Box>
            );
          })}
          {!isEndOfList && (
            <Text
              cursor={'pointer'}
              color="primary"
              fontWeight={'600'}
              onClick={onLoadMore}
              textAlign={'center'}
            >
              {t('load_more')}
            </Text>
          )}
          {loading && <Spinner />}
        </Box>
        <InputGroup marginX={'auto'} backgroundColor="white" mt={'4'}>
          <Input
            type={'text'}
            placeholder="Bir şeyler yazın"
            fontWeight={'500'}
            borderWidth={2}
            borderColor={'gray'}
            borderRadius={'xl'}
            mx="1"
            value={text}
            onChange={e => setText(e.target.value)}
          />
          <Button
            borderRadius={'full'}
            backgroundColor={'primary'}
            onClick={onSend}
            p="1"
          >
            <Icon as={Send} color={'white'} />
          </Button>
        </InputGroup>
      </Box>
    </MainLayout>
  );
};

export default Chat;
