import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SET_AUTH, SET_USER } from '../store/actionTypes';
import Fallback from './Fallback';
import useMainStore from '../hooks/useMainStore';
import { AuthService, UserService } from '../services';
import { routes } from '../constants/routes';
import { isSecuredRoute } from '../helpers';

const withSplashScreen = Component => props => {
  const { dispatch, resetAuth, initData } = useMainStore();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const token = AuthService.getToken();

  const { search: searchParams, pathname: currentRoute } = window.location;

  useEffect(() => {
    if (token) {
      initialize();
    } else {
      setLoading(false);
      const isSecured = isSecuredRoute(currentRoute);
      go(isSecured ? routes.explore.path : currentRoute);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const go = (path, search = '') => {
    navigate(path + (search || searchParams));
  };

  const next = () => go(currentRoute, searchParams);

  const initialize = async () => {
    try {
      AuthService.setAuthorizationToken(token);
      const [data] = await UserService.getMe();
      if (data) {
        dispatch({
          type: SET_AUTH,
          payload: true,
        });
        dispatch({
          type: SET_USER,
          payload: data,
        });
        initData(data);
        AuthService.setToken(token);
        AuthService.setAuthorizationToken(token);
        setLoading(false);
        next();
      } else {
        setLoading(false);
        resetAuth();
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      resetAuth();
    }
  };

  if (loading) return <Fallback />;
  else return <Component {...props} />;
};

export default withSplashScreen;
