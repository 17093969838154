/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createContext,
  useReducer,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import mainReducer from '../reducers/mainReducer';
import { INIT_DATA, SET_USER, SET_AUTH, SET_SHARE_POST } from '../actionTypes';
import { AuthService, UserService } from '../../services';
import toast from '../../helpers/toast';
import FirebaseChat from '@metehankurucu/firebase-chat';
import { useTranslation } from 'react-i18next';

const modRoles = [
  'ROLE_ADMIN',
  'ROLE_MODERATOR',
  'ROLE_NON-GOVERNMENTAL',
  'admin',
  'moderator',
  'non-governmental',
];

export const MainContext = createContext();

const initialState = {
  authenticated: false,
  user: {},
  sharePost: {
    image: { url: null, image: null },
    details: null,
    id: null,
  },
};

const MainContextProvider = ({ children }) => {
  const [data, dispatch] = useReducer(mainReducer, initialState);
  const { t } = useTranslation();

  useEffect(() => {
    initalizeFirebaseChat();
  }, []);

  useEffect(() => {
    if (data?.user?.id) {
      if (!FirebaseChat.isInitialized) {
        initalizeFirebaseChat();
      }
      FirebaseChat.setUser(data.user.id);
    }
  }, [data.user]);

  const initalizeFirebaseChat = async () => {
    const credentials = {
      apiKey: 'AIzaSyA91gRsTSnAwJCNwonj50xcgt7q89fLqMQ',
      authDomain: 'panoyabak-4ff9c.firebaseapp.com',
      projectId: 'panoyabak-4ff9c',
      storageBucket: 'panoyabak-4ff9c.appspot.com',
      messagingSenderId: '381841226366',
      appId: '1:381841226366:web:ba3dd935710b776edd362f',
      measurementId: 'G-DCXJ9ZENH2',
    };
    if (!FirebaseChat.isInitialized) FirebaseChat.initialize({}, credentials);
  };

  const resetAuth = () => {
    AuthService.setToken('');
    AuthService.setAuthorizationToken('');
    dispatch({ type: SET_USER, payload: {} });
    dispatch({ type: SET_AUTH, payload: false });
  };

  const initData = data => {
    dispatch({ type: INIT_DATA, payload: data });
  };

  const setUser = data => {
    dispatch({ type: SET_USER, payload: data });
  };

  const updateUser = obj => {
    dispatch({ type: SET_USER, payload: { ...data.user, ...obj } });
  };

  const updateSharePost = obj => {
    dispatch({ type: SET_SHARE_POST, payload: { ...data.sharePost, ...obj } });
  };

  const resetSharePost = () => {
    dispatch({ type: SET_SHARE_POST, payload: initialState.sharePost });
  };

  const setAuth = data => {
    dispatch({ type: SET_AUTH, payload: data });
  };

  const isUserMod = useCallback((roles = []) => {
    if (roles.length > 0 && typeof roles[0] === 'string')
      return (
        roles?.includes('ROLE_ADMIN') ||
        roles?.includes('ROLE_MODERATOR') ||
        roles?.includes('ROLE_NON-GOVERNMENTAL')
      );
    else {
      return roles.some(item => modRoles.includes(item?.name?.toLowerCase()));
    }
  }, []);

  const isMod = useMemo(() => {
    return isUserMod(data?.user?.roles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.user]);

  const isLoggedIn = useMemo(() => {
    return data.authenticated;
  }, [data.user, data.authenticated]);

  const blockUser = async userId => {
    if (data?.user?.id === userId) return;
    const [result] = await UserService.block(userId);
    if (result.code === 200) {
      toast({ title: t('user_blocked'), status: 'info' });
    }
  };

  const reportUser = async userId => {
    toast({ title: t('reported'), status: 'info' });
  };

  const onShare = post => {
    const url = `panoyabak.com/etkinlikler/${post.slug}`;
    navigator?.clipboard?.writeText?.(url);
    toast({ title: t('event_link_copied'), status: 'success' });
  };

  return (
    <MainContext.Provider
      value={{
        data,
        dispatch,
        resetAuth,
        initData,
        setUser,
        setAuth,
        isUserMod,
        isMod,
        updateUser,
        updateSharePost,
        resetSharePost,
        isLoggedIn,
        blockUser,
        onShare,
        reportUser,
      }}
    >
      {children}
    </MainContext.Provider>
  );
};

MainContextProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export default MainContextProvider;
