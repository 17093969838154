/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useMemo, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Text,
  Image,
  useDisclosure,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuGroup,
  MenuDivider,
} from '@chakra-ui/react';
import { useQuery } from 'react-query';
import {
  MainLayout,
  MainSection,
  RecommendedUsers,
  SecondarySection,
  Footer,
  UserBoard,
  UserPostBoard,
  TabLine,
  AlertModal,
  FollowListModal,
  EditProfileModal,
  SectionsWrapper,
} from '../components';
import { ProfileEvent, UserPost /*Certificate*/ } from '../components/icons';
import useMainStore from '../hooks/useMainStore';
import { UserService } from '../services';
import { useNavigate } from 'react-router-dom';
import { routes } from '../constants/routes';
import toast from '../helpers/toast';
import ProfileTmp from '../assets/images/ProfileTmp.png';
import {
  communityBadge,
  speakerBadge,
  govBadge,
  stkBadge,
} from '../assets/images/badge';
import { Action, checkPermission } from '../constants/roles';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import ChangeLanguage from '../components/ChangeLanguage';

const tabs = {
  posts: 'posts',
  userPosts: 'userPosts',
  certificates: 'certificates',
};

const Profile = () => {
  const { t } = useTranslation();
  const {
    isMod,
    data: { user },
    resetAuth,
  } = useMainStore();
  const id = user?.id;
  const { avatar, name, username, biyografi } = user;
  const [tab, setTab] = useState(tabs.posts);
  const logoutModal = useDisclosure();
  const followersModal = useDisclosure();
  const followsModal = useDisclosure();
  const editModal = useDisclosure();
  const deleteModal = useDisclosure();
  const navigate = useNavigate();

  const { data: feedData } = useQuery(['profileFeed', id], () =>
    isMod ? UserService.getModFeed(id) : UserService.getUserFeed(id)
  );

  const { data: userPost } = useQuery(['userPost', id], () =>
    UserService.getProfileUserPosts(id)
  );

  const { data: followersData } = useQuery(['profileFollowers', id], () =>
    UserService.getFollowers(id)
  );
  const { data: followData } = useQuery(['profileFollows', id], () =>
    UserService.getFollowList(id)
  );

  const [isDeleting, setIsDeleting] = useState(false);

  const posts = useMemo(() => {
    return feedData && feedData.length > 0 ? feedData[0] : [] || [];
  }, [feedData]);

  const userPosts = useMemo(() => {
    return userPost && userPost.length > 0 ? userPost[0] : [] || [];
  }, [userPost]);

  const followers = useMemo(() => {
    return followersData && followersData.length > 0
      ? followersData[0]
      : [] || [];
  }, [followersData]);

  const follows = useMemo(() => {
    return followData && followData.length > 0 ? followData[0] : [] || [];
  }, [followData]);

  const onLogout = async () => {
    await resetAuth();
  };

  const onDeleteAccount = async () => {
    setIsDeleting(true);
    const [data, error] = await UserService.deleteUser();
    setIsDeleting(false);
    if (data.result) {
      toast({ title: t('account_deleted'), status: 'success' });
      resetAuth();
    } else {
      toast({ title: data?.message || error, status: 'warning' });
    }
  };

  const showClubMenu = checkPermission(user?.roles, Action.ShowClubMenu);

  //show badge
  const showSpeaker = checkPermission(user?.roles, Action.ShowSpeaker);
  const showCommunity = checkPermission(user?.roles, Action.showCommunity);
  const showGoverment = checkPermission(user?.roles, Action.showGoverment);
  const showNonGoverment = checkPermission(
    user?.roles,
    Action.showNonGoverment
  );

  const isMobile = useMediaQuery({ query: '(max-width: 786px)' });

  return (
    <MainLayout>
      <SectionsWrapper>
        <MainSection>
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            mt={isMobile ? '1' : '3'}
            p={isMobile ? '1' : '5'}
          >
            <Avatar
              size={isMobile ? 'xl' : '2xl'}
              src={avatar}
              name={name || username}
              alt={t('profile_photo')}
            />
            <Box
              display={'flex'}
              flex="1"
              flexDirection={'column'}
              alignItems={'flex-start'}
              justifyContent={'center'}
              pl={'5'}
            >
              <Text mb={'2'} fontWeight={'600'} fontSize="md">
                {username}
                {name ? ' - ' + name : ''}
              </Text>
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'flex-start'}
                color="default"
                mb="2"
              >
                <Button
                  size="md"
                  variant={'ghost'}
                  pl="0"
                  _focus={{ boxShadow: 'none' }}
                  onClick={followsModal.onOpen}
                >
                  {follows?.length} {t('following')}
                </Button>
                <Button
                  size="md"
                  variant={'ghost'}
                  pl="0"
                  _focus={{ boxShadow: 'none' }}
                  onClick={followersModal.onOpen}
                >
                  {followers?.length} {t('followers')}
                </Button>
              </Box>
              <Box>
                <Button
                  colorScheme="blue"
                  variant={'outline'}
                  color="primary"
                  size="sm"
                  borderWidth={1}
                  onClick={editModal.onOpen}
                >
                  {t('edit_profile')}
                </Button>
                {showClubMenu ? (
                  <Button
                    colorScheme="blue"
                    variant={'outline'}
                    color="primary"
                    size="sm"
                    ml="2"
                    borderWidth={1}
                    onClick={() => navigate(routes.clubDashboard.path)}
                  >
                    {t('club_dashboard')}
                  </Button>
                ) : null}
              </Box>
            </Box>
          </Box>
          <Box display={'flex'} ml={'2'} mb={'2'}>
            {showCommunity ? (
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                borderRadius={10}
                borderWidth={1}
                borderColor={'#194a94'}
                p={'1'}
              >
                <Icon fontSize="2xl" as={communityBadge} />
                <Text
                  ml={'1'}
                  fontWeight={'300'}
                  fontSize="sm"
                  color={'#194a94'}
                >
                  {t('community')}
                </Text>
              </Box>
            ) : null}
            {showSpeaker ? (
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                borderRadius={10}
                borderWidth={1}
                borderColor={'#b72020'}
                p={'1'}
              >
                <Icon fontSize="2xl" as={speakerBadge} />
                <Text
                  ml={'1'}
                  fontWeight={'300'}
                  fontSize="sm"
                  color={'#b72020'}
                >
                  {t('speaker')}
                </Text>
              </Box>
            ) : null}
            {showGoverment ? (
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                borderRadius={10}
                borderWidth={1}
                borderColor={'#366472'}
                p={'1'}
              >
                <Icon fontSize="2xl" as={govBadge} />
                <Text
                  ml={'1'}
                  fontWeight={'300'}
                  fontSize="sm"
                  color={'#366472'}
                >
                  {t('government')}
                </Text>
              </Box>
            ) : null}
            {showNonGoverment ? (
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                borderRadius={10}
                borderWidth={1}
                borderColor={'#5c2d1f'}
                p={'1'}
              >
                <Icon fontSize="2xl" as={stkBadge} />
                <Text
                  ml={'1'}
                  fontWeight={'300'}
                  fontSize="sm"
                  color={'#5c2d1f'}
                >
                  {t('non_government')}
                </Text>
              </Box>
            ) : null}
          </Box>
          {biyografi ? (
            <Text ml={'2'} mb={'1'} fontWeight={'300'} fontSize="sm">
              {biyografi}
            </Text>
          ) : null}
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'flex-start'}
            mt={'2'}
            fontWeight={'600'}
            fontSize={'md'}
            color="default"
          >
            <Box
              px="2"
              cursor={'pointer'}
              mr="5"
              onClick={() => setTab(tabs.posts)}
            >
              <Box display={'flex'} alignItems={'center'}>
                <Icon mr={'2'} fontSize="2xl" as={ProfileEvent} />
                <Text>{isMod ? t('shared_events') : t('joined_events')}</Text>
              </Box>
              <TabLine isActive={tab === tabs.posts} />
            </Box>
            <Box
              px="2"
              cursor={'pointer'}
              mr="2"
              onClick={() => setTab(tabs.userPosts)}
            >
              <Box display={'flex'} alignItems={'center'}>
                <Icon mr={'2'} fontSize="2xl" as={UserPost} />
                <Text>{t('posts')}</Text>
              </Box>
              <TabLine isActive={tab === tabs.userPosts} />
            </Box>
            {/* <Box
              px="2"
              cursor={'pointer'}
              mr="2"
              onClick={() => setTab(tabs.certificates)}
            >
              <Text>Sertifikalar</Text>
              <TabLine isActive={tab === tabs.certificates} />
            </Box> */}
          </Box>
          {tab === tabs.posts ? (
            <>
              {posts.length > 0 ? (
                <UserBoard showAvatar={false} posts={posts} />
              ) : (
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  alignItems={'center'}
                >
                  <Image boxSize="200px" src={ProfileTmp} />
                  <Text>{t('no_posts_yet')}</Text>
                  <Text>
                    {isMod
                      ? t('shared_events_shows_here')
                      : t('joined_events_shows_here')}
                  </Text>
                </Box>
              )}
            </>
          ) : null}
          {tab === tabs.userPosts ? (
            <>
              {userPosts.length > 0 ? (
                <>
                  <UserPostBoard userPosts={userPosts} />
                </>
              ) : (
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  alignItems={'center'}
                >
                  <Image boxSize="200px" src={ProfileTmp} />
                  <Text>{t('no_posts_yet')}</Text>
                  <Text>{t('shared_photos_shows_here')}</Text>
                </Box>
              )}
            </>
          ) : null}
          {tab === tabs.certificates ? <></> : null}
        </MainSection>
        <SecondarySection>
          <RecommendedUsers />
          <Footer />
          <Box
            backgroundColor={'light'}
            p={'5'}
            m={'3'}
            borderRadius={'lg'}
            flexDirection="row"
            display={'flex'}
            justifyContent={'space-between'}
            alignItems="flex-start"
          >
            <Menu>
              <MenuButton as={Button} variant="outline" colorScheme="blue">
                {t('settings')}
              </MenuButton>
              <MenuList>
                {showClubMenu ? (
                  <MenuGroup title="Profil">
                    <MenuItem
                      onClick={() => navigate(routes.clubDashboard.path)}
                      color={'blue'}
                    >
                      {t('club_dashboard')}
                    </MenuItem>
                    <MenuItem
                      onClick={() => navigate(routes.api.path)}
                      color={'blue'}
                    >
                      {t('ticketing_system')}
                    </MenuItem>
                  </MenuGroup>
                ) : null}
                <MenuDivider />
                <MenuGroup title="Hesabım">
                  <MenuItem onClick={logoutModal.onOpen} color={'dark'}>
                    {t('logout')}
                  </MenuItem>
                  <MenuItem onClick={deleteModal.onOpen} color={'red'}>
                    {t('delete_account')}
                  </MenuItem>
                </MenuGroup>
              </MenuList>
            </Menu>
            <ChangeLanguage />
          </Box>
        </SecondarySection>
      </SectionsWrapper>
      <AlertModal
        isOpen={logoutModal.isOpen}
        onClose={logoutModal.onClose}
        onContinue={onLogout}
        continueText={t('logout')}
        description={t('logout_desc')}
      />
      <AlertModal
        isOpen={deleteModal.isOpen}
        onClose={deleteModal.onClose}
        onContinue={onDeleteAccount}
        continueText={t('delete_account')}
        description={t('delete_account_desc')}
        continueButtonProps={{ isDisabled: isDeleting, isLoading: isDeleting }}
      />
      <FollowListModal
        title={t('followers')}
        onClose={followersModal.onClose}
        isOpen={followersModal.isOpen}
        type="followers"
        userId={id}
        users={followers}
      />
      <FollowListModal
        title={t('following')}
        onClose={followsModal.onClose}
        isOpen={followsModal.isOpen}
        userId={id}
        type="followees"
        users={follows}
      />
      <EditProfileModal onClose={editModal.onClose} isOpen={editModal.isOpen} />
    </MainLayout>
  );
};

export default Profile;
