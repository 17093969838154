import { useTranslation } from 'react-i18next';
import { detectLanguagePreference } from '../language';

const useLanguage = () => {
  const { i18n } = useTranslation();

  const setLanguagePreference = async userLanguage => {
    const { language, needUpdate } = detectLanguagePreference(
      userLanguage,
      i18n.language
    );
    if (needUpdate) {
      await i18n.changeLanguage(language);
      localStorage.setItem('user-lang', language);
    }

    return language;
  };

  return { setLanguagePreference };
};

export default useLanguage;
