import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Box,
  ModalFooter,
  Button,
  Text,
} from '@chakra-ui/react';
import useGoogleMaps from '../../hooks/useGoogleMaps';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import MapPicker from 'react-google-map-picker';
import {
  getCoordinatesFromPlace,
  getLocationFromCoordinates,
} from '../../helpers/location';
import { useTranslation } from 'react-i18next';

const defaultLocation = { lat: 41.0391683, lng: 28.9982707 };

const LocationSelectModal = ({ onCloseModal, isOpen, onSelect = () => {} }) => {
  const { t } = useTranslation();
  const google = useGoogleMaps();
  const [value, setValue] = useState(null);
  const [coordinates, setCoordinates] = useState(defaultLocation);
  const [location, setLocation] = useState({ type: null, data: null });
  const [display, setDisplay] = useState('');

  const onChangeCoordinates = (lat, lng) => {
    setCoordinates({ lat, lng });
    setLocation({ type: 'coordinate', data: { lat, lng } });
    setValue(null);
  };

  const onChangePlace = val => {
    setValue(val);
    setLocation({ type: 'place', data: val.value });
  };

  useEffect(() => {
    setDisplayText(location);
  }, [location]);

  const setDisplayText = async locationData => {
    try {
      if (locationData.type === 'coordinate') {
        const response = await getLocationFromCoordinates({
          latitude: locationData.data.lat,
          longitude: locationData.data.lng,
        });
        setDisplay(response);
      } else if (locationData.type === 'place') {
        setDisplay(locationData.data.description);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onContinue = async () => {
    let coords = { latitude: coordinates.lat, longitude: coordinates.lng };
    let display;
    let city;

    if (location.type === 'coordinate') {
      const response = await getLocationFromCoordinates({
        latitude: location.data.lat,
        longitude: location.data.lng,
      });
      display = response;
    } else if (location.type === 'place') {
      display = location.data.description;
      const placeCoords = await getCoordinatesFromPlace(
        location.data.description
      );
      if (placeCoords) {
        coords = placeCoords;
      }
    }

    // eslint-disable-next-line no-useless-escape
    const cityMatch = display.match(/\/.[^\/]+,/g);
    if (cityMatch && cityMatch.length > 0) {
      city = cityMatch[0].replace(/,/g, '').replace(/\//g, '');
    }

    onSelect({ coordinates: coords, display, city });
    onCloseModal();
  };

  return (
    <Modal size={'5xl'} onClose={onCloseModal} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent alignSelf={'center'} backgroundColor={'light'}>
        <ModalHeader>
          {t('select_location')}
          <Text color="primary" fontSize={'1rem'} fontWeight={'500'}>
            {t('selected_location')}:{' '}
            <Text as="span" color="dark">
              {location.data ? display : t('did_not_select')}
            </Text>
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {google && (
            <GooglePlacesAutocomplete
              selectProps={{
                value,
                onChange: onChangePlace,
                placeholder: t('enter_location'),
              }}
              apiOptions={{ language: 'tr', region: 'tr' }}
              apiKey={'AIzaSyDB9c1nPWNT-yU3Yk82Ru0gxDXB75BIgog'}
            />
          )}
          <Box height={'5'} />
          <MapPicker
            defaultLocation={defaultLocation}
            mapTypeId="roadmap"
            onChangeLocation={onChangeCoordinates}
            apiKey="AIzaSyDB9c1nPWNT-yU3Yk82Ru0gxDXB75BIgog"
          />
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={onContinue}
            isDisabled={!location.data}
            isFullWidth
            backgroundColor={'primary'}
            color="white"
          >
            {t('choose')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default LocationSelectModal;
