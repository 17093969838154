import * as React from 'react';

const SvgMenu = props => (
  <svg
    viewBox="0 0 5 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={2.065} cy={2.065} r={2.065} fill="currentColor" />
    <circle cx={2.065} cy={9.5} r={2.065} fill="currentColor" />
    <circle cx={2.065} cy={16.935} r={2.065} fill="currentColor" />
  </svg>
);

export default SvgMenu;
