import React from 'react';
import { Box } from '@chakra-ui/react';

const TabLine = ({ isActive }) => (
  <Box
    height={'3px'}
    width={'90%'}
    marginX={'auto'}
    backgroundColor={isActive ? 'primary' : 'transparent'}
    borderRadius={3.5}
  />
);

export default TabLine;
