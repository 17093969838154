export const MAIN_LAYOUT_MAX_WIDTH = { base: '100%', sm: '95%', md: '95%', lg: '85%' };

export const GOOGLE_MAPS_API_KEY = 'AIzaSyDUs2W_Z0Sfrs-hhYQ8IKzu5ygk2iIYpMA';

export const roles = {
  mod: 'mod',
  user: 'user',
  admin: 'admin',
  gov: 'gov',
  non_gov: 'non_gov',
};

export const BASE_URL = 'https://panoyabak.com';
