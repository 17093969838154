export const Role = {
    Admin: 'admin',
    Moderator: 'moderator',
    User: 'user',
    Speaker: 'speaker',
    Municipality: 'municipality',
    NonGovernmental: 'non-governmental',
};

export const Action = {
    ShowClubMenu: 'show-club-menu',
    ShowSpeaker: 'show-speaker',
    showCommunity: 'show-community',
    showGoverment: 'show-goverment',
    showNonGoverment: 'show-nongoverment',
    Share: 'share',
};

export const Permissions = {
    [Action.ShowClubMenu]: [Role.Admin, Role.Moderator, Role.NonGovernmental],
    [Action.ShowSpeaker]: [Role.Speaker],
    [Action.showCommunity]: [Role.Moderator],
    [Action.showGoverment]: [Role.Municipality],
    [Action.showNonGoverment]: [Role.NonGovernmental],
    [Action.Share]: [
        Role.Admin,
        Role.Moderator,
        Role.Municipality,
        Role.NonGovernmental,
    ],
};

export const checkPermission = (roles, action) => {
    if (!roles || !action) return false;

    const permissions = Permissions[action];

    if (!permissions) return false;

    return permissions.some(role => {
        if (typeof roles === 'string') {
            return roles.toLowerCase() === role.toLowerCase();
        } else if (Array.isArray(roles)) {
            return roles.some(item => {
                if (typeof item === 'string')
                    return item.toLowerCase() === role.toLowerCase();
                return item?.name?.toLowerCase() === role.toLowerCase();
            });
        }
        return false;
    });
};
