/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useMemo } from 'react';
import { Box, Divider, Heading, Text } from '@chakra-ui/react';
import { useQuery } from 'react-query';
import { Board, MainLayout, Spinner, UserCard } from '../components';
import { UserService } from '../services';
import { useTranslation } from 'react-i18next';

const Search = () => {
  const { t } = useTranslation();
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const query = params.get('q');

  const { data, isLoading, isError } = useQuery(['explore', query], () =>
    UserService.search(query)
  );

  const users = useMemo(() => {
    return data?.[0]?.users || [];
  }, [data]);

  const posts = useMemo(() => {
    return data?.[0]?.posts || [];
  }, [data]);

  return (
    <MainLayout>
      <Box py="5" px="3">
        {!query && <Text color="red">{t('enter_a_word')}</Text>}
        {isLoading && !isError ? (
          <Spinner />
        ) : (
          <>
            <Box py="2">
              <Heading fontSize={'xl'}>{t('events')}</Heading>
              {posts.length === 0 && (
                <Text color="red">{t('events_not_found')}</Text>
              )}
              <Board posts={posts} />
            </Box>
            <Divider color="gray" w="80%" marginX="auto" />
            <Box pt="3">
              <Heading fontSize={'xl'}>{t('users')}</Heading>
              {users.length === 0 && (
                <Text color="red">{t('users_not_found')}</Text>
              )}
              {users.map((user, index) => {
                return <UserCard key={index.toString()} user={user} />;
              })}
            </Box>
          </>
        )}
      </Box>
    </MainLayout>
  );
};

export default Search;
