import React from 'react';
import { Box, Link, Text, Image } from '@chakra-ui/react';
import { Logo } from '.';
import { Link as RLink } from 'react-router-dom';
import images from '../constants/images';
import { routes } from '../constants/routes';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();
  return (
    <Box backgroundColor={'light'} p={'5'} m={'3'} borderRadius={'lg'}>
      <Text color="default" opacity={0.6}>
        <Link as={RLink} to={routes.contact.path}>
          {t('contact')}
        </Link>{' '}
        •{' '}
        <Link as={RLink} to={routes.privacyPolicy.path}>
          {t('privacy_policy')}
        </Link>{' '}
        •{' '}
        <Link as={RLink} to={routes.terms.path}>
          {t('terms_of_use')}
        </Link>{' '}
        •{' '}
        <Link as={RLink} to={routes.cookie.path}>
          {t('cookie_policy')}
        </Link>{' '}
        •{' '}
        <Link as={RLink} to={routes.deliveryAndReturn.path}>
          {t('delivery_and_return')}
        </Link>{' '}
        •{' '}
        <Link href={'/mesafeli-satis-sozlesmesi.pdf'}>
          {t('sales_aggrement')}
        </Link>
      </Text>
      <Image
        src={images.delivery}
        mt="3"
        w={{ base: '50%', md: '35%', lg: '30%' }}
      />
      <Box
        display={'flex'}
        alignItems={'center'}
        pt={'5'}
        justifyContent={'space-between'}
      >
        <Logo width={150} color="default" />
        <Text color="default" fontSize="sm">
          {new Date().getFullYear()} • {t('all_rights_reserved')}
        </Text>
      </Box>
    </Box>
  );
};

export default Footer;
