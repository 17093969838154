import * as React from 'react';

const SvgHeartOutline = props => (
  <svg
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m10.5 19-1.377-1.295C4.23 13.125 1 10.103 1 6.395 1 3.374 3.299 1 6.225 1c1.653 0 3.24.795 4.275 2.05C11.536 1.795 13.122 1 14.775 1 17.701 1 20 3.374 20 6.395c0 3.708-3.23 6.73-8.123 11.32L10.5 19Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgHeartOutline;
