/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Box, Text, Image, Link, useBreakpointValue } from '@chakra-ui/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link as RLink } from 'react-router-dom';
import { routes } from '../../constants/routes';

import image1 from '../../assets/images/post/post1.jpg';
import image2 from '../../assets/images/post/post2.jpg';
import image3 from '../../assets/images/post/post3.jpg';
import image4 from '../../assets/images/post/post4.jpg';
import image5 from '../../assets/images/post/post5.jpg';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import { Pagination, EffectCoverflow } from 'swiper';
import { useTranslation } from 'react-i18next';

const AppInfo = () => {
  const { t } = useTranslation();
  const maxWidth = useBreakpointValue({ base: '90vw', md: '90vw' });

  const posts = [
    {
      name: 'post1',
      src: image1,
    },
    {
      name: 'post2',
      src: image3,
    },
    {
      name: 'post3',
      src: image2,
    },
    {
      name: 'post4',
      src: image4,
    },
    {
      name: 'post4',
      src: image5,
    },
  ];

  return (
    <Box marginY={10} display={'column'} justifyContent={'center'} p={'5'}>
      <Box style={{ maxWidth, margin: 'auto' }}>
        <Swiper
          effect={'coverflow'}
          slidesPerView={5}
          spaceBetween={30}
          initialSlide={2}
          centeredSlides={true}
          coverflowEffect={{
            rotate: 20,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          breakpoints={{
            320: { slidesPerView: 2, spaceBetween: 20 },
            480: { slidesPerView: 3, spaceBetween: 30 },
            640: { slidesPerView: 4, spaceBetween: 40 },
            768: { slidesPerView: 5, spaceBetween: 30 },
            1024: { slidesPerView: 5, spaceBetween: 30 },
          }}
          modules={[EffectCoverflow]}
        >
          {posts.map((slide, index) => {
            return (
              <SwiperSlide key={index}>
                <Image
                  flex={1}
                  w={'100%'}
                  src={slide.src}
                  alt={`Callsesh phone demonstration for ${slide.name}`}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Box>
      <Box
        py="10"
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
      >
        <Text fontWeight={'600'} fontSize={32}>
          {t('slogan')}
        </Text>
        <Text fontWeight={'600'}>{t('slogan_2')}</Text>
        <Text fontWeight={'600'} color="primary" mt="2">
          <Link
            as={RLink}
            to={routes.explore.path}
            textDecorationColor={'cyan.300'}
          >
            {t('explore')} {'->'}
          </Link>
        </Text>
      </Box>
    </Box>
  );
};

export default AppInfo;
