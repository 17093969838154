const axios = require('axios');

export const BASE_URL =
  process.env.REACT_APP_BASE_URL || 'https://api.panoyabak.com'; //"http://localhost:3000";

const API = axios.create({
  baseURL: BASE_URL,
});

export default API;
