import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Stat,
  StatLabel,
  StatNumber,
  Text,
} from '@chakra-ui/react';
import { useQuery } from 'react-query';
import {
  MainLayout,
  MainSection,
  SecondarySection,
  SectionsWrapper,
  Spinner,
} from '../../components';
import { routes } from '../../constants/routes';
import usePost from '../../hooks/usePost';
import { PostService } from '../../services';
import { useTranslation } from 'react-i18next';

const PostStatistics = () => {
  const { t } = useTranslation();
  const statItems = [
    {
      key: 'participatingPercent',
      label: t('participating_ratio'),
      onValue: data => {
        if (!data?.totalParticipants) return t('unknown');
        return (
          '%' +
          ((data?.joinedParticipants / data?.totalParticipants) * 100).toFixed(
            2
          )
        );
      },
    },
    {
      key: 'joinedParticipants',
      label: t('joined_persons'),
    },
    {
      key: 'totalParticipants',
      label: t('applied_persons'),
    },
    {
      key: 'comments',
      label: t('comments'),
    },
    {
      key: 'totalRatedCount',
      label: t('rated_persons'),
    },
    {
      key: 'rating',
      label: t('mean_rating'),
    },
  ];
  const { id } = useParams();
  const { isLoading, post } = usePost(id, {
    navigateWhenNotFound: true,
    navigateWhenNotMyPost: true,
  });
  const { data } = useQuery(['getPostStats', id], () =>
    PostService.getPostStats(id)
  );

  const stats = useMemo(() => {
    if (data?.[0]?.stats) return data?.[0]?.stats;
    return {};
  }, [data]);

  const navigate = useNavigate();

  return (
    <MainLayout>
      <SectionsWrapper>
        <MainSection>
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              <Text my="3" fontSize="xl" fontWeight={'bold'}>
                <Text as="strong" color={'primary'}>
                  {post?.title}
                </Text>{' '}
                {t('statistics')}
              </Text>
              <Box
                display="grid"
                gridGap={'4'}
                gridTemplateColumns={{
                  sm: '1fr',
                  md: '1fr 1fr',
                  lg: '1fr 1fr 1fr',
                }}
              >
                {statItems.map((item, index) => {
                  const value = item.onValue
                    ? item.onValue(stats)
                    : stats?.[item.key];
                  return (
                    <Stat
                      key={item.key}
                      flex={1}
                      backgroundColor={'light'}
                      padding="3"
                      borderRadius={'md'}
                    >
                      <StatLabel>{item.label}</StatLabel>
                      <StatNumber color="primary">{value}</StatNumber>
                    </Stat>
                  );
                })}
              </Box>
            </>
          )}
        </MainSection>
        <SecondarySection>
          <Button
            onClick={() => navigate(routes.postDashboard.getPath(id))}
            my="3"
            mx="1"
            backgroundColor="primary"
            color="white"
          >
            {t('back_to_event_dashboard')}
          </Button>
        </SecondarySection>
      </SectionsWrapper>
    </MainLayout>
  );
};

export default PostStatistics;
