import * as React from 'react';

const SvgExplore = props => (
  <svg
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M24.854 2.24 10.552 5.827c-2.089.518-4.206 2.636-4.724 4.724L2.24 24.854c-1.08 4.321 1.57 6.986 5.906 5.906l14.302-3.573c2.074-.518 4.206-2.65 4.724-4.724L30.76 8.146c1.08-4.321-1.585-6.986-5.906-5.906Z"
      stroke="currentColor"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.5 21.333a4.833 4.833 0 1 0 0-9.666 4.833 4.833 0 0 0 0 9.666Z"
      stroke="currentColor"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgExplore;
