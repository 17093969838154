import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Text, Textarea, useDisclosure } from '@chakra-ui/react';
import {
  AlertModal,
  MainLayout,
  MainSection,
  SecondarySection,
  SectionsWrapper,
  Spinner,
} from '../../components';
import { routes } from '../../constants/routes';
import toast from '../../helpers/toast';
import { PostService } from '../../services';
import usePost from '../../hooks/usePost';
import { useTranslation } from 'react-i18next';

const PostSendEmail = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { isLoading, post } = usePost(id, {
    navigateWhenNotMyPost: true,
  });
  const [content, setContent] = useState('');
  const [pending, setPending] = useState(false);
  const navigate = useNavigate();
  const modal = useDisclosure();

  const onSend = async () => {
    if (!content || !content.trim()) {
      return toast({ title: t('enter_message') });
    }
    try {
      setPending(true);
      const [data] = await PostService.sendEmailToParticipants({
        postId: id,
        content,
      });
      modal.onClose();
      if (data?.result) {
        toast({
          title: t('email_sent'),
          status: 'success',
        });
        setContent('');
        navigate(-1);
      } else {
        toast({ title: data?.message || t('an_error') });
      }
      setPending(false);
    } catch (error) {
      toast({ title: t('an_error') });
      console.log(error);
      setPending(false);
    }
  };

  return (
    <MainLayout>
      <SectionsWrapper>
        <MainSection>
          {isLoading ? (
            <Spinner />
          ) : (
            <Box>
              <Text mt="3" color={'primary'} fontSize="xl" fontWeight={'bold'}>
                {t('send_email')}
              </Text>
              <Box>
                <Text>
                  {t('send_email_desc_1')}
                  <strong>{post?.title}</strong>
                  {t('send_email_desc_2')}
                </Text>
                <Textarea
                  my="3"
                  noOfLines={10}
                  borderColor="primary"
                  minH={'30vh'}
                  value={content}
                  onChange={e => setContent(e.target.value)}
                />
                <Button
                  isFullWidth
                  my="2"
                  disabled={pending || !content || !content.trim()}
                  onClick={modal.onOpen}
                  backgroundColor="primary"
                  color="white"
                >
                  {t('send_email')}
                </Button>
              </Box>
            </Box>
          )}
        </MainSection>
        <SecondarySection>
          <Button
            onClick={() => navigate(routes.postDashboard.getPath(id))}
            my="3"
            mx="1"
            backgroundColor="primary"
            color="white"
          >
            {t('back_to_event_dashboard')}
          </Button>
        </SecondarySection>
      </SectionsWrapper>
      <AlertModal
        description={t('send_email_warning')}
        continueText={t('send')}
        isOpen={modal.isOpen}
        onClose={modal.onClose}
        continueButtonProps={{ isDisabled: pending, isLoading: pending }}
        onContinue={onSend}
      />
    </MainLayout>
  );
};

export default PostSendEmail;
