import React from 'react';
import {
  Box,
  Avatar,
  Text,
  Menu,
  MenuButton,
  Icon,
  MenuList,
  MenuItem,
  IconButton,
} from '@chakra-ui/react';
import { Menu as MenuIcon } from '../icons';

const MessageCard = ({
  user,
  lastMessage = '',
  onClick,
  onUser,
  onDelete = () => {},
  deleteText = 'Konuşmayı Sil',
}) => {
  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      my={'1'}
      cursor={'pointer'}
      onClick={onClick}
      bg={'light'}
      p="2"
      borderRadius={'lg'}
    >
      <Box pr={'2'} cursor={'pointer'} onClick={onUser}>
        <Avatar
          borderWidth={1}
          borderColor={'primary'}
          size={'md'}
          src={user?.avatar}
          name={user?.name || user?.username}
        />
      </Box>
      <Box display="flex" flex="1" flexDirection="column">
        <Text fontWeight={'600'} fontSize={'lg'} color="dark">
          {user?.name || user?.username}
        </Text>
        <Text>
          {lastMessage?.message?.length > 50
            ? lastMessage?.message.slice(0, 50) + '...'
            : lastMessage?.message}
        </Text>
      </Box>
      <Menu>
        <MenuButton
          borderWidth={0}
          as={IconButton}
          onClick={e => e.stopPropagation()}
          aria-label="Options"
          icon={<Icon as={MenuIcon} />}
          variant="outline"
        />
        <MenuList borderWidth={0}>
          <MenuItem
            onClick={e => {
              e.stopPropagation();
              onDelete();
            }}
          >
            {deleteText}
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};

export default MessageCard;
