/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Box, Button, Heading, Link, Text } from '@chakra-ui/react';
import { AuthLayout, PasswordInput, AuthInput } from '../components';
import { User } from '../components/icons';
import useForm from '../hooks/useForm';
import { AuthService } from '../services';
import useMainStore from '../hooks/useMainStore';
import toast, { showFormErrorsToast } from '../helpers/toast';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import * as yup from 'yup';
import { routes } from '../constants/routes';
import { useTranslation } from 'react-i18next';

const Login = () => {
  const { t } = useTranslation();
  const validation = yup.object({
    username: yup.string().required(t('enter_username')),
    password: yup.string().required(t('enter_password')),
  });
  const { values, setValues, errors, isValid } = useForm(
    {
      username: '',
      password: '',
    },
    validation
  );
  const { initData, setUser, setAuth } = useMainStore();
  const { mutateAsync, isLoading } = useMutation(AuthService.login);
  const navigate = useNavigate();

  const onSubmit = async () => {
    if (!isValid) {
      return showFormErrorsToast(errors, toast);
    }
    try {
      const [data] = await mutateAsync(values);
      if (data.accessToken) {
        setUser(data);
        setAuth(true);
        initData(data);
        AuthService.setToken(data.accessToken);
        AuthService.setAuthorizationToken(data.accessToken);
        toast({
          title: t('wellcome'),
          status: 'success',
        });
        navigate(routes.home.path);
      } else {
        toast({
          title: t('user_not_found'),
        });
      }
    } catch (error) {
      toast({
        title: t('user_not_found'),
      });
    }
  };

  return (
    <AuthLayout>
      <Box width={{ base: '100%', md: '80%', lg: '70%' }} mx="auto">
        <Heading my={'5'}>{t('wellcome')} 👋</Heading>
        <AuthInput
          Icon={User}
          placeholder={t('username')}
          name="username"
          onChange={setValues}
          value={values.username}
        />
        <PasswordInput
          name="password"
          onChange={setValues}
          value={values.password}
        />
        <Link
          onClick={() => navigate(routes.forgotPassword.path)}
          my="3"
          _hover={{ textDecoration: 'none' }}
        >
          <Text color="primary" textAlign={'right'} fontWeight={'600'}>
            {t('forgot_password')}
          </Text>
        </Link>
        <Button
          backgroundColor={'primary'}
          borderRadius={'full'}
          height={'65px'}
          isFullWidth
          my={'5'}
          onClick={onSubmit}
          isLoading={isLoading}
        >
          <Text color={'white'} fontWeight={'600'} fontSize={'1.3rem'}>
            {t('login')}
          </Text>
        </Button>
        <Text textAlign={'center'}>
          {t('no_account')}{' '}
          <Link
            _hover={{ textDecoration: 'none' }}
            color="primary"
            fontWeight={'600'}
            onClick={() => navigate(routes.register.path)}
          >
            {t('register')}
          </Link>
        </Text>
      </Box>
    </AuthLayout>
  );
};

export default Login;
