import API from '../api';
import endpoints from '../api/endpoints';

const getUserPost = async () => {
  const { data } = await API.get(endpoints.getUserPosts);
  return [data?.posts, null];
};
const getHomePosts = async ({ page = 1 }) => {
  const { data } = await API.get(endpoints.getHomeUserPosts, {
    params: { page },
  });
  return [data, null];
};

const getEventUserPosts = async postId => {
  const { data } = await API.get(endpoints.getEventUserPost(postId));
  return [data?.posts, null];
};

const createPost = async ({ userPostId, description, image }) => {
  const formData = new FormData();
  formData.append('description', description);
  formData.append('image', image);
  const { data } = await API.post(
    endpoints.createUserPost(userPostId),
    formData
  );
  return [data, null];
};

const likePost = async userPostId => {
  const { data } = await API.post(endpoints.likeUserPost(userPostId));
  return [data, null];
};

const unlikePost = async userPostId => {
  const { data } = await API.post(endpoints.unlikeUserPost(userPostId));
  return [data, null];
};

const createUserComment = async ({ userPostId, text }) => {
  const { data } = await API.post(endpoints.createUserComment(userPostId), {
    text,
  });
  return [data, null];
};

const deleteComment = async commentId => {
  const { data } = await API.delete(endpoints.deleteUserComment(commentId));
  return [data, null];
};

const updateUserPost = async (userPostId, form) => {
  const { data } = await API.put(endpoints.updateUserPost(userPostId), form);
  return [data, null];
};

const deleteUserPost = async userPostId => {
  const { data } = await API.delete(endpoints.deleteUserPost(userPostId));
  return [data, null];
};

const getUserPostLikes = async userPostId => {
  const { data } = await API.get(endpoints.getUserPostLikes(userPostId));
  return [data?.likes, null];
};

const getUserPostComment = async userPostId => {
  const { data } = await API.get(endpoints.getUserPostComments(userPostId));
  return [data?.comments, null];
};

const findUserPostById = async userPostId => {
  const { data } = await API.get(endpoints.findUserPostById(userPostId));
  return [data?.post, null];
};

const fetchUserPostLikes = async (postId, params) => {
  const { data } = await API.get(endpoints.fetchUserPostLikes(postId), {
    params,
  });
  return [data, null];
};

const UserPostService = {
  getUserPost,
  getEventUserPosts,
  createPost,
  likePost,
  unlikePost,
  createUserComment,
  deleteComment,
  updateUserPost,
  deleteUserPost,
  getUserPostLikes,
  getUserPostComment,
  findUserPostById,
  getHomePosts,
  fetchUserPostLikes,
};

export default UserPostService;
