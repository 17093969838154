import React from 'react';
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
} from '@chakra-ui/react';

const InputWrapper = ({
  labelProps = {},
  label,
  id,
  children,
  error = '',
  row,
  helperText = '',
  ...props
}) => {
  return (
    <FormControl
      borderWidth={1}
      borderColor={'#f1f1f1'}
      backgroundColor={'white'}
      p="2"
      borderRadius={'2xl'}
      my={'2'}
      isInvalid={error}
      {...props}
    >
      <Box
        display={'flex'}
        flexDirection={row ? 'row' : 'column'}
        justifyContent={'space-between'}
      >
        <FormLabel
          marginBottom={'0'}
          htmlFor={id}
          fontWeight={'600'}
          {...labelProps}
        >
          {label}
        </FormLabel>
        {children}
      </Box>
      <FormHelperText>{helperText}</FormHelperText>
      <FormErrorMessage marginTop={0}>{error}</FormErrorMessage>
    </FormControl>
  );
};

export default InputWrapper;
