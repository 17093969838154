import * as React from 'react';

const SvgSearch = props => (
  <svg
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.6 8.346c.366 4.097-2.689 7.707-6.84 8.05-4.153.343-7.805-2.713-8.172-6.81-.367-4.096 2.688-7.706 6.84-8.05 4.152-.342 7.804 2.714 8.171 6.81Z"
      stroke="currentColor"
      strokeWidth={1.7}
    />
    <rect
      width={2.27}
      height={7.106}
      rx={1.135}
      transform="matrix(.68626 -.72735 .73365 .67953 12.229 15.312)"
      fill="currentColor"
    />
  </svg>
);

export default SvgSearch;
