import React from 'react';
import { Box } from '@chakra-ui/react';
import { PostImageUserCard } from '.';

const UserBoard = ({ posts = [], showAvatar = true, ...props }) => {
  return (
    <Box
      //flex={1}
      display="grid"
      gridGap={'4'}
      gridTemplateColumns={{
        base: '1fr 1fr',
        sm: '1fr 1fr',
        md: '1fr 1fr 1fr',
        lg: '1fr 1fr 1fr 1fr',
      }}
      pt={'5'}
      px="3"
      {...props}
    >
      {posts.map?.(item => {
        return (
          <PostImageUserCard
            showAvatar={showAvatar}
            key={item?.id}
            avatar={item?.user}
            username={item?.user?.username}
            image={item?.image}
            userId={item?.user?.id || item?.userId}
            id={item?.id}
            slug={item.slug}
          />
        );
      })}
    </Box>
  );
};

export default UserBoard;
