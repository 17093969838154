import { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';

const useFetch = ({
  queryKey,
  fetchFn,
  select = data => data[0].results,
  params,
}) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMorePage, setHasMorePage] = useState(true);
  const query = useQuery({
    queryKey: [queryKey, page, params],
    queryFn: () => fetchFn({ page: page, ...params }),
    enabled: true,
    select,
  });
  const keepData = useRef(true);

  useEffect(() => {
    if (query.data) {
      if (query.data.length === 0) {
        setHasMorePage(false);
      } else {
        if (!keepData.current) {
          setData(query.data);
          keepData.current = true;
        } else {
          setData(prev => [...prev, ...query.data]);
        }
      }
    }
  }, [query.data]);

  useEffect(() => {
    keepData.current = false;
    setPage(1);
  }, [params]);

  const loadMore = () => {
    keepData.current = true;
    if (!hasMorePage) return;
    setPage(val => val + 1);
  };

  return {
    data,
    isLoading: query.isLoading,
    isLoadingMore: query.isLoading,
    page,
    loadMore,
    hasMore: hasMorePage,
    dataLength: data.length,
  };
};

export default useFetch;
