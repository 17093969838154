import * as React from 'react';

const SvgEye = props => (
  <svg
    viewBox="0 0 27 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M26 8.064s-3.75 7.064-12.5 7.064S1 8.064 1 8.064 4.75 1 13.5 1 26 8.064 26 8.064Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.5 11.596c2.071 0 3.75-1.582 3.75-3.532s-1.679-3.532-3.75-3.532c-2.071 0-3.75 1.581-3.75 3.532 0 1.95 1.679 3.532 3.75 3.532Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgEye;
