/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Box, Button, Heading, Text } from '@chakra-ui/react';
import { AuthLayout } from '../components';
import { AuthService } from '../services';
import toast from '../helpers/toast';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { routes } from '../constants/routes';
import { useTranslation } from 'react-i18next';

const EmailVerification = () => {
  const { t } = useTranslation();
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const key = params.get('key');

  const { mutateAsync, isLoading, isError } = useMutation(
    AuthService.verifyEmail
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (!key) {
      navigate(routes.login.path);
    } else {
      verifyEmail();
    }
  }, []);

  const verifyEmail = async () => {
    try {
      const [data] = await mutateAsync(key);
      toast({
        title: data.message || t('verification_success'),
        status: 'success',
      });
    } catch (error) {
      toast({
        title: error?.response?.data?.message || t('an_error'),
        status: 'warning',
      });
    }
  };

  return (
    <AuthLayout>
      <Box width={{ base: '100%', md: '80%', lg: '70%' }} mx="auto">
        <Heading my="5" fontSize={'2xl'} textAlign={'center'}>
          {isLoading
            ? t('email_verifying')
            : isError
            ? t('email_not_verified')
            : t('email_verified')}
        </Heading>
        <Button
          backgroundColor={'primary'}
          borderRadius={'full'}
          height={'65px'}
          isFullWidth
          my={'5'}
          onClick={() => navigate(routes.login.path)}
          isLoading={isLoading}
        >
          <Text color={'white'} fontWeight={'600'} fontSize={'1.3rem'}>
            {t('login')}
          </Text>
        </Button>
      </Box>
    </AuthLayout>
  );
};

export default EmailVerification;
