/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { Footer, MainLayout } from '../../components';
import { useTranslation } from 'react-i18next';

const Contact = () => {
  const { t } = useTranslation();
  return (
    <MainLayout>
      <Box minH={'70vh'}>
        <Box my="10">
          <Text fontWeight={'bold'} fontSize="3xl" textAlign={'center'}>
            {t('contact_with_us')}
          </Text>
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          fontSize={'20'}
        >
          <Box>
            <span>
              <strong>{t('on_all_platforms')}: </strong>
              <a href="https://linktr.ee/panoyabak" style={{ color: 'blue' }}>
                @panoyabak
              </a>
            </span>
          </Box>
          <Box>
            <span>
              <strong>{t('email')}:</strong> iletisim@panoyabak.com
            </span>
          </Box>
          <Box>
            <span>
              <strong>{t('phone')}</strong> +90 539 696 08 43
            </span>
          </Box>
        </Box>
      </Box>
      <Footer />
    </MainLayout>
  );
};

export default Contact;
