import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { availableLanguages } from '../language';
import { UserService } from '../services';
import useMainStore from './useMainStore';

const useChangeLanguage = () => {
  const { updateUser } = useMainStore();
  const { i18n } = useTranslation();
  const [pending, setPending] = useState(false);

  const change = async langCode => {
    try {
      if (
        !langCode ||
        !availableLanguages.some(item => item.code === langCode)
      ) {
        console.warn('Unsupported language code provided to useChangeLanguage');
        return;
      }

      setPending(true);

      await Promise.all([
        i18n.changeLanguage(langCode),
        UserService.updateUser({ language: langCode }),
      ]);

      updateUser({ language: langCode });
      localStorage.setItem('user-lang', langCode);

      setPending(false);
    } catch (error) {
      setPending(false);
    }
  };

  return { change, pending };
};

export default useChangeLanguage;
