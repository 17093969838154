/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useMemo, useState } from 'react';
import { Box, Button } from '@chakra-ui/react';
import { useQuery } from 'react-query';
import { Board, MainLayout, InfiniteScroll } from '../components';
import { defaultPostFilter } from '../constants/post';
import { PostService } from '../services';
import useFetch from '../hooks/useFetch';
import { useTranslation } from 'react-i18next';

const Explore = () => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState(null);

  const { data, ...props } = useFetch({
    queryKey: 'posts',
    fetchFn: PostService.getDiscoverPosts,
    params: filter,
  });

  const changeFilter = ({ key, value, toggleValue }) => {
    const newFilter = { ...filter };
    if (newFilter?.[key] === value) {
      return setFilter(obj => ({ ...obj, [key]: toggleValue }));
    }
    setFilter(obj => ({ ...obj, [key]: value }));
  };

  const filters = [
    {
      title: t('online'),
      key: 'type',
      value: 'online',
      toggleValue: 'all',
    },
    {
      title: t('face_to_face'),
      key: 'type',
      value: 'offline',
      toggleValue: 'all',
    },
    {
      title: 'İstanbul',
      key: 'city',
      value: 'İstanbul',
      toggleValue: '',
    },
    {
      title: 'Kıbrıs',
      key: 'city',
      value: 'Kıbrıs',
      toggleValue: '',
    },
    {
      title: 'Sakarya',
      key: 'city',
      value: 'Sakarya',
      toggleValue: '',
    },
    {
      title: 'Bursa',
      key: 'city',
      value: 'Bursa',
      toggleValue: '',
    },
  ];

  return (
    <MainLayout>
      <Box
        flex={1}
        display="grid"
        gridGap={'4'}
        gridTemplateColumns={{
          base: '1fr 1fr 1fr',
          sm: '1fr 1fr 1fr 1fr 1fr',
          md: '1fr 1fr 1fr 1fr 1fr 1fr',
          lg: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
        }}
        pt={'5'}
        px="3"
        alignItems={'center'}
      >
        {filters.map(({ title, key, value, toggleValue }) => (
          <FilterButton
            title={title}
            isActive={filter?.[key] === value}
            onClick={() =>
              changeFilter({
                key,
                value,
                toggleValue,
              })
            }
          />
        ))}
        <Button
          onClick={() => setFilter(defaultPostFilter)}
          color="#1b70f7"
          ml="2"
          border="1px"
          borderRadius={8}
          borderColor="#71a5f5"
        >
          {t('clear')}
        </Button>
      </Box>
      <InfiniteScroll {...props}>
        <Board posts={data} />
      </InfiniteScroll>
    </MainLayout>
  );
};

const FilterButton = ({ onClick, title, isActive }) => {
  return (
    <Button
      variant="solid"
      backgroundColor="#1b70f7"
      color="white"
      onClick={onClick}
      borderColor={isActive ? 'yellow.500' : 'gray.500'}
      border="1"
      ml="2"
      borderRadius={10}
      _hover={{ bg: '#9dbced' }}
      _active={{
        bg: '#ebedf0',
        transform: 'scale(0.98)',
        borderColor: '#bec3c9',
      }}
    >
      {title}
    </Button>
  );
};

export default Explore;
