import React from 'react';
import { Box } from '@chakra-ui/react';
import { PostImageCard } from '.';

const Board = ({ posts = [], showAvatar = true, ...props }) => {
  return (
    <Box
      flex={1}
      display="grid"
      gridGap={'4'}
      gridTemplateColumns={{
        //xs: '1fr',
        base: '1fr 1fr',
        sm: '1fr 1fr 1fr',
        md: '1fr 1fr 1fr',
        //lg: '1fr 1fr 1fr 1fr 1fr',
        xl: '1fr 1fr 1fr 1fr 1fr',
      }}
      pt={'5'}
      px="3"
      {...props}
    >
      {posts.map?.(item => {
        return (
          <PostImageCard
            showAvatar={showAvatar}
            key={item?.id}
            avatar={item?.user}
            image={item?.image}
            title={item?.title}
            location={item?.city}
            userId={item?.user?.id || item?.userId}
            id={item?.id}
            isOnline={item?.isOnline}
            slug={item?.slug}
          />
        );
      })}
    </Box>
  );
};

export default Board;
