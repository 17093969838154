import { useNavigate } from 'react-router-dom';
import { routes } from '../constants/routes';
import useMainStore from './useMainStore';

const useNavigateUserProfile = ({ condition = true } = { condition: true }) => {
  const {
    data: { user: currentUser },
  } = useMainStore();
  const navigate = useNavigate();

  const navigateProfile = username => {
    if (!condition) return;
    if (currentUser?.username === username) {
      navigate(routes.profile.path);
    } else {
      navigate(routes.user.getPath(username));
    }
  };

  return navigateProfile;
};

export default useNavigateUserProfile;
