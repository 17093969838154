import Geocode from 'react-geocode';
import { GOOGLE_MAPS_API_KEY } from '../constants';

Geocode.setApiKey(GOOGLE_MAPS_API_KEY);

Geocode.setLanguage('tr');

export const getLocationFromCoordinates = async coordinates => {
  try {
    if (
      typeof coordinates !== 'object' ||
      !coordinates.latitude ||
      !coordinates.longitude
    )
      return '';
    const result = await Geocode.fromLatLng(
      coordinates.latitude,
      coordinates.longitude
    );

    return result.results?.[0]?.formatted_address || '';
  } catch (error) {
    console.error('error', error);
    return '';
  }
};

export const getCoordinatesFromPlace = async place => {
  try {
    const response = await Geocode.fromAddress(place);
    const { lat, lng } = response.results[0].geometry.location;
    return {
      latitude: lat,
      longitude: lng,
    };
  } catch (error) {
    return null;
  }
};
