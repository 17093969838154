/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Button, Text, useDisclosure } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import {
  AlertModal,
  MainLayout,
  MainSection,
  PostImageCard,
  SecondarySection,
  SectionsWrapper,
  Spinner,
} from '../../components';
import { routes } from '../../constants/routes';
import useMainStore from '../../hooks/useMainStore';
import { PostService } from '../../services';
import toast from '../../helpers/toast';
import usePost from '../../hooks/usePost';
import { useTranslation } from 'react-i18next';

const PostDashboard = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { updateSharePost, onShare } = useMainStore();
  const deleteModal = useDisclosure();
  const { mutateAsync: deletePost, isLoading: isDeleting } = useMutation(
    PostService.deletePost
  );
  const navigate = useNavigate();
  const [details, setDetails] = useState(null);

  useEffect(() => {
    PostService.getPostDetails(id).then(([result]) => {
      if (result) setDetails(result);
    });
  }, []);

  const { post, isLoading } = usePost(id, {
    navigateWhenNotFound: true,
    navigateWhenNotMyPost: true,
  });

  const items = [
    {
      key: 'postDetails',
      label: t('show_event'),
      onPress: () => {
        navigate(routes.detail.getPath(details.slug));
      },
    },
    {
      key: 'editPost',
      label: t('edit_event'),
      onPress: () => {
        if (!post) return;
        updateSharePost({ details: post, id });
        navigate(routes.shareEditPost.path);
      },
    },
    {
      key: 'sendEmail',
      label: t('send_email_to_participants'),
      onPress: () => {
        navigate(routes.postSendEmail.getPath(id));
      },
    },
    {
      key: 'postStats',
      label: t('event_statistics'),
      onPress: () => {
        navigate(routes.postStats.getPath(id));
      },
    },
    {
      key: 'generateCertificate',
      label: t('create_cert'),
      onPress: () => {
        navigate(routes.postCreateCertificate.getPath(id));
      },
    },
    {
      key: 'deletePost',
      label: t('delete_event'),
      onPress: () => {
        deleteModal.onOpen();
      },
    },
    {
      key: 'share',
      label: t('share_event'),
      onPress: () => {
        onShare?.(post);
      },
    },
  ];

  const onDelete = async () => {
    await deletePost(id);
    deleteModal.onClose();
    toast({ title: t('event_deleted'), status: 'success' });
    navigate(routes.home.path);
  };

  return (
    <MainLayout>
      {isLoading ? (
        <Spinner />
      ) : (
        <SectionsWrapper>
          <MainSection>
            <Text my="3" color={'primary'} fontSize="xl" fontWeight={'bold'}>
              İşlemler
            </Text>
            {items.map(item => {
              return (
                <Button
                  key={item.key}
                  size="lg"
                  onClick={item.onPress}
                  backgroundColor="light"
                  borderRadius={'md'}
                  margin="2"
                >
                  {item.label}
                </Button>
              );
            })}
          </MainSection>
          <SecondarySection>
            <Text textAlign={'center'} my="3" fontSize="xl" fontWeight={'bold'}>
              <Text as="strong" color={'primary'}>
                {post?.title}
              </Text>
              <br></br>
              {t('dashboard')}
            </Text>
            <PostImageCard
              showAvatar={false}
              title={post?.title}
              id={id}
              image={post?.image}
              slug={post?.slug}
            />
          </SecondarySection>
        </SectionsWrapper>
      )}
      <AlertModal
        description={t('delete_event_desc')}
        continueText={t('delete')}
        isOpen={deleteModal.isOpen}
        onClose={deleteModal.onClose}
        continueButtonProps={{ isDisabled: isDeleting, isLoading: isDeleting }}
        onContinue={onDelete}
      />
    </MainLayout>
  );
};

export default PostDashboard;
