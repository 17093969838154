import React from 'react';
import { Box, Text, Image } from '@chakra-ui/react';
import { Footer, MainLayout } from '../../components';
import howUsers from '../../assets/images/how-participants.png';
import { useTranslation } from 'react-i18next';
import { selectByLanguage } from '../../language';

const HowToUserUse = () => {
  const {
    i18n: { language },
  } = useTranslation();
  const userUSe = [
    {
      key: 1,
      text: 'Kaydını tamamla ve ilgi alanındaki topluluk/kulübü takip derek paylaşılan etkinliklerden haberdar ol.',
      text_en:
        'Complete your registration and follow the community/club in your field of interest to be informed about the shared events.',
    },
    {
      key: 2,
      text: 'Etkinlikleri kaçırmamak için hatırlatıcı özelliğini kullan.',
      text_en: 'Use the reminder feature to not miss events.',
    },
    {
      key: 3,
      text: 'Etkinlikleri puanlayarak, yorum yaparak etkinlik değerlendirmesi yapabilirsin.',
      text_en:
        'You can evaluate the event by rating and commenting on the events.',
    },
    {
      key: 4,
      text: 'Etkinlik sonrası sertifikanı alarak dijital CV’ni oluşturmaya başla.',
      text_en:
        'Start creating your digital CV by receiving your certificate after the event.',
    },
    {
      key: 5,
      text: 'Üniversitendeki sana uygun, ilgi alanlarına göre kendini geliştirebileceğin topluluklara katılmak için başvuru yapabilirsin.',
      text_en:
        'You can apply to join communities where you can improve yourself according to your interests at your university.',
    },
    {
      key: 6,
      text: "Pano'yu kullanarak kendi alanlarında etkinliklere ve topluluklara ulaş ve yeni etkinliklerden haberdar olmak için takip et.",
      text_en:
        'Use the board to reach events and communities in your own fields and follow them to be informed about new events.',
    },
  ];

  return (
    <MainLayout>
      <Box display={'flex'} justifyContent={'center'} mb="5">
        <Image maxH={'35vh'} src={howUsers} objectFit="cover" />
      </Box>
      <Box>
        {userUSe.map((text, index) => {
          return (
            <Box
              key={index}
              display={'flex'}
              textAlign={'center'}
              mb="5"
              borderWidth={1}
              borderColor={'primary'}
              borderRadius={'2xl'}
              m={{ base: '2', md: '3', lg: '4' }}
              backgroundColor={'light'}
            >
              <Box
                justifyContent={'center'}
                alignItems={'center'}
                display={'flex'}
                w={{ base: 75, md: 100, lg: 150 }}
              >
                <Text
                  fontWeight={'600'}
                  fontSize={{ base: '3xl', md: '5xl', lg: '7xl' }}
                  lineHeight={'none'}
                >
                  {text.key}
                </Text>
              </Box>
              <Box flex={1} p={{ base: '5', md: '8', lg: '12' }}>
                <Text
                  fontSize={{ base: 15, md: 20, lg: 25 }}
                  alignSelf={'center'}
                >
                  {selectByLanguage(
                    {
                      tr: text.text,
                      en: text.text_en,
                    },
                    language
                  )}
                </Text>
              </Box>
            </Box>
          );
        })}
      </Box>
      <Footer />
    </MainLayout>
  );
};

export default HowToUserUse;
