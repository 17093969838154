import { Box, Text } from '@chakra-ui/react';
import React from 'react';
import { Footer, MainLayout } from '../../components';
import { selectByLanguage } from '../../language';
import { useTranslation } from 'react-i18next';

const data = {
  tr: (
    <>
      <Text fontWeight={'bold'} fontSize="3xl" textAlign={'center'}>
        Bilet Satın Alma ve İptal Şartları
      </Text>
      <br></br>

      <p>
        1. Cayma Hakkı: Tüketicinin Korunması Hakkında Kanun’un 48. maddesi
        uyarınca, mesafeli sözleşmelerde cayma hakkının istisnaları
        belirlenmektedir. Bu nedenle, satın alınan biletler için cayma hakkı
        bulunmamaktadır.
      </p>
      <br></br>

      <p>
        2. Bilet Satışı: Bilet satışları kesindir ve satın alınan biletler,
        satış işlemi sonrasında iptal edilemez, değiştirilemez ve ücret iadesi
        yapılmaz.
      </p>
      <br></br>

      <p>
        3. Etkinlik İptali veya Değişikliği: Organizatör tarafından herhangi bir
        etkinlik iptal edilmediği veya ertelenmediği müddetçe ya da
        organizatörün belirlediği kurallar dışında, bilet iadesi, iptal ve
        değişiklik yapılamaz.
      </p>
      <br></br>

      <p>
        4. Bilgilendirme: Panoyabak üyelik hesabı ile bilet satın alan
        müşterilere, bilgilendirme amaçlı sms mesajı ve/veya e-posta
        gönderilmektedir. Etkinlik iptali veya değişikliği durumunda, detaylı
        iade bilgileri bu kanallar aracılığıyla paylaşılmaktadır.
      </p>
      <br></br>

      <p>
        5. İletişim Bilgileri: Biletlerini perakende satış noktalarından satın
        alan müşterilerin, iade süreci ile ilgili bilgi almaları için yardım
        sayfalarından veya çağrı merkezini aramaları gerekmektedir. İletişim
        bilgilerinin Panoyabak ile paylaşılmaması durumunda, iade süreci takip
        etme sorumluluğu müşterilere aittir.
      </p>
      <br></br>

      <p>
        6. Çağrı Merkezi Hizmetleri: Panoyabak çağrı merkezi haftanın her günü
        10:00-17:00 saatleri arasında hizmet vermektedir.
      </p>
      <br></br>

      <p>
        7. Bilgilendirme Tercihi: Etkinlik iptali veya değişikliği durumunda,
        müşterilerin bilgilendirilmesi için Panoyabak üyelik hesabı ile bilet
        satın almaları önerilmektedir. Bu şekilde, detaylı iade bilgileri sms
        veya e-posta yoluyla müşterilere iletilmektedir.
      </p>
    </>
  ),
  en: (
    <>
      <Text fontWeight={'bold'} fontSize="3xl" textAlign={'center'}>
        Ticket Purchase and Cancellation Terms
      </Text>
      <br></br>

      <p>
        1. Right of Withdrawal: According to Article 48 of the Law on the
        Protection of the Consumer, the exceptions to the right of withdrawal in
        distance contracts are determined. Therefore, there is no right of
        withdrawal for purchased tickets.
      </p>
      <br></br>

      <p>
        2. Ticket Sales: Ticket sales are final, and purchased tickets cannot be
        canceled, changed, or refunded after the sales transaction.
      </p>
      <br></br>

      <p>
        3. Event Cancellation or Change: Unless any event is canceled or
        postponed by the organizer, or outside the rules set by the organizer,
        ticket refunds, cancellations, and changes cannot be made.
      </p>
      <br></br>

      <p>
        4. Notification: Customers who purchase tickets with a Panoyabak
        membership account are sent informational SMS messages and/or emails.
        Detailed refund information is shared through these channels in case of
        event cancellation or change.
      </p>
      <br></br>

      <p>
        5. Contact Information: Customers who purchase their tickets from retail
        sales points need to refer to help pages or call the call center for
        information about the refund process. If contact information is not
        shared with Panoyabak, customers are responsible for following the
        refund process.
      </p>
      <br></br>

      <p>
        6. Call Center Services: The Panoyabak call center is available every
        day from 10:00 to 17:00.
      </p>
      <br></br>

      <p>
        7. Notification Preference: In the event of an event cancellation or
        change, it is recommended that customers purchase tickets with a
        Panoyabak membership account for notification. This way, detailed refund
        information is conveyed to customers via SMS or email.
      </p>
    </>
  ),
};
const Delivery = () => {
  const {
    i18n: { language },
  } = useTranslation();
  return (
    <MainLayout>
      <Box my="10">
        {selectByLanguage(data, language)}
        <br></br>
      </Box>
      <Footer />
    </MainLayout>
  );
};

export default Delivery;
