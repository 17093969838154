/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Avatar,
  Box,
  Text,
  MenuButton,
  MenuList,
  MenuItem,
  Menu,
  Icon,
  Image,
  IconButton,
  useDisclosure,
  Button,
  Divider,
  AvatarGroup,
} from '@chakra-ui/react';
import { useMutation, useQuery } from 'react-query';
import {
  PostCommentItem,
  TabLine,
  CommentInput,
  PostParticipantItem,
  Spinner,
} from '..';
import { UserPostService } from '../../services';
import {
  CommentOutline,
  Heart,
  HeartOutline,
  Menu as MenuIcon,
} from '../icons';
import useMainStore from '../../hooks/useMainStore';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../constants/routes';
import { useTranslation } from 'react-i18next';

const tabs = {
  comments: 'comments',
  details: 'details',
  participants: 'participants',
};

const UserPostDetails = ({ title, postId, id }) => {
  const { t } = useTranslation();
  const {
    data: { user },
    isLoggedIn,
    onShare,
    blockUser,
    reportUser,
  } = useMainStore();
  const navigate = useNavigate();
  const [tab, setTab] = useState(tabs.participants);
  const [isLiked, setIsLiked] = useState(false);
  const [likedUser, setLikedUser] = useState([]);
  const [comments, setComments] = useState([]);
  const [comment, setComment] = useState('');

  const commentsEnd = useRef();

  const { data, isLoading } = useQuery(['userPostDetailsData', id], () =>
    UserPostService.findUserPostById(id)
  );
  const { data: commentsData } = useQuery(['userPostDetailComments', id], () =>
    UserPostService.getUserPostComment(id)
  );

  const { mutateAsync: like, isLoading: isLiking } = useMutation(
    UserPostService.likePost
  );
  const { mutateAsync: unlike, isLoading: isUnliking } = useMutation(
    UserPostService.unlikePost
  );
  const { mutateAsync: createUserComment, isLoading: isSendingComment } =
    useMutation(UserPostService.createUserComment);

  const { data: likedUsersData } = useQuery(['userPostDetailLikes', id], () =>
    UserPostService.getUserPostLikes(id)
  );

  const userPost = useMemo(() => {
    if (data) {
      if (data[0] === null) {
        navigate(routes.home.path);
      }
      return data[0];
    }
    return {};
  }, [data]);

  const isMyPost = useMemo(() => {
    return userPost?.userId === user?.id;
  }, [userPost, user]);

  const liked = useMemo(() => {
    const result = likedUser.some(item => item?.userId === user?.id);
    setIsLiked(result);
    return result;
  }, [likedUser]);

  const likes = async () => {
    const [data] = await like(id);
    setIsLiked(true);
    if (data.like) {
      const newArr = likedUser.unshift({ ...data.like, user });
      setLikedUser([...newArr]);
    }
  };

  const unlikes = async () => {
    const [data] = await unlike(id);
    setIsLiked(false);
    if (liked) {
      const newArr = likedUser.filter(item => item.userId !== user.id);
      setLikedUser([...newArr]);
    }
  };

  const onSendComment = async () => {
    comments.unshift({ text: comment, user });
    setComments([...comments]);
    commentsEnd.current?.scrollIntoView({ behavior: 'smooth' });
    const [result, error] = await createUserComment({
      userPostId: id,
      text: comment,
    });
    setComment('');
  };

  const onUser = username => {
    const isMe = user?.username === username;
    if (isMe) return navigate(routes.profile.path);
    navigate(routes.user.getPath(username));
  };

  if (isLoading) return <Spinner />;

  return (
    <Box>
      <Box
        display={'flex'}
        my={'3'}
        fontSize={'md'}
        backgroundColor={'light'}
        flex="1"
        flexDirection={{ base: 'column', lg: 'row' }}
        height={{ base: 'unset', lg: '70vh' }}
      >
        <Box
          borderLeftRadius={'2xl'}
          borderBottomRightRadius={'2xl'}
          px={'5'}
          py="4"
          display={'flex'}
          flexDirection={'column'}
          flex="1"
        >
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Box
              display={'flex'}
              alignItems={'center'}
              cursor={'pointer'}
              onClick={() => onUser(userPost?.user.username)}
            >
              <Avatar
                src={userPost?.user?.avatar}
                name={userPost?.user?.username}
                size={'sm'}
              />
              <Text color="default" ml={'2'} fontWeight={'600'}>
                {userPost?.user?.username}
              </Text>
            </Box>
            {isLoggedIn && (
              <Menu>
                <MenuButton
                  borderWidth={0}
                  as={IconButton}
                  aria-label="Options"
                  icon={<Icon as={MenuIcon} />}
                  variant="outline"
                />
                <MenuList borderWidth={0}>
                  {/*isMyPost ? (
                                    <MenuItem onClick={console.log()}>
                                        Gönderiyi Düzenle
                                    </MenuItem>
                                ) : null*/}
                  <MenuItem onClick={() => onShare(userPost)}>
                    {t('share_post')}
                  </MenuItem>
                  {!isMyPost && (
                    <MenuItem
                      onClick={() => {
                        blockUser(userPost.userId);
                        navigate(routes.home.path);
                      }}
                    >
                      {t('block_user')}
                    </MenuItem>
                  )}
                  {!isMyPost && (
                    <MenuItem onClick={() => reportUser(userPost.userId)}>
                      {t('report')}
                    </MenuItem>
                  )}
                </MenuList>
              </Menu>
            )}
          </Box>
          <Box
            flex={'1'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            position={'relative'}
          >
            <Image
              maxH={'60vh'}
              borderWidth={2}
              borderColor={'primary'}
              src={userPost?.image}
              borderRadius={'2xl'}
              alt={userPost?.description}
            />
          </Box>
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'space-between'}
          p={'5'}
          flex={1}
        >
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-around'}
            mt={'5'}
            fontWeight={'600'}
            fontSize={'md'}
            color="default"
          >
            {/*<Box
                        px="2"
                        cursor={'pointer'}
                        mr="2"
                        onClick={() => setTab(tabs.details)}
                    >
                        <Text>Detaylar</Text>
                        <TabLine isActive={tab === tabs.details} />
                                </Box>*/}
            <Box
              px="2"
              cursor={'pointer'}
              mr="2"
              onClick={() => setTab(tabs.participants)}
            >
              <Text>{t('likes')}</Text>
              <TabLine isActive={tab === tabs.participants} />
            </Box>
            <Box
              px="2"
              cursor={'pointer'}
              mr="2"
              onClick={() => setTab(tabs.comments)}
            >
              <Text>{t('comments')}</Text>
              <TabLine isActive={tab === tabs.comments} />
            </Box>
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            flex="1"
            py={'5'}
            maxHeight="80vh"
            overflow={'scroll'}
            wordBreak={'break-word'}
            css={{
              '&::-webkit-scrollbar': {
                width: '4px',
              },
              '&::-webkit-scrollbar-track': {
                width: '6px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: 'blue',
                borderRadius: '24px',
              },
            }}
          >
            {tab === tabs.details ? (
              <Box height="100%">
                <Box>
                  <Text pb={'2'} color="default" fontWeight={'500'}>
                    {userPost?.description}
                  </Text>
                </Box>
              </Box>
            ) : null}
            {tab === tabs.comments ? (
              <>
                <Box>
                  <Text pb={'2'} color="default" fontWeight={'500'}>
                    {userPost?.description}
                  </Text>
                </Box>
                <Divider color="#ccc" mr={'auto'} my="2" />
                <Box
                  display={'flex'}
                  flex={'1'}
                  flexDirection={'column-reverse'}
                >
                  {comments?.map((item, key) => (
                    <PostCommentItem
                      key={item.id}
                      text={item?.text}
                      avatar={item?.user?.avatar}
                      userId={item?.userId}
                      username={item?.user?.username}
                    />
                  ))}
                </Box>
                {isLoggedIn && (
                  <Box ref={commentsEnd}>
                    <CommentInput
                      onChange={e => setComment(e.target.value)}
                      value={comment}
                      onSubmit={onSendComment}
                      isSending={isSendingComment}
                      placeholder={t('write_comment')}
                    />
                  </Box>
                )}
              </>
            ) : null}
            {tab === tabs.participants ? (
              <>
                {likedUser?.map(item => (
                  <PostParticipantItem
                    key={item.id}
                    avatar={item?.user?.avatar}
                    userId={item?.userId}
                    username={item?.user?.username}
                  />
                ))}
              </>
            ) : null}
          </Box>
          <Box
            flex={0}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            fontSize={'lg'}
          >
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'flex-start'}
            >
              {isLiked || liked ? (
                <Button disabled={isUnliking} onClick={() => unlikes()}>
                  <Icon fontSize="2xl" as={Heart} color="red" />
                </Button>
              ) : (
                <Button disabled={isLiking} onClick={() => likes()}>
                  <Icon fontSize="2xl" as={HeartOutline} color="default" />
                </Button>
              )}

              {likedUser.length > 1 ? (
                <Button
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  mr={'3'}
                  cursor={'pointer'}
                  onClick={() => setTab(tabs.participants)}
                >
                  <AvatarGroup max={3}>
                    {likedUser?.map(item => (
                      <Avatar
                        size="sm"
                        name={item?.user?.username}
                        src={item?.user?.avatar}
                      />
                    ))}
                  </AvatarGroup>
                </Button>
              ) : null}
              <Button
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                mr={'3'}
                onClick={() => setTab(tabs.comments)}
              >
                <Icon fontSize="2xl" as={CommentOutline} color="default" />
                <Text ml={'2'} fontWeight={'600'} color="default">
                  {comments.length}
                </Text>
              </Button>
            </Box>
            {/*<Box
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        mr={'3'}
                    >
                        <Icon as={BookmarkOutline} fontSize="2xl" color="default" />
                                </Box>*/}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default UserPostDetails;
