import * as React from 'react';

const SvgLock = props => (
  <svg
    viewBox="0 0 22 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20 13H2v14h18V13ZM18 13V8.875C18 5.078 14.866 2 11 2S4 4.39 4 8.188"
      stroke="currentColor"
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgLock;
