import React from 'react';
import { Box, Text, Image } from '@chakra-ui/react';
import { Footer, MainLayout } from '../../components';
import howComminities from '../../assets/images/how-communities.png';
import { useTranslation } from 'react-i18next';
import { selectByLanguage } from '../../language';

const HowToClupUse = () => {
  const {
    i18n: { language },
  } = useTranslation();
  const clupUse = [
    {
      key: 1,
      text: 'Kaydını tamamla ve etkinliklerini paylaş, dijital panoda yerini alarak etkinliklerini katılımcılar ile buluştur.',
      text_en:
        'Complete your registration and share your events, take your place on the digital board and meet your events with participants.',
    },
    {
      key: 2,
      text: 'Etkinlik paylaşımında etkinliğe ait tarih,konum, açıklamalar, etkinlik şartları, varsa ücret bilgisi ve farklı oturum bilgilerini belirtebilmekte ve bunlar üzerinde değişiklik yapabilirsin.',
      text_en:
        'In event sharing, you can specify the date, location, descriptions, event conditions, if any, fee information and different session information of the event and make changes on them.',
    },
    {
      key: 3,
      text: 'Etkinlikte bir değişiklik olduğu takdirde (saat, gün, mekandeğişikliği yada etkinliğin iptal edilmesi) katılımcılara toplu olarak mail göndererek katılımcılarını haberdar et.',
      text_en:
        'In case of a change in the event (change of time, day, location or cancellation of the event), inform the participants by sending a mass email to the participants.',
    },
    {
      key: 4,
      text: 'Etkinlik sonrası kontrol panelinden istatistik (katılım oranı, yorum, puanlama vb.) değerlendirmesine ulaş.',
      text_en:
        'Access the statistics (participation rate, comments, scoring, etc.) evaluation from the control panel after the event.',
    },
    {
      key: 5,
      text: 'Katılımcılarına etkinlik sonrası katılım sertifikalarını gönder.',
      text_en:
        'Send participation certificates to participants after the event.',
    },
    {
      key: 6,
      text: ' Web sitesi üzerinden çekilişini gerçekleştir.',
      text_en: 'Conduct your draw via the website.',
    },
  ];

  return (
    <MainLayout>
      <Box display={'flex'} justifyContent={'center'} mb="5">
        <Image maxH={'35vh'} src={howComminities} objectFit="cover" />
      </Box>
      <Box>
        {clupUse.map((text, index) => {
          return (
            <Box
              key={index}
              display={'flex'}
              textAlign={'center'}
              mb="5"
              borderWidth={1}
              borderColor={'primary'}
              borderRadius={'2xl'}
              m={{ base: '2', md: '3', lg: '4' }}
              backgroundColor={'light'}
            >
              <Box
                justifyContent={'center'}
                alignItems={'center'}
                display={'flex'}
                w={{ base: 75, md: 100, lg: 150 }}
              >
                <Text
                  fontWeight={'600'}
                  fontSize={{ base: '3xl', md: '5xl', lg: '7xl' }}
                  lineHeight={'none'}
                >
                  {text.key}
                </Text>
              </Box>
              <Box flex={1} p={{ base: '5', md: '8', lg: '12' }}>
                <Text
                  fontSize={{ base: 15, md: 20, lg: 25 }}
                  alignSelf={'center'}
                >
                  {selectByLanguage(
                    {
                      tr: text.text,
                      en: text.text_en,
                    },
                    language
                  )}
                </Text>
              </Box>
            </Box>
          );
        })}
      </Box>
      <Footer />
    </MainLayout>
  );
};

export default HowToClupUse;
