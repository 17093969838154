import React from 'react';
import { Box, Image } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../constants/routes';

const UserPostCard = ({ image, title = '', userId, postId, id }) => {
  const navigate = useNavigate();

  const onDetail = () =>
    navigate(routes.userPostDetail.getPath({ postId, id }));

  return (
    <Box
      borderRadius={'lg'}
      position={'relative'}
      alignSelf={'center'}
      cursor={'pointer'}
      onClick={onDetail}
      overflow={'hidden'}
      boxShadow={'xl'}
    >
      <Image
        src={image}
        alt={title}
        borderRadius={{ base: 'sm', md: 'md', lg: 'lg' }}
        objectFit="cover"
        boxShadow="md"
      />
    </Box>
  );
};

export default UserPostCard;
