import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import tr from './translations/tr.json';
import en from './translations/en.json';

export const resources = {
  tr: {
    translation: tr,
  },
  en: {
    translation: en,
  },
};

export const defaultLanguage = 'en';

export const availableLanguages = [
  {
    code: 'tr',
    label: 'Türkçe',
    flag: '🇹🇷',
  },
  {
    code: 'en',
    label: 'English',
    flag: '🇺🇸',
  },
];

const getPreferredLanguage = (user = null) => {
  if (user?.language) return user.language;

  const savedlang = localStorage.getItem('user-lang');
  if (savedlang) return savedlang;

  const browserLang = navigator?.language || navigator?.userLanguage;
  const found = availableLanguages.find(l => l.code === browserLang);
  if (found) return found.code;

  return defaultLanguage;
};

i18n.use(initReactI18next).init({
  // compatibilityJSON: 'v3',
  lng: getPreferredLanguage(),
  fallbackLng: defaultLanguage,
  debug: true,
  resources,
  interpolation: {
    escapeValue: false,
  },
});

export const detectLanguagePreference = (userLanguage, currentLang) => {
  let language = currentLang;
  let needUpdate = false;

  if (userLanguage && userLanguage !== language) {
    language = userLanguage;
    needUpdate = true;
  }

  return {
    language,
    needUpdate,
  };
};

export const selectByLanguage = (object, language) => {
  return object[language] || object[defaultLanguage];
};

export default i18n;
