import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  Text,
  ModalFooter,
  Box,
  Avatar,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const getRandomIndex = length => {
  return Math.floor(Math.random() * length);
};

const DELAY = 80;
const NUMBER_OF_LOOPS = 120;

const sleep = delay => new Promise(resolve => setTimeout(resolve, delay));

const RaffleModal = ({ users = [], onClose = () => {}, isOpen = false }) => {
  const { t } = useTranslation();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [infoText, setInfoText] = useState(t('raffle_not_started'));

  const onRaffle = async () => {
    setInfoText(t('choosing_participant') + '...');
    for (let i = 0; i < NUMBER_OF_LOOPS; i++) {
      const index = getRandomIndex(users.length);
      setSelectedIndex(index);
      await sleep(DELAY);
    }
    const resultIndex = getRandomIndex(users.length);
    setSelectedIndex(resultIndex);
    setInfoText(t('choosed_participant'));
  };

  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent backgroundColor={'light'}>
        <ModalHeader>{t('make_raffle')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody
          overflow={'scroll'}
          justifyContent={'center'}
          alignItems={'center'}
          display={'flex'}
          flex="1"
          flexDirection={'column'}
          css={{
            '&::-webkit-scrollbar': {
              width: '4px',
            },
            '&::-webkit-scrollbar-track': {
              width: '6px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: 'blue',
              borderRadius: '24px',
            },
          }}
        >
          <Text textAlign={'center'} fontWeight={'bold'}>
            {t('total_participants')}: {users?.length}
          </Text>
          <Text
            textAlign={'center'}
            fontWeight={'bold'}
            fontSize={'lg'}
            my="2"
            color="primary"
          >
            {infoText}
          </Text>
          {selectedIndex !== null ? (
            <User
              user={{
                ...users?.[selectedIndex]?.user,
                id: users?.[selectedIndex]?.userId,
              }}
            />
          ) : null}
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={onRaffle}
            isFullWidth
            backgroundColor="primary"
            color="white"
          >
            {t('make_raffle')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const User = ({ user }) => (
  <Box
    transition="all 50ms ease-in-out"
    display={'flex'}
    alignItems={'center'}
    my={'1'}
    cursor={'pointer'}
  >
    <Box pr={'2'}>
      <Avatar
        borderWidth={1}
        borderColor={'primary'}
        size={'sm'}
        src={user?.avatar}
      />
    </Box>
    <Box>
      <Text fontWeight={'600'} color="dark">
        {user?.username}
      </Text>
    </Box>
  </Box>
);

export default RaffleModal;
