import React from 'react';
import { useParams } from 'react-router-dom';
import { MainLayout, UserPostDetails } from '../../components';

const UserPostDetail = ({ route, navigation, ...props }) => {
  const { postId, id } = useParams();
  return (
    <MainLayout>
      <UserPostDetails postId={postId} id={id} />
    </MainLayout>
  );
};

export default UserPostDetail;
