import * as React from 'react';

const SvgBookmark = props => (
  <svg
    viewBox="0 0 16 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.88 1H4.112C2.394 1 1 2.566 1 4.473V17.15c0 1.62 1.04 2.303 2.313 1.52l3.933-2.438c.42-.26 1.097-.26 1.508 0l3.933 2.438c1.273.792 2.313.108 2.313-1.52V4.473C14.992 2.566 13.598 1 11.88 1Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgBookmark;
