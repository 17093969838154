import * as React from 'react';

const SvgShare = props => (
  <svg
    viewBox="0 0 34 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.001 16.5h12M17 22.5v-12M12.5 31.5h9C29 31.5 32 28.5 32 21v-9c0-7.5-3-10.5-10.5-10.5h-9C5 1.5 2 4.5 2 12v9c0 7.5 3 10.5 10.5 10.5Z"
      stroke="currentColor"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgShare;
