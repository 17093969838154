import API from '../api';
import endpoints from '../api/endpoints';

const getPosts = async () => {
  const { data } = await API.get(endpoints.getPosts);
  return [data, null];
};

const getHomePosts = async ({ page = 1 }) => {
  const { data } = await API.get(endpoints.getHomePosts, {
    params: { page },
  });
  return [data, null];
};

const getDiscoverPosts = async (filter = {}) => {
  const { data } = await API.get(endpoints.getDiscoverPosts, {
    params: { ...filter, limit: 9 },
  });
  return [data, null];
};

const getParticipants = async postId => {
  const { data } = await API.get(endpoints.getParticipants(postId));
  return [data?.participants, null];
};

const getComments = async (postId, params) => {
  const { data } = await API.get(endpoints.getComments(postId), { params });
  return [data, null];
};

const sendComment = async ({ postId, text }) => {
  const { data } = await API.post(endpoints.sendComment(postId), { text });
  return [data, null];
};

const join = async postId => {
  const { data } = await API.post(endpoints.join(postId));
  return [data, null];
};

const unjoin = async postId => {
  const { data } = await API.post(endpoints.unjoin(postId));
  return [data, null];
};

const getPostDetails = async postId => {
  const { data } = await API.get(endpoints.getPostDetails(postId));
  return [data?.post, null];
};

const getPostDetailsBySlug = async slug => {
  const { data } = await API.get(endpoints.getPostDetailsBySlug(slug));
  return [data, null];
};

const updatePost = async (postId, form) => {
  const { data } = await API.put(endpoints.updatePost(postId), form);
  return [data, null];
};

const deletePost = async postId => {
  const { data } = await API.delete(endpoints.deletePost(postId));
  return [data, null];
};

const ratePost = async (postId, rating) => {
  const { data } = await API.post(endpoints.ratePost(postId), { rating });
  return [data, null];
};

const canRatePost = async postId => {
  const { data } = await API.post(endpoints.canRatePost(postId));
  return [data, null];
};

const createPost = async ({
  description,
  image,
  date,
  endDate,
  location,
  price,
  conditions,
  link,
  title,
  city,
  sessions,
}) => {
  const formData = new FormData();
  formData.append('description', description);
  formData.append('price', price);
  formData.append('conditions', conditions);
  formData.append('date', date.toString());
  formData.append('endDate', endDate.toString());
  formData.append('location', JSON.stringify(location));
  formData.append('sessions', JSON.stringify(sessions));
  formData.append('link', link);
  formData.append('title', title);
  formData.append('city', city);
  formData.append('image', image);

  const { data } = await API.post(endpoints.createPost, formData);
  return [data, null];
};

const sendEmailToParticipants = async ({ postId, content }) => {
  const { data } = await API.post(endpoints.sendEmailToParticipants(postId), {
    content: content.trim(),
  });
  return [data, null];
};

const getCertificates = async () => {
  const { data } = await API.get(endpoints.getCertificates);
  return [data?.certificates, null];
};

const generateCertificate = async ({ postId, certificateKey }) => {
  const { data } = await API.post(endpoints.generateCertificates(postId), {
    certificateKey,
  });
  return [data, null];
};

const getPostStats = async postId => {
  const { data } = await API.get(endpoints.getPostStats(postId));
  return [data, null];
};

const checkQuota = async postId => {
  const { data } = await API.get(endpoints.checkQuota(postId));
  return [data?.result, null];
};

const getExternalEvents = async postId => {
  const { data } = await API.get(endpoints.getExternalEvents);
  return [data, null];
};

const PostService = {
  getPosts,
  getDiscoverPosts,
  getParticipants,
  getPostDetails,
  getComments,
  sendComment,
  join,
  unjoin,
  updatePost,
  deletePost,
  ratePost,
  canRatePost,
  createPost,
  sendEmailToParticipants,
  getCertificates,
  generateCertificate,
  getPostStats,
  checkQuota,
  getHomePosts,
  getPostDetailsBySlug,
  getExternalEvents,
};

export default PostService;
