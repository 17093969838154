/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Box, Button, Heading, Link, Text, InputGroup } from '@chakra-ui/react';
import { AuthLayout, PasswordInput, AuthInput } from '../components';
import useForm from '../hooks/useForm';
import { AuthService } from '../services';
import toast, { showFormErrorsToast } from '../helpers/toast';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import * as yup from 'yup';
import 'yup-phone';
import { routes } from '../constants/routes';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useTranslation } from 'react-i18next';

const Register = () => {
  const { t } = useTranslation();

  const validation = yup.object({
    name: yup.string().required(t('enter_name')),
    username: yup.string().required(t('enter_username')),
    email: yup.string().email(t('invalid_email')).required(t('enter_email')),
    phone: yup
      .string()
      .phone('TR', undefined, t('invalid_phone'))
      .required(t('enter_phone')),
    password: yup
      .string()
      .min(6, t('min_x_characters', { x: 6 }))
      .required(t('enter_password')),
    passwordConfirm: yup
      .string()
      .oneOf([yup.ref('password'), null], t('password_not_match')),
  });

  const { values, setValues, errors, isValid, setValuesDirectly } = useForm(
    {
      name: '',
      username: '',
      email: '',
      password: '',
      passwordConfirm: '',
      phone: '',
    },
    validation
  );

  const { mutateAsync, isLoading } = useMutation(AuthService.register);

  const navigate = useNavigate();

  const onSubmit = async () => {
    if (!isValid) {
      return showFormErrorsToast(errors, toast);
    }
    try {
      const [data] = await mutateAsync({
        ...values,
        username: values.username.trim(),
      });
      toast({
        title: data.message || t('check_email'),
        status: 'success',
      });
      navigate(routes.login.path);
    } catch (error) {
      toast({
        title: error?.response?.data?.message || t('an_error'),
        status: 'warning',
      });
    }
  };

  return (
    <AuthLayout withBackButton>
      <Box width={{ base: '100%', md: '80%', lg: '70%' }} mx="auto">
        <Heading my={'5'}>{t('hi')} 👋</Heading>
        <AuthInput
          placeholder={t('name_lastname')}
          name="name"
          onChange={setValues}
          value={values.name}
        />
        <AuthInput
          placeholder={t('email')}
          name="email"
          onChange={setValues}
          value={values.email}
        />
        <AuthInput
          placeholder={t('username')}
          name="username"
          onChange={e =>
            setValuesDirectly({
              username: String(e.target?.value)
                .trimStart()
                .replace(/\s/g, '_')
                .toLocaleLowerCase('tr'),
            })
          }
          value={values.username}
        />

        <InputGroup my={'3'} backgroundColor={'light'} size={'lg'}>
          <PhoneInput
            enableSearch
            placeholder={t('phone_number')}
            country={'tr'}
            value={values.phone}
            onChange={e => setValuesDirectly({ phone: e })}
            inputStyle={{
              backgroundColor: 'light',
              width: '100%',
            }}
          />
        </InputGroup>

        <PasswordInput
          showLeftIcon={false}
          name="password"
          onChange={setValues}
          value={values.password}
        />
        <PasswordInput
          showLeftIcon={false}
          name="passwordConfirm"
          onChange={setValues}
          value={values.passwordConfirm}
        />

        <Button
          backgroundColor={'primary'}
          borderRadius={'full'}
          height={'65px'}
          isFullWidth
          mt={'5'}
          mb="2"
          onClick={onSubmit}
          isLoading={isLoading}
        >
          <Text color={'white'} fontWeight={'600'} fontSize={'1.3rem'}>
            {t('register')}
          </Text>
        </Button>
        <Text
          color="default"
          textAlign={'center'}
          fontSize={'sm'}
          fontWeight={'500'}
        >
          {t('register_desc_1')}{' '}
          <Text onClick={() => {}} cursor={'pointer'} as="span" color="primary">
            {t('register_desc_2')}
          </Text>
          {t('register_desc_3')}
        </Text>
        <Text mt="5" textAlign={'center'}>
          {t('have_account')}{' '}
          <Link
            onClick={() => navigate(routes.login.path)}
            _hover={{ textDecoration: 'none' }}
            color="primary"
            fontWeight={'600'}
          >
            {t('login')}
          </Link>
        </Text>
      </Box>
    </AuthLayout>
  );
};

export default Register;
