import { INIT_DATA, SET_AUTH, SET_SHARE_POST, SET_USER } from '../actionTypes';

const mainReducer = (state, action) => {
  switch (action.type) {
    case SET_AUTH:
      return {
        ...state,
        authenticated: action.payload,
      };
    case SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case SET_SHARE_POST:
      return {
        ...state,
        sharePost: action.payload,
      };
    case INIT_DATA:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default mainReducer;
