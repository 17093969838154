import React from 'react';
import {
  InputGroup,
  Input,
  InputRightElement,
  Button,
  Icon,
} from '@chakra-ui/react';
import { Send } from '../icons';

const CommentInput = ({
  isSending = false,
  onSubmit = () => {},
  placeholder = 'Yorum yaz...',
  ...props
}) => {
  return (
    <InputGroup
      marginX={'auto'}
      backgroundColor="white"
      borderWidth={2}
      borderColor={'gray'}
      borderRadius={'lg'}
      mt={'4'}
    >
      <Input
        type={'text'}
        placeholder={placeholder}
        borderWidth={0}
        fontWeight={'500'}
        {...props}
      />
      <InputRightElement>
        <Button
          disabled={isSending}
          isLoading={isSending}
          borderRadius={'lg'}
          backgroundColor={'primary'}
          onClick={onSubmit}
        >
          <Icon as={Send} color={'white'} />
        </Button>
      </InputRightElement>
    </InputGroup>
  );
};

export default CommentInput;
