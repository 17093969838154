/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Box, Button, Heading, Text } from '@chakra-ui/react';
import { AuthLayout, PasswordInput } from '../components';
import useForm from '../hooks/useForm';
import { AuthService } from '../services';
import toast, { showFormErrorsToast } from '../helpers/toast';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { routes } from '../constants/routes';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

const ChangePassword = () => {
  const { t } = useTranslation();
  const validation = yup.object({
    password: yup
      .string()
      .min(6, t('min_x_characters', { x: 6 }))
      .required(t('mandatory_field')),
    passwordConfirm: yup
      .string()
      .oneOf([yup.ref('password'), null], t('password_not_match')),
  });
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const key = params.get('key');
  const { values, setValues, errors, isValid } = useForm(
    {
      password: '',
      passwordConfirm: '',
    },
    validation
  );
  const { mutateAsync, isLoading } = useMutation(AuthService.resetNewPassword);

  const navigate = useNavigate();

  useEffect(() => {
    if (!key) {
      navigate(routes.login.path);
    }
  }, []);

  const onSubmit = async () => {
    if (!isValid) {
      return showFormErrorsToast(errors, toast);
    }
    try {
      const [data] = await mutateAsync({ key, password: values.password });
      toast({
        title: data.message || t('password_changed'),
        status: 'success',
      });
      navigate(routes.login.path);
    } catch (error) {
      toast({
        title: error?.response?.data?.message || t('an_error'),
        status: 'warning',
      });
    }
  };

  return (
    <AuthLayout>
      <Box width={{ base: '100%', md: '80%', lg: '70%' }} mx="auto">
        <Heading my="5">{t('change_password_title')}🔐</Heading>
        <PasswordInput
          name="password"
          onChange={setValues}
          value={values.password}
        />
        <PasswordInput
          name="passwordConfirm"
          onChange={setValues}
          value={values.passwordConfirm}
          placeholder={t('password_again')}
        />
        <Button
          backgroundColor={'primary'}
          borderRadius={'full'}
          height={'65px'}
          isFullWidth
          my={'5'}
          onClick={onSubmit}
          isLoading={isLoading}
        >
          <Text color={'white'} fontWeight={'600'} fontSize={'1.3rem'}>
            {t('change_password')}
          </Text>
        </Button>
      </Box>
    </AuthLayout>
  );
};

export default ChangePassword;
