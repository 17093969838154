/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Box, Button, Icon } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { Footer, MainLayout } from '../../components';
import { Back } from '../../components/icons';
import WhatIs from './WhatIs';
import AppInfo from './AppInfo';

const WhatIsPage = () => {
  const navigate = useNavigate();
  return (
    <MainLayout>
      <Button
        onClick={() => navigate(-1)}
        backgroundColor={'light'}
        variant={'ghost'}
        borderRadius={'full'}
        position={{ base: 'fixed', md: 'absolute' }}
        top="3"
        left="5"
      >
        <Icon width={'unset'} as={Back} color="primary" />
      </Button>
      <WhatIs />
      <AppInfo />
      <Footer />
    </MainLayout>
  );
};

export default WhatIsPage;
