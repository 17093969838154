import * as React from 'react';

const SvgSend = props => (
  <svg
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.304 5.147 16.82 1.483c4.72-1.644 7.283 1.049 5.723 5.981l-3.505 10.992c-2.354 7.392-6.218 7.392-8.572 0l-1.04-3.263-3.122-1.087c-7.072-2.46-7.072-6.487 0-8.96Z"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgSend;
