import * as React from 'react';

const SvgHeart = props => (
  <svg
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m13 24-1.595-1.583C5.74 16.818 2 13.126 2 8.594 2 4.901 4.662 2 8.05 2c1.914 0 3.751.971 4.95 2.506C14.199 2.97 16.036 2 17.95 2 21.338 2 24 4.901 24 8.594c0 4.532-3.74 8.225-9.405 13.835L13 24Z"
      fill="#F32929"
      stroke="#F32929"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgHeart;
