import React from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { Avatar, Box, Icon, Link, Text } from '@chakra-ui/react';
import { Explore, Home, HomeActive, Share, ShareActive } from '../icons';
import { routes } from '../../constants/routes';
import SearchBox from '../SearchBox';
import { Logo } from '..';
import useMainStore from '../../hooks/useMainStore';
import { MAIN_LAYOUT_MAX_WIDTH } from '../../constants';
import { FiMessageCircle } from 'react-icons/fi';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';

const MainHeader = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    data: { user },
    isLoggedIn,
    isMod,
  } = useMainStore();

  const nameDisplay = user?.name?.split?.(' ')?.[0] || user?.username || '';

  return (
    <Box as="nav" backgroundColor={'light'}>
      <Box
        display={'flex'}
        flexDirection={{ base: 'column', md: 'row' }}
        justifyContent={'space-between'}
        maxWidth={MAIN_LAYOUT_MAX_WIDTH}
        marginX={'auto'}
        pt={{ base: '4', md: 0, lg: 0 }}
        px={{ base: '2', md: '1', lg: 0 }}
        minH={'60px'}
      >
        <Box justifyContent={'center'} alignItems={'center'} display={'flex'}>
          <Link
            _hover={{ textDecoration: 'none' }}
            _focus={{ boxShadow: 'none' }}
            as={RouterLink}
            to={routes.home.path}
          >
            <Logo width={150} />
          </Link>
        </Box>
        {isLoggedIn && (
          <Box flex="1" display={'flex'} alignSelf={'center'}>
            <SearchBox
              onSearch={text => navigate(routes.search.getPath(text))}
              placeholder={t('search_in_your_mind')}
            />
          </Box>
        )}

        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-around'}
        >
          {isLoggedIn ? (
            <>
              {isMod && (
                <NavItem
                  to={routes.share.path}
                  isActive={[
                    routes.share.path,
                    routes.shareEditPost.path,
                  ].includes(location.pathname)}
                  active={<Icon as={ShareActive} />}
                  inactive={<Icon as={Share} />}
                />
              )}
              <NavItem
                to={routes.home.path}
                isActive={location.pathname === routes.home.path}
                active={<Icon as={HomeActive} />}
                inactive={<Icon as={Home} />}
              />
              <NavItem
                to={routes.explore.path}
                isActive={location.pathname === routes.explore.path}
                active={<Icon as={Explore} />}
                inactive={<Icon as={Explore} />}
              />
              <NavItem
                to={routes.messages.path}
                isActive={location.pathname.includes(routes.messages.path)}
                active={<Icon as={FiMessageCircle} />}
                inactive={<Icon as={FiMessageCircle} />}
              />
              <NavItem
                to={routes.profile.path}
                isActive={location.pathname === routes.profile.path}
                active={<UserAvatar avatar={user?.avatar} name={nameDisplay} />}
                inactive={
                  <UserAvatar avatar={user?.avatar} name={nameDisplay} />
                }
              />
            </>
          ) : (
            <>
              <NavItem
                to={routes.register.path}
                isActive={false}
                active={null}
                inactive={<Text fontSize={'lg'}>{t('register')}</Text>}
              />
              <NavItem
                to={routes.login.path}
                isActive={false}
                active={null}
                inactive={
                  <Text color="primary" fontSize={'lg'}>
                    {t('login')}
                  </Text>
                }
              />
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const UserAvatar = ({ name, avatar }) => {
  return (
    <Box
      height={'100%'}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <Avatar size="sm" name={name} src={avatar} />
      <Text ml={'5px'} fontSize={15}>
        {name}
      </Text>
    </Box>
  );
};

const NavItem = ({
  active = null,
  inactive = null,
  isActive = false,
  to = '#',
}) => {
  const isMobile = useMediaQuery({ query: '(max-width: 786px)' });
  return (
    <Box
      display={'flex'}
      flexDirection={{ base: 'column-reverse', md: 'column' }}
      alignItems="center"
      justifyContent={'space-between'}
      marginX={'5px'}
      mt={isMobile ? 2 : 0}
    >
      <Box
        height={'5px'}
        width={70}
        backgroundColor={isActive ? 'primary' : 'transparent'}
        borderBottomLeftRadius={3.5}
        borderBottomRightRadius={3.5}
        transform={{ base: 'rotatex(180deg)', md: 'none' }}
        mt={{ base: '1', md: '0' }}
      />
      <Link
        _hover={{ textDecoration: 'none' }}
        _focus={{ boxShadow: 'none' }}
        as={RouterLink}
        to={to}
        fontWeight={'600'}
        flex="1"
        fontSize={25}
        justifyContent={'center'}
        alignItems={'center'}
        display={'flex'}
        flexDirection={'row'}
        color={isActive ? 'primary' : 'lightGray'}
      >
        {isActive ? active : inactive}
      </Link>
      <Box />
    </Box>
  );
};

export default MainHeader;
