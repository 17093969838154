/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import FirebaseChat from '@metehankurucu/firebase-chat';

const messagesLimit = 20;

const useMessages = roomId => {
  const [_messages, _setMessages] = useState([]);
  const messages = FirebaseChat.messages(roomId);
  const [isEndOfList, setIsEndOfList] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const unsubscribe = messages.listenMessages(
      { limit: messagesLimit },
      items => {
        _setMessages(items);
      }
    );

    return () => unsubscribe();
  }, []);

  const sendMessage = async (text, mediaUrl = null) => {
    await messages.sendMessage(text, mediaUrl);
  };

  const loadMore = async (lastMessageDate, limit = messagesLimit) => {
    setLoading(true);
    if (!lastMessageDate || typeof lastMessageDate !== 'number') return;
    const items =
      (await messages.getMessages({ startAfter: lastMessageDate, limit })) ||
      [];
    _setMessages(prev => [...prev, ...items]);
    if (items.length === 0) setIsEndOfList(true);
    setLoading(false);
  };

  return {
    messages: _messages,
    sendMessage,
    loadMore,
    isEndOfList: isEndOfList || messagesLimit > _messages.length,
    loading,
  };
};

export default useMessages;
