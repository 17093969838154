import React from 'react';
import { Box, Text, Link } from '@chakra-ui/react';
import { Link as RLink } from 'react-router-dom';
import { routes } from '../constants/routes';
import { useTranslation } from 'react-i18next';

const AuthLinkButon = ({ text }) => {
  const { t } = useTranslation();
  return (
    <Box p={'2'}>
      <Text fontWeight={'600'}>{text}</Text>
      <Box display={'flex'} justifyContent={'space-evenly'}>
        <Text fontWeight={'600'} color="dark">
          <Link as={RLink} to={routes.register.path}>
            {t('register')}
          </Link>
        </Text>
        <Text fontWeight={'600'} color="primary">
          <Link as={RLink} to={routes.login.path}>
            {t('login')}
          </Link>
        </Text>
      </Box>
    </Box>
  );
};

export default AuthLinkButon;
