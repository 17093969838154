/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Text,
  useDisclosure,
  Box,
  Switch,
  useChakra,
} from '@chakra-ui/react';
import {
  MainLayout,
  MainSection,
  SecondarySection,
  SectionsWrapper,
  InputWrapper,
  Input,
  Textarea,
  LocationSelectModal,
} from '../../components';
import useMainStore from '../../hooks/useMainStore';
import useForm from '../../hooks/useForm';
import * as yup from 'yup';
import { getLocationFromCoordinates } from '../../helpers/location';
import { PostService } from '../../services';
import moment from 'moment';
import toast from '../../helpers/toast';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../constants/routes';
import { useTranslation } from 'react-i18next';

const defaultSessionData = {
  title: '',
  startDate: '',
  endDate: '',
};

const CustomSwitch = props => {
  const { theme } = useChakra();
  const styleConfig = {
    ...theme?.components?.Switch,
    baseStyle: {
      ...theme?.components?.Switch?.baseStyle({}),
      track: {
        ...theme?.components?.Switch?.baseStyle({})?.track,
        bg: props.isChecked ? 'primary' : '#4A5568',
      },
    },
  };

  return <Switch {...props} styleConfig={styleConfig} />;
};

const EditPostDetails = () => {
  const { t } = useTranslation();

  const validation = yup.object({
    title: yup
      .string()
      .required(t('mandatory_field'))
      .max(255, t('max_x_characters', { x: 255 })),
    description: yup
      .string()
      .required(t('mandatory_field'))
      .max(255, t('max_x_characters', { x: 255 })),
    date: yup.string().required(t('mandatory_field')),
    endDate: yup.string().required(t('mandatory_field')),
    link: yup
      .string()
      .nullable()
      .max(255, t('max_x_characters', { x: 255 })),
    conditions: yup
      .string()
      .nullable()
      .max(255, t('max_x_characters', { x: 255 })),
    location: yup
      .object()
      .shape({
        latitude: yup.number().nullable(),
        longitude: yup.number().nullable(),
      })
      .required(),
    price: yup.string().nullable(),
    city: yup.string(),
    isOnline: yup.boolean(),
    isPaid: yup.boolean(),
    hasQuota: yup.boolean(),
    quota: yup.number().nullable(),
    isApprovalRequired: yup.boolean(),
    isExternal: yup.boolean(),
  });
  const {
    data: { sharePost },
    resetSharePost,
  } = useMainStore();
  const locationModal = useDisclosure();
  const navigate = useNavigate();
  const [locationDisplay, setLocationDisplay] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const {
    values,
    setValues,
    onFormSubmit,
    errors,
    isSubmitted,
    setValuesDirectly,
    isValid,
    setError,
  } = useForm(
    sharePost?.details
      ? sharePost?.details
      : {
          title: '',
          description: '',
          date: '',
          endDate: '',
          location: { latitude: null, longitude: null },
          link: '',
          conditions: '',
          price: '',
          city: '',
          sessions: [],
          isOnline: false,
          isPaid: false,
          hasQuota: false,
          quota: 0,
          isApprovalRequired: false,
          isExternal: false,
        },
    validation
  );

  useEffect(() => {
    if (sharePost.details) initLocationDisplay(sharePost?.details?.location);
  }, []);

  useEffect(() => {
    return () => resetSharePost();
  }, []);

  const initLocationDisplay = async coords => {
    if (!coords) return;
    const coordinates =
      typeof coords === 'string' ? JSON.parse(coords) : coords;
    const location = await getLocationFromCoordinates(coordinates);
    coordinates && setValuesDirectly({ location: coordinates });
    setLocationDisplay(location);
  };

  const onSubmit = async () => {
    onFormSubmit();
    if (!isValid) return;
    if (!values.location.latitude) {
      setError({ location: t('select_a_location') });
      return;
    }
    let result;
    setIsLoading(true);
    if (sharePost.id) {
      const [data] = await PostService.updatePost(sharePost.id, values);
      result = data;
    } else {
      const [data] = await PostService.createPost({
        ...values,
        image: sharePost.image.image,
      });
      result = data;
    }
    setIsLoading(false);
    if (result) {
      toast({ title: result.message || t('success'), status: 'info' });
      resetSharePost();
      navigate(routes.home.path);
    }
  };

  const onLocationChange = ({ coordinates, city, display }) => {
    setValuesDirectly({ location: coordinates, city });
    setLocationDisplay(display);
  };

  const addSession = () => {
    const sessions = values?.sessions?.length > 0 ? [...values.sessions] : [];
    sessions.push({ ...defaultSessionData });
    setValuesDirectly({ sessions: [...sessions] });
  };

  const updateSession = (index, obj) => {
    const sessions = values?.sessions?.length > 0 ? [...values.sessions] : [];
    if (sessions.length > index) {
      let item = sessions[index];
      item = { ...item, ...obj };
      sessions[index] = { ...item };
      setValuesDirectly({ sessions: [...sessions] });
    }
  };

  const deleteSession = index => {
    const sessions = values?.sessions?.length > 0 ? [...values.sessions] : [];
    if (sessions.length > index) {
      sessions.splice(index, 1);
      setValuesDirectly({ sessions: [...sessions] });
    }
  };

  return (
    <MainLayout>
      <SectionsWrapper>
        <MainSection>
          <InputWrapper
            label={t('title')}
            id="title"
            error={isSubmitted && errors.title}
            backgroundColor="light"
          >
            <Input
              name="title"
              id="title"
              onChange={setValues}
              value={values.title}
            />
          </InputWrapper>
          <InputWrapper
            label={t('description')}
            id="description"
            error={isSubmitted && errors.description}
            backgroundColor="light"
          >
            <Textarea
              label="description"
              name="description"
              id="description"
              onChange={setValues}
              value={values.description}
            />
          </InputWrapper>
          <InputWrapper
            label={t('start_date')}
            id="date"
            error={isSubmitted && errors.date}
            backgroundColor="light"
          >
            <Input
              id="date"
              name="date"
              onChange={setValues}
              value={moment(values.date).format('yyyy-MM-DDTHH:mm')}
              type="datetime-local"
            />
          </InputWrapper>
          <InputWrapper
            label={t('end_date')}
            id="endDate"
            error={isSubmitted && errors.endDate}
            backgroundColor="light"
          >
            <Input
              id="endDate"
              name="endDate"
              onChange={setValues}
              value={moment(values.endDate).format('yyyy-MM-DDTHH:mm')}
              type="datetime-local"
            />
          </InputWrapper>
          <InputWrapper
            label={t('location')}
            id="location"
            error={isSubmitted && errors.location}
            backgroundColor="light"
          >
            <Box display="inline">
              {locationDisplay}
              <Button onClick={locationModal.onOpen}>
                Konum {values.location.latitude ? 'Değiştir' : 'Seç'}
              </Button>
            </Box>
          </InputWrapper>
          <InputWrapper
            label={t('conditions')}
            id="conditions"
            error={isSubmitted && errors.conditions}
            backgroundColor="light"
          >
            <Textarea
              label="conditions"
              name="conditions"
              id="conditions"
              onChange={setValues}
              value={values.conditions}
            />
          </InputWrapper>

          <InputWrapper
            label={t('link')}
            id="link"
            error={isSubmitted && errors.link}
            backgroundColor="light"
          >
            <Input
              id="link"
              name="link"
              onChange={setValues}
              value={values.link}
            />
          </InputWrapper>
          <InputWrapper
            label={t('is_paid')}
            id="isPaid"
            error={isSubmitted && errors.isPaid}
            backgroundColor="light"
            row
            htmlFor="isPaid"
          >
            <CustomSwitch
              id="isPaid"
              isChecked={values.isPaid}
              onChange={e => {
                setValuesDirectly({ isPaid: e.target.checked });
              }}
              size="md"
            />
          </InputWrapper>
          {values.isPaid && (
            <InputWrapper
              label={t('price')}
              id="price"
              error={isSubmitted && errors.price}
              backgroundColor="light"
            >
              <Input
                name="price"
                id="price"
                onChange={setValues}
                value={values.price}
              />
            </InputWrapper>
          )}
          <InputWrapper
            label={t('has_quota')}
            id="hasQuota"
            error={isSubmitted && errors.hasQuota}
            backgroundColor="light"
            row
            htmlFor="hasQuota"
          >
            <CustomSwitch
              id="hasQuota"
              isChecked={values.hasQuota}
              onChange={e => {
                setValuesDirectly({ hasQuota: e.target.checked });
              }}
              size="md"
            />
          </InputWrapper>
          {values.hasQuota && (
            <InputWrapper
              label={t('quota')}
              id="quota"
              error={isSubmitted && errors.quota}
              backgroundColor="light"
            >
              <Input
                name="quota"
                id="quota"
                onChange={setValues}
                value={values.quota}
              />
            </InputWrapper>
          )}
          <InputWrapper
            label={t('is_approval_required')}
            id="isApprovalRequired"
            error={isSubmitted && errors.isApprovalRequired}
            backgroundColor="light"
            row
            htmlFor="isApprovalRequired"
          >
            <CustomSwitch
              id="isApprovalRequired"
              isChecked={values.isApprovalRequired}
              onChange={e => {
                setValuesDirectly({ isApprovalRequired: e.target.checked });
              }}
              size="md"
            />
          </InputWrapper>
          <InputWrapper
            label={t('use_ticketing_system')}
            id="isExternal"
            error={isSubmitted && errors.isExternal}
            backgroundColor="light"
            row
            htmlFor="isExternal"
            helperText={t('use_ticketing_system_desc')}
          >
            <CustomSwitch
              id="isExternal"
              isChecked={values.isExternal}
              onChange={e => {
                setValuesDirectly({ isExternal: e.target.checked });
              }}
              size="md"
            />
          </InputWrapper>
          <Text mt="3">{t('sessions')}</Text>
          <Button
            borderRadius={'full'}
            size={'sm'}
            backgroundColor={'primary'}
            color="white"
            my="4"
            onClick={addSession}
          >
            Oturum Ekle
          </Button>
          {values?.sessions?.map((session, index) => {
            const key = `session-${index}`;
            const titleKey = `${key}-title`;
            const startDateKey = `${key}-start-date`;
            const endDateKey = `${key}-end-date`;
            return (
              <Box
                key={key}
                p="2"
                my="2"
                borderWidth={1}
                borderColor={'#ddd'}
                borderRadius={'lg'}
              >
                <InputWrapper
                  label={t('session_title')}
                  id={titleKey}
                  error={isSubmitted && errors.title}
                  backgroundColor="light"
                >
                  <Input
                    name={titleKey}
                    id={titleKey}
                    onChange={e =>
                      updateSession(index, { title: e.target.value })
                    }
                    value={session.title}
                  />
                </InputWrapper>
                <InputWrapper
                  label={t('session_start_date')}
                  id={startDateKey}
                  backgroundColor="light"
                >
                  <Input
                    id={startDateKey}
                    name={startDateKey}
                    onChange={e =>
                      updateSession(index, { startDate: e.target.value })
                    }
                    value={moment(session.startDate).format('yyyy-MM-DDTHH:mm')}
                    type="datetime-local"
                  />
                </InputWrapper>
                <InputWrapper
                  label={t('session_end_date')}
                  id={endDateKey}
                  backgroundColor="light"
                >
                  <Input
                    id={endDateKey}
                    name={endDateKey}
                    onChange={e =>
                      updateSession(index, { endDate: e.target.value })
                    }
                    value={moment(session.endDate).format('yyyy-MM-DDTHH:mm')}
                    type="datetime-local"
                  />
                </InputWrapper>
                <Button
                  borderRadius={'full'}
                  size={'sm'}
                  backgroundColor={'red'}
                  color="white"
                  onClick={() => deleteSession(index)}
                >
                  {t('delete_session')}
                </Button>
              </Box>
            );
          })}
        </MainSection>
        <SecondarySection p="3" pt="5">
          <Text fontWeight={'600'} fontSize={'2xl'} mb="3">
            {t('event_details')}
          </Text>
          {/*<Text fontWeight={'500'} fontSize={'md'} mb="3">*/}
          {/*  Lorem ipsum dolor sit amet, consectetur adipiscing elit.{' '}*/}
          {/*</Text>*/}
          <Button
            disabled={isLoading}
            isLoading={isLoading}
            isFullWidth
            borderRadius={'full'}
            size={'lg'}
            backgroundColor={'primary'}
            color="white"
            my="4"
            onClick={onSubmit}
          >
            {sharePost.details ? t('edit') : t('share')}
          </Button>
        </SecondarySection>
      </SectionsWrapper>
      <LocationSelectModal
        onCloseModal={locationModal.onClose}
        isOpen={locationModal.isOpen}
        onSelect={onLocationChange}
      />
    </MainLayout>
  );
};

export default EditPostDetails;
