import API from '../api';
import endpoints from '../api/endpoints';

const updateToken = async ({ token }) => {
  const { data } = await API.put(endpoints.updateToken, {
    token,
  });
  return [data, null];
};

const updateUser = async user => {
  const { data } = await API.put(endpoints.updateUser, {
    ...user,
  });
  return [data, null];
};

const changePassword = async ({ oldPassword, password }) => {
  const { data } = await API.put(endpoints.changePassword, {
    oldPassword,
    password,
  });
  return [data, null];
};

const getUserFeed = async userId => {
  const { data } = await API.get(endpoints.getUserFeed(userId));
  return [data?.reverse?.(), null];
};

const getModFeed = async userId => {
  const { data } = await API.get(endpoints.getModFeed(userId));
  return [data?.post?.reverse(), null];
};

const getUser = async userId => {
  const { data } = await API.get(endpoints.getUser(userId));
  return [data?.user, null];
};

const getProfileUserPosts = async userId => {
  const { data } = await API.get(endpoints.getProfileUserPost(userId));
  return [data?.userPost, null];
};

const getMe = async () => {
  const { data } = await API.get(endpoints.getMe);
  return [data?.user, null];
};

const getUsers = async () => {
  const { data } = await API.get(endpoints.getUsers);
  return [data?.users, null];
};

const getFollowers = async userId => {
  const { data } = await API.get(endpoints.getFollowers(userId));
  return [data?.followers?.reverse?.(), null];
};

const queryFollowers = async (userId, params) => {
  const { data } = await API.get(endpoints.queryFollowers(userId), { params });
  return [data, null];
};

const queryFollowees = async (userId, params) => {
  const { data } = await API.get(endpoints.queryFollowees(userId), { params });
  return [data, null];
};

const getFollowList = async userId => {
  const { data } = await API.get(endpoints.getFollowList(userId));
  return [data?.follow?.reverse?.(), null];
};

const checkFollow = async userId => {
  const { data } = await API.get(endpoints.checkFollow(userId));
  return [data, null];
};

const follow = async userId => {
  const { data } = await API.post(endpoints.follow(userId));
  return [data, null];
};

const unfollow = async userId => {
  const { data } = await API.post(endpoints.unfollow(userId));
  return [data, null];
};

const block = async userId => {
  const { data } = await API.post(endpoints.blockUser, { blockedId: userId });
  return [data, null];
};

const getBlockedUsers = async () => {
  const { data } = await API.get(endpoints.getBlockedUsers);
  return [data?.blockedUsers, null];
};

const deleteBlock = async blockId => {
  const { data } = await API.delete(endpoints.deleteBlock(blockId));
  return [data, null];
};

const sendMessage = async ({ message, receiverId }) => {
  const { data } = await API.post(endpoints.sendMessage, {
    message,
    receiverId,
  });
  return [data, null];
};

const canSendMessage = async otherUserId => {
  const { data } = await API.post(endpoints.canSendMessage, { otherUserId });
  return [data?.result, null];
};

const registerClub = async userId => {
  const { data } = await API.post(endpoints.registerClub(userId));
  return [data?.result, null];
};

const getClubApplications = async () => {
  const { data } = await API.get(endpoints.getClubApplications);
  return [data?.applications, null];
};

const getUserProfile = async userId => {
  const { data } = await API.get(endpoints.getUserProfile(userId));
  return [data, null];
};

const getUserProfileByUsername = async username => {
  const { data } = await API.get(endpoints.getUserProfileByUsername(username));
  return [data, null];
};

const withoutSigninGetUserProfile = async username => {
  const { data } = await API.get(
    endpoints.withoutSigninGetUserProfile(username)
  );
  return [data, null];
};

const getRecomendedUsers = async () => {
  const { data } = await API.get(endpoints.getRecomendedUsers);
  return [data?.users, null];
};

const updateClubApplication = async ({ userId, status }) => {
  const { data } = await API.put(endpoints.updateClubApplication(userId), {
    status,
  });
  return [data?.result, null];
};

const updateAvatar = async image => {
  const formData = new FormData();
  formData.append('avatar', image);
  const { data } = await API.put(endpoints.changeAvatar, formData);
  return [data?.avatar, null];
};

// const createStory = async image => {
//   const formData = new FormData();
//   formData.append('avatar', {
//     name: 'avatar',
//     type: image?.type || image?.mime,
//     uri:
//       Platform.OS === 'android'
//         ? image?.path
//         : image?.path?.replace('file://', ''),
//   });
//   const {data} = await API.post(endpoints.createStory, formData);
//   return [data, null];
// };

const deleteStory = async storyId => {
  const { data } = await API.delete(endpoints.deleteStory(storyId));
  return [data, null];
};

const getStoriesOfUser = async userId => {
  const { data } = await API.get(endpoints.getStoriesOfUser(userId));
  return [data?.stories, null];
};

const search = async query => {
  const { data } = await API.get(endpoints.search(query));
  return [data, null];
};

const getCertificates = async () => {
  const { data } = await API.get(endpoints.getCertificatesOfUser);
  return [data, null];
};

const searchUser = async query => {
  const { data } = await API.get(endpoints.searchUser(query));
  return [data, null];
};

const addUserToClub = async userId => {
  const { data } = await API.post(endpoints.addUserToClub, { userId });
  return [data, null];
};

const sendEmailToClub = async content => {
  const { data } = await API.post(endpoints.sendMailToClub, {
    content: content.trim(),
  });
  return [data, null];
};

const deleteUser = async () => {
  const { data } = await API.delete(endpoints.deleteUser);
  return [data, null];
};

const getApiKey = async () => {
  const { data } = await API.get(endpoints.getApiKey);
  return [data, null];
};

const UserService = {
  getMe,
  updateToken,
  updateUser,
  changePassword,
  getUserFeed,
  getModFeed,
  getUser,
  getProfileUserPosts,
  getFollowers,
  follow,
  unfollow,
  checkFollow,
  getFollowList,
  block,
  getUsers,
  sendMessage,
  canSendMessage,
  registerClub,
  getClubApplications,
  updateClubApplication,
  deleteStory,
  getStoriesOfUser,
  getBlockedUsers,
  deleteBlock,
  getUserProfile,
  withoutSigninGetUserProfile,
  updateAvatar,
  getRecomendedUsers,
  search,
  getCertificates,
  searchUser,
  addUserToClub,
  sendEmailToClub,
  deleteUser,
  getUserProfileByUsername,
  queryFollowers,
  queryFollowees,
  getApiKey,
};

export default UserService;
