import * as React from 'react';

const SvgCommentOutline = props => (
  <svg
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18 6.096v4.24c0 .275-.016.533-.049.79C17.71 13.982 16.01 15.4 12.9 15.4h-.421a.876.876 0 0 0-.68.338l-1.279 1.694c-.567.758-1.473.758-2.04 0L7.2 15.739c-.145-.178-.452-.34-.679-.34H6.1c-3.4 0-5.1-.838-5.1-5.079V6.08c0-3.097 1.44-4.79 4.29-5.032.26-.032.519-.048.794-.048h6.8C16.3 1.032 18 2.725 18 6.096Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgCommentOutline;
