import { createStandaloneToast } from '@chakra-ui/react';
import theme from '../theme';

const toast = createStandaloneToast({
  theme,
  defaultOptions: { isClosable: true, position: 'top' },
});

export const showFormErrorsToast = errors => {
  Object.keys(errors).forEach(key => {
    toast({
      title: errors[key],
      position: 'top',
      status: 'warning',
      isClosable: true,
    });
  });
};

export default toast;
