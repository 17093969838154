import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { navigation, routes } from './constants/routes';
import useMainStore from './hooks/useMainStore';
import { Fallback, withSplashScreen } from './components';

import '@fontsource/poppins';
import { roles } from './constants';
import useLanguage from './hooks/useLanguage';

function App() {
  const {
    data: { authenticated, user },
    isMod,
  } = useMainStore();
  const { setLanguagePreference } = useLanguage();

  useEffect(() => {
    if (authenticated) {
      setLanguagePreference(user?.language);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated]);

  return (
    <React.Suspense fallback={<Fallback />}>
      <Routes>
        {navigation.map((route, index) => {
          let redirect = false;

          if (route.redirect) {
            redirect = route.redirectPath;
          }

          if (route.isSecured && !authenticated) {
            redirect = routes.login.path;
          }

          if (route.roles) {
            if (route.roles.includes(roles.mod) && !isMod)
              redirect = route.redirectPath || routes.home.path;
          }

          return (
            <Route
              key={route.path}
              path={route.path}
              element={
                redirect ? <Navigate to={redirect} /> : <route.component />
              }
              exact={route.isExact}
            />
          );
        })}
      </Routes>
    </React.Suspense>
  );
}

export default withSplashScreen(App);
