import React from 'react';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/react';
import {
  MainLayout,
  MainSection,
  PostCard,
  RecommendedUsers,
  SecondarySection,
  Footer,
  SectionsWrapper,
  InfiniteScroll,
} from '../components';
import { PostService, UserPostService } from '../services';
import useFetch from '../hooks/useFetch';
import UserPostDetailedCard from '../components/cards/UserPostDetailedCard';
import { useTranslation } from 'react-i18next';

const Home = () => {
  const { t } = useTranslation();
  const posts = useFetch({
    queryKey: 'posts',
    fetchFn: PostService.getHomePosts,
  });

  const userPosts = useFetch({
    queryKey: 'user-posts',
    fetchFn: UserPostService.getHomePosts,
  });

  return (
    <MainLayout>
      <SectionsWrapper>
        <MainSection>
          <Tabs variant="soft-rounded" colorScheme="main" my={'3'}>
            <TabList
              alignItems={'center'}
              display={'flex'}
              justifyContent={'center'}
            >
              <Tab>{t('events')}</Tab>
              <Tab>{t('for_you')}</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <InfiniteScroll {...posts}>
                  {posts?.data.map?.(item => {
                    return (
                      <PostCard
                        key={item.id}
                        avatar={item.user.avatar}
                        image={item.image}
                        username={item.user.username}
                        title={item.title}
                        description={item.description}
                        likeCount={item.likes?.length || 0}
                        commentCount={item.comments?.length || 0}
                        date={item.date}
                        userId={item.userId}
                        id={item.id}
                        sessions={item?.sessions || []}
                        slug={item?.slug}
                        isOnline={item?.isOnline}
                        price={item?.price}
                        city={item?.city}
                      />
                    );
                  })}
                </InfiniteScroll>
              </TabPanel>
              <TabPanel>
                <InfiniteScroll {...userPosts}>
                  {userPosts?.data.map?.(item => {
                    return (
                      <UserPostDetailedCard
                        key={item.id}
                        image={item.image}
                        title={item.title}
                        userId={item.userId}
                        id={item.id}
                        postId={item.postId}
                        event={item?.post?.title}
                        user={item.user}
                        likeCount={item?.likes?.length || 0}
                        commentCount={item?.comments?.length || 0}
                        post={item.post}
                      />
                    );
                  })}
                </InfiniteScroll>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </MainSection>
        <SecondarySection>
          <RecommendedUsers />
          <Footer />
        </SecondarySection>
      </SectionsWrapper>
    </MainLayout>
  );
};

export default Home;
