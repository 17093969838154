import { availableLanguages } from '../language';
import { Box, Select } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import useChangeLanguage from '../hooks/useChangeLanguage';

const ChangeLanguage = () => {
  const { t, i18n } = useTranslation();
  const { change } = useChangeLanguage();

  return (
    <Box>
      <Select
        placeholder={t('language_selection')}
        value={i18n.language}
        borderColor={'gray'}
        onChange={e => change(e.target.value)}
      >
        {availableLanguages.map((lang, index) => (
          <option key={index} value={lang.code}>
            {lang.flag} {lang.label}
          </option>
        ))}
      </Select>
    </Box>
  );
};

export default ChangeLanguage;
